<template>
  <div>
    <v-select
      v-model="selectedDefinitionVersion"
      :clearable="false"
      :options="definitionVersionOptions"
      :reduce="option => option.value"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    vSelect,
  },
  computed: {
    definitionVersions() {
      return this.$store.getters['applicationSettings/definitionVersions']
    },
    defaultDefinitionVersion() {
      return this.$store.getters['applicationSettings/defaultDefinitionVersion']
    },
    definitionVersionOptions() {
      return this.definitionVersions.map(definitionVersion => ({
        label: definitionVersion.toUpperCase(),
        value: definitionVersion,
      }))
    },
    selectedDefinitionVersion: {
      get() {
        return this.$store.getters['dataView/selectedDefinitionVersion']
      },
      set(value) {
        this.$store.commit('dataView/SET_SELECTED_DEFINITION_VERSION', value)
        this.$store.commit('dataView/SET_SELECTED_TABLE_ID', 0)
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
