<template>
  <div :style="{width: `${width}%` }">
    <b-input-group v-if="editableNode && editableNode.v">
      <b-form-input
        ref="nodeEditorInput"
        v-model="nodeValue"
        :placeholder="editableNode.v"
        autofocus
      />

      <b-input-group-append class="cursor-pointer">
        <b-input-group-text
          v-if="editableNode.v !== nodeValue"
          style="margin-right: 1px"
          @click="save"
        >
          <feather-icon
            icon="CheckIcon"
            size="12"
          />
        </b-input-group-text>
        <b-input-group-text
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="12"
          />
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BInputGroupText, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BFormInput,
  },
  props: {
    width: {
      required: false,
      type: String,
      default() {
        return '100'
      },
    },
  },
  data() {
    return {
      nodeValue: '',
    }
  },
  computed: {
    editableNode() {
      return this.$store.getters['batch/editableNode']
    },
  },
  watch: {
    editableNode() {
      this.nodeValue = this.editableNode?.v || ''

      if (this.$refs.nodeEditorInput) {
        this.$refs.nodeEditorInput.focus()
      }
    },
  },
  created() {
    this.nodeValue = this.editableNode?.v || ''
  },
  methods: {
    async save() {
      const data = {
        id: this.editableNode.id,
        nodeValue: this.nodeValue,
      }

      await this.$store.dispatch('batch/updateNodeValue', data)
      await this.$store.dispatch('batch/setEditableNode', { ...this.editableNode, v: this.nodeValue })

      this.close()
    },
    close() {
      this.$store.dispatch('batch/setEditableNode', null)
    },
  },
}
</script>
