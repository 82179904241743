<template>
  <b-card
    class="border-secondary"
  >
    <div
      class="d-flex align-items-center"
      style="column-gap:10px;"
    >
      <div>
        <feather-icon
          v-if="!expanded"
          icon="ChevronRightIcon"
          class="cursor-pointer mx-auto"
          size="20"
          @click="$emit('toogle-expanded')"
        />
        <feather-icon
          v-if="expanded"
          icon="ChevronDownIcon"
          class="cursor-pointer mx-auto"
          size="20"
          @click="$emit('toogle-expanded')"
        />
      </div>
      <h5 class="mb-0">
        Query #{{ queryIndex + 1 }}
      </h5>
      <div>
        <feather-icon
          v-b-tooltip.hover
          title="Delete Query"
          icon="Trash2Icon"
          class="cursor-pointer mx-auto"
          size="20"
          @click="$emit('delete')"
        />
      </div>
    </div>

    <div
      v-if="expanded"
      class="mt-50"
    >
      <div style="width:350px;">
        <b-form-group label="Table">
          <v-select
            v-model="queryData.table"
            :options="tableOptions"
          />
        </b-form-group>
      </div>

      <query-group
        v-model="queryData.group"
        :table-columns="tableColumns"
        :depth="0"
        :query-result-options="queryResultOptions"
      />

      <div
        v-if="sqlQuery"
        class="my-1"
      >
        <div>SQL:</div>
        <b-alert
          class="my-50"
          variant="primary"
          show
        >
          <div class="alert-body">
            <p>
              {{ sqlQuery }}
            </p>
          </div>
        </b-alert>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BFormGroup, BAlert, BCard, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { isEqual, cloneDeep } from 'lodash'
import QueryGroup from './QueryGroup.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormGroup,
    BAlert,
    vSelect,
    QueryGroup,
    BCard,
  },
  props: {
    queryIndex: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    queryResultOptions: {
      type: Array,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      queryData: {},
    }
  },
  computed: {
    out() {
      const out = cloneDeep(this.queryData)
      out.sql = this.sqlQuery
      return out
    },
    tableOptions() {
      return this.$store.getters['lookup/tables']
    },
    tableColumns() {
      const { table } = this.queryData
      if (!table) {
        return []
      }

      return this.$store.getters['lookup/tableColumns'](table)
    },
    sqlQuery() {
      const { table } = this.queryData
      let sqlQuery = ''
      if (!table) {
        return sqlQuery
      }
      sqlQuery = `SELECT * FROM "${table}"`
      const parsedCondition = this.parseGroup(this.queryData.group)
      if (parsedCondition !== '') {
        sqlQuery += ` WHERE ${parsedCondition}`
      }
      return sqlQuery
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.queryData = cloneDeep(this.value)
    },
    parseGroup(group) {
      const rules = []
      group.items.forEach(groupItem => {
        let rule = ''
        if (groupItem.type === 'rule') {
          rule = this.parseRule(groupItem.data)
        } else if (groupItem.type === 'group') {
          rule = this.parseGroup(groupItem.data)
          if (rule !== '') {
            rule = `( ${rule} )`
          }
        }
        if (rule !== '') {
          rules.push(rule)
        }
      })
      return rules.join(` ${group.operator} `)
    },
    parseRule(rule) {
      let ruleString = ''

      if (!rule.column || !rule.operator || !rule.value) {
        return ruleString
      }

      let ruleValue = rule.value
      if (rule.valueType === 'input') {
        ruleValue = `<I>${rule.value}</I>`
      }

      if (rule.operator === 'ILIKE') {
        ruleString = `LOWER(${rule.column}) LIKE LOWER('${ruleValue}')`
      } else if (rule.operator === 'FUZZY MATCH') {
        ruleString = `UTL_MATCH.JARO_WINKLER_SIMILARITY(LOWER(${rule.column}), LOWER('${ruleValue}')) > 93`
      } else {
        ruleString = `"${rule.column}" ${rule.operator} '${ruleValue}'`
      }

      return ruleString
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
