<template>
  <v-select
    v-model="documentId"
    :clearable="false"
    :options="options"
    :loading="loadingDocumentData"
  >
    <template #spinner="{ loading }">
      <b-spinner
        v-if="loading"
        variant="primary"
        small
      />
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    vSelect,
    BSpinner,
  },
  computed: {
    mainMode() {
      return this.$store.getters['dataView/mainMode']
    },
    documentId: {
      get() {
        return this.$store.getters['batch/selectedDocumentId']
      },
      set(value) {
        this.$store.commit('batch/SET_SELECTED_DOCUMENT_ID', value)
      },
    },
    options() {
      return Object.keys(this.$store.getters['batch/documents'])
    },
    loadingDocumentData() {
      return this.$store.getters['batch/loadingDocumentData']
    },
    selectedNode() {
      return this.$store.getters['batch/selectedNode']
    },
    verificationDetails() {
      return this.$store.getters['batch/verificationDetails']
    },
  },
  watch: {
    documentId(oldVal, newVal) {
      if (this.mainMode === 'verification' && oldVal !== newVal) {
        const batchId = `${this.documentId.split('.')[0]}.${this.documentId.split('.')[1]}`
        const batch = this.verificationDetails.find(e => e.id === batchId)

        this.$store.commit('dataView/SET_SELECTED_TABLE_ID', 0)

        this.$store.dispatch('dataView/setDJsonTableList', batch)

        this.$store.commit('batch/SET_BATCH', { id: batchId, subPath: batch?.sub_path || '' })

        this.$store.dispatch('batch/setEditableNode', null)
      }
      this.$store.dispatch('batch/loadDocumentData')
    },
    selectedNode() {
      if (this.selectedNode?.docId !== this.documentId && this.selectedNode?.highlight) {
        this.documentId = this.selectedNode.docId
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
