import { v4 as uuidv4 } from 'uuid'
import store from '@/store'

const getDefaultKey = isExcelBatch => {
  const defaultKey = {
    id: uuidv4(),
    keyLabel: '',
    qualifierValue: '',
    type: isExcelBatch ? 'cellRange' : 'keys',
    shape: '',
    startPos: '',
    topPos: '',
    endPos: '',
    bottomPos: '',
    pageId: '',
    pageIndex: '',
    anchorShapes: null,
    selector: false,
    extractMultiple: false,
    removeDuplicates: false,
    singleColumnExtractor: null,
    regexExtractor: null,
    advanceSettings: {},
    typeData: {},
    isCompoundKey: false,
    compoundItems: [],
    isCompoundItem: false,
  }
  return defaultKey
}

const getDefaultKeys = (count, isExcelBatch) => {
  const keys = []
  for (let i = 0; i < count; i += 1) {
    keys.push(getDefaultKey(isExcelBatch))
  }
  return keys
}

const getDefaultCompoundKeys = (isExcelBatch, compoundKeySettingName) => {
  const defaultBehaviour = store.getters['defaultSettings/defaultBehaviour']
  const compoundKeySettings = store.getters['definitionSettings/compoundKeys']
  const compoundKeySetting = compoundKeySettings.find(item => item.name === compoundKeySettingName)
  const keyItems = compoundKeySetting ? compoundKeySetting.keyItems : []

  const compoundKeyItems = keyItems.map(keyItem => {
    const record = getDefaultKey(isExcelBatch)
    record.keyLabel = keyItem.keyValue
    record.isCompoundItem = true

    if (defaultBehaviour.compoundKeys.includes(compoundKeySettingName)) {
      record.type = 'static'
      record.shape = 'ERROR'
    }

    return record
  })

  return compoundKeyItems
}

const normalizeKeyItemValues = (items, optionsKeyItems) => {
  const normalizedItems = items.map(record => {
    const optionsKeyItem = optionsKeyItems.find(e => e.keyValue === record.keyLabel)

    const item = {
      id: record.id || uuidv4(),
      keyLabel: String(record.keyLabel || ''),
      qualifierValue: String(record.qualifierValue || ''),
      type: record.type !== undefined ? record.type : '',
      shape: String(record.shape || ''),
      startPos: String(record.startPos || ''),
      topPos: String(record.topPos || ''),
      endPos: String(record.endPos || ''),
      bottomPos: String(record.bottomPos || ''),
      pageId: String(record.pageId || ''),
      pageIndex: String(record.pageIndex || ''),
      anchorShapes: record.anchorShapes !== undefined ? record.anchorShapes : null,
      selector: record.selector !== undefined ? record.selector : false,
      export: !!record.export,
      extractMultiple: record.extractMultiple !== undefined ? record.extractMultiple : false,
      removeDuplicates: record.removeDuplicates !== undefined ? record.removeDuplicates : false,
      singleColumnExtractor: record.singleColumnExtractor !== undefined ? record.singleColumnExtractor : null,
      regexExtractor: record.regexExtractor !== undefined ? record.regexExtractor : null,
      advanceSettings: record.advanceSettings !== undefined ? record.advanceSettings : {},
      typeData: record.typeData !== undefined ? record.typeData : {},
      isCompoundKey: record.isCompoundKey !== undefined ? record.isCompoundKey : false,
      compoundItems: record.compoundItems !== undefined ? record.compoundItems : [],
      isCompoundItem: record.isCompoundItem !== undefined ? record.isCompoundItem : false,
    }

    if (optionsKeyItem && Object.keys(optionsKeyItem).includes('export')) {
      item.export = optionsKeyItem.export
    }

    // Key data migrations for old format
    if (item.type === '') {
      item.type = item.selector ? 'selector' : 'keys'
    }

    return item
  })

  return normalizedItems
}

export {
  getDefaultKey, getDefaultKeys, normalizeKeyItemValues, getDefaultCompoundKeys,
}
