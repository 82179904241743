<template>
  <b-modal
    v-model="showModal"
    title="Create Template"
    no-close-on-backdrop
    centered
    @hidden="$emit('modal-closed')"
    @ok="onSubmit"
  >
    <div v-if="!loading">
      <validation-observer ref="profileForm">
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Template Name"
                label-for="template-name"
                label-cols-md="4"
              >
                <b-form-input
                  id="template-name"
                  :value="profileName"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Name"
                label-for="name"
                label-cols-md="4"
              >
                <b-form-input
                  id="name"
                  v-model="formData.vendor"
                  @input="errorMessage = null"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Project"
                vid="project"
                mode="eager"
              >
                <b-form-group
                  label="Project"
                  label-for="project"
                  label-cols-md="4"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="project"
                    v-model="formData.project"
                    :options="options.project"
                    @input="onChangeProject(true)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Country"
                vid="country"
                mode="eager"
              >
                <b-form-group
                  label="Country"
                  label-for="country"
                  label-cols-md="4"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="project"
                    v-model="formData.country"
                    :options="options.country_code"
                    :reduce="option => option.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="doc_type"
                vid="doc_type"
                mode="eager"
              >
                <b-form-group
                  label="Document Type"
                  label-for="doc_type"
                  label-cols-md="4"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="doc_type"
                    v-model="formData.docType"
                    :options="documentTypes"
                    @input="errorMessage = null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <b-alert
            variant="danger"
            :show="errorMessage !== null ? true : false"
            class="my-1"
          >
            <div class="alert-body">
              <p>
                {{ errorMessage }}
              </p>
            </div>
          </b-alert>
        </b-form>
      </validation-observer>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>

      <b-button
        variant="primary"
        type="submit"
        :disabled="submitting || loading"
        @click="ok()"
      >
        Create
        <b-spinner
          v-if="submitting"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner variant="primary" />
    </div>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BButton, BForm, BSpinner, BAlert, BModal, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  components: {
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BForm,
    BSpinner,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BModal,
  },
  data() {
    return {
      loading: false,
      submitting: false,
      errorMessage: null,
      formData: {},
      options: {
        project: [],
        country_code: [],
      },
      showModal: true,
    }
  },
  computed: {
    batch() {
      return this.$store.getters['batch/batch']
    },
    profileName() {
      if (this.formData.country && this.formData.vendor && this.formData.docType && this.formData.project) {
        return `${this.formData.country}_${this.formData.vendor.toUpperCase()}_${this.formData.project}_${this.formData.docType}`
      }
      return ''
    },
    documentTypes() {
      if (!this.formData.project) {
        return []
      }

      const docTypeSettings = this.$store.getters['definitionSettings/options']['options-meta-root-type']

      if (!docTypeSettings) {
        return []
      }
      return docTypeSettings.items.map(item => item[docTypeSettings.valueKey])
    },
  },
  created() {
    this.init()
    this.onChangeProject()
  },
  methods: {
    async init() {
      try {
        this.formData = { ...this.batch }
        this.formData.docType = this.batch.type
        this.formData.country = this.batch.definitionId.slice(0, 2)

        const res = await axios.get('/dashboard/projects/')

        this.options.project = res.data.results.map(e => e.name).sort()
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching field options'
        this.loading = false
      }
      try {
        const res = await axios.get('/dashboard/profile_fields_options/')

        const countryCodeOptions = res.data.country_code || []
        this.options.country_code = countryCodeOptions.map(item => ({ label: `${item.name} - ${item.code}`, value: item.code }))

        // this.options.project = res.data.results.map(e => e.name).sort()
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching field options'
        this.loading = false
      }
    },
    onSubmit(event) {
      event.preventDefault()
      this.errorMessage = null
      this.submitting = true

      this.$refs.profileForm.validate().then(async success => {
        if (!success) {
          this.submitting = false

          return
        }
        const requestData = {
          country: this.formData.country,
          name: this.formData.vendor,
          project: this.formData.project,
          doc_type: this.formData.docType,
          language: 'English',
          ocr_engine: 'S',
          page_rotate: false,
          barcode: false,
          use_existing_definition: true,
          existing_profile_name: this.formData.definitionId,
          existing_document: this.formData.type,
          file_type: '.xlsx',
        }

        try {
          await axios.post('/dashboard/template/', requestData)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Template create successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.submitting = false
          this.errorMessage = null
          this.showModal = false
        } catch (error) {
          this.errorMessage = error?.response?.data?.non_field_errors[0] || 'Something went wrong'
          this.submitting = false
        }
      })
    },
    async onChangeProject(isChanged = false) {
      try {
        if (isChanged) {
          this.formData.docType = ''
        }
        this.$store.commit('definitionSettings/SET_PROJECT', this.formData.project)
        await this.$store.dispatch('definitionSettings/fetchData')
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching definition settings'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
