<template>
  <div
    ref="tableModelsWrapper"
    class="table-model-wrapper"
  >
    <b-col md="12">
      <validation-observer
        ref="tableModelForm"
        mode="eager"
      >
        <b-form
          @submit.prevent
        >
          <b-row>
            <template
              v-for="field of visibleNonAdvancedModelFields"
            >
              <table-model-field
                :key="field.key"
                v-model="model[field.key]"
                :field="field"
                :validation-rules="validationRules[field.key]"
                :gl-action-options="glActionOptions"
                @selection-input="shapeSelectionInputHandler($event, field.key)"
                @item-deleted="shapeSelectionItemDeleteHandler($event, field.key)"
              />
            </template>

            <b-button
              v-if="model.type === 'autoPattern'"
              variant="outline-primary"
              class="atm-btn"
              @click="goToAutomatedTableModel"
            >
              Automated Table Model
            </b-button>

            <template v-if="visibleAdvancedModelFields.length > 0">
              <b-col cols="12">
                Advanced Settings
                <feather-icon
                  v-if="displayAdvancedFields"
                  icon="ChevronDownIcon"
                  class="cursor-pointer"
                  size="20"
                  @click.stop="toogleAdvancedFields"
                />
                <feather-icon
                  v-else
                  icon="ChevronUpIcon"
                  class="cursor-pointer"
                  size="20"
                  @click.stop="toogleAdvancedFields"
                />
              </b-col>
              <b-col
                v-show="displayAdvancedFields"
                cols="12"
              >
                <b-row>
                  <template
                    v-for="field of visibleAdvancedModelFields"
                  >
                    <table-model-field
                      v-if="shouldDisplayTableModelField(field)"
                      :key="field.key"
                      v-model="model[field.key]"
                      :field="field"
                      :validation-rules="validationRules[field.key]"
                      :gl-action-options="glActionOptions"
                    />
                  </template>
                </b-row>
                <b-row>
                  <template
                    v-for="field of visibleAdvancedModelFields"
                  >
                    <table-model-field
                      v-if="field.type === 'numeric'"
                      :key="field.key"
                      v-model="model[field.key]"
                      class="mt-2"
                      :field="field"
                      :validation-rules="validationRules[field.key]"
                      :gl-action-options="glActionOptions"
                    />
                  </template>
                </b-row>
              </b-col>
            </template>

          </b-row>
        </b-form>
      </validation-observer>
    </b-col>
  </div>
</template>

<script>
import {
  VBTooltip, BCol, BRow, BForm, BButton,
} from 'bootstrap-vue'
import { isEqual, cloneDeep } from 'lodash'
import { ValidationObserver } from 'vee-validate'

import bus from '@/bus'
import TableModelField from './TableModelField.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCol,
    BRow,
    TableModelField,
    ValidationObserver,
    BForm,
    BButton,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      initialized: false,
      hiddenFields: ['InsertType', 'posCheck', 'chunkThreshold', 'shapeCheck'],
      displayAdvancedFields: false,
    }
  },
  computed: {
    modelFields() {
      return this.$store.getters['applicationSettings/tableSettings'].model.fields
    },
    visibleModelFields() {
      return this.modelFields.filter(item => {
        if (item.applicableForType && !item.applicableForType.includes(this.modelType)) {
          return false
        }

        if (this.hiddenFields.includes(item.key)) {
          return false
        }

        return true
      })
    },
    visibleAdvancedModelFields() {
      return this.visibleModelFields.filter(item => (item.advanced === 'true'))
    },
    visibleNonAdvancedModelFields() {
      return this.visibleModelFields.filter(item => (item.advanced !== 'true'))
    },
    batchProject() {
      return this.$store.getters['batch/batch']?.project
    },
    out() {
      return cloneDeep(this.model)
    },
    modelType() {
      return this.model.type
    },
    validationRules() {
      return {
        type: 'required',
        headerTrigger: 'required',
        close: 'required',
        open: ['tableOpenBlock', 'dynamicOpenBlock'].includes(this.modelType) ? 'required|selectTextFromImage' : '',
        tableStart: 'required',
        separator: 'required',
      }
    },
    glActionOptions() {
      let options = []
      if (this.modelType === 'tableLR') {
        options = ['notMerge', 'geoMain']
      } else if (this.modelType === 'tableOpenBlock') {
        options = ['multiOpenBlock', 'notRemoveHeader']
      }
      return options
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
    modelType() {
      if (this.initialized) {
        if (this.modelType === 'tableLR') {
          this.model.gLAction = 'notMerge'
        } else {
          this.model.gLAction = ''
        }
      }
    },
  },
  created() {
    this.setInternalState()

    this.$nextTick(() => {
      this.initialized = true
    })

    bus.$on('validateTableModel', this.validateTableModel)
  },
  destroyed() {
    bus.$off('validateTableModel', this.validateTableModel)
  },
  methods: {
    setInternalState() {
      const item = {}

      this.modelFields.forEach(modelField => {
        let value

        if (this.value[modelField.key] !== undefined) {
          value = this.value[modelField.key]
        } else if (modelField.defaultValue !== undefined) {
          if (modelField.key === 'forceLiner') {
            value = 'false'
          } else {
            value = modelField.defaultValue
          }
        } else {
          value = ''
        }
        if (value === null && modelField.advanced) {
          value = ''
        }

        item[modelField.key] = value
      })

      this.model = item
    },
    shapeSelectionInputHandler(data, field) {
      if (field !== 'open') {
        return
      }

      if (!this.model?.openPositions || data.totalItems === 1) {
        this.model.openPositions = data.selectionData.startPos.toString()

        return
      }

      const openPositions = this.model.openPositions.split('|')

      openPositions[data.index === -1 ? 0 : data.index] = data.selectionData.startPos

      this.model.openPositions = openPositions.join('|')
    },
    shapeSelectionItemDeleteHandler(index, field) {
      if (field !== 'open') {
        return
      }

      const openPositions = this.model.openPositions.split('|')

      if (index >= openPositions.length) {
        return
      }

      openPositions.splice(index, 1)

      this.model.openPositions = openPositions.join('|')
    },
    toogleAdvancedFields() {
      this.displayAdvancedFields = !this.displayAdvancedFields
    },
    validateTableModel(callback) {
      this.$refs.tableModelForm.validate().then(success => {
        callback(success)
      })
    },
    goToAutomatedTableModel() {
      this.$router.push({ name: 'automated-table-model' })
    },
    shouldDisplayTableModelField(field) {
      return field.type !== 'numeric'
      && (field.key !== 'ignoreChargesTable'
      || (field.key === 'ignoreChargesTable' && this.batchProject === 'CustomsEntryUpdate'))
    },
  },
}
</script>

<style scoped>
.table-model-wrapper {
    height: 100%!important;
    overflow-y: scroll;
}
.atm-btn {
  height: 37px;
  margin-top: 28px;
}
</style>
