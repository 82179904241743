<template>
  <span
    :class="`chip text-${variant} border-${variant}`"
  >
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.chip {
  text-transform: capitalize;
  background-color: transparent;
  border: thin solid grey;
  border-radius: 9999px;
  padding: 0px 10px;
  font-size: .8575rem;

  display: inline-block;
  vertical-align: middle;
  line-height: 22px;
  font-weight: 600;
}

</style>
