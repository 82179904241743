<template>
  <div class="h-100">
    <b-table-simple
      ref="table"
      class="h-100 mb-0"
      sticky-header="100%"
    >
      <b-thead>
        <b-tr>
          <b-th>Id</b-th>
          <b-th>Key Id</b-th>
          <b-th>Actions</b-th>
        </b-tr>
      </b-thead>
      <draggable
        v-model="records"
        tag="tbody"
        handle=".handle"
        v-bind="dragOptions"
      >
        <b-tr
          v-for="(record, recordIndex) of records"
          :key="recordIndex"
        >
          <b-td>{{ record.id }}</b-td>
          <b-td>{{ record.keyId }}</b-td>
          <b-td>
            <feather-icon
              icon="AlignJustifyIcon"
              class="cursor-move handle mr-1"
              size="20"
            />

            <feather-icon
              v-b-tooltip.hover
              icon="EditIcon"
              size="20"
              class="mr-1 cursor-pointer"
              title="Edit Rule Item"
              @click.stop="editRecord(recordIndex)"
            />

            <feather-icon
              v-b-tooltip.hover
              title="Delete Rule Item"
              icon="Trash2Icon"
              class="cursor-pointer mx-auto"
              size="20"
              @click.stop="deleteRecord(recordIndex)"
            />
          </b-td>
        </b-tr>
      </draggable>
    </b-table-simple>
  </div>
</template>

<script>
import {
  VBTooltip, BTableSimple, BThead, BTr, BTh, BTd,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    draggable,
    BTd,
  },
  computed: {
    records: {
      get() {
        return this.$store.getters['dataView/keyRuleItems']
      },
      set(value) {
        this.$store.commit('dataView/SET_KEY_RULE_ITEMS', value)
      },
    },
    dragOptions() {
      return {
        animation: 0,
        ghostClass: 'draggable-ghost',
      }
    },
  },
  methods: {
    editRecord(index) {
      this.$store.dispatch('dataView/setKeyRuleItemByIndex', index)
      this.$store.dispatch('dataView/setMode', 'key-rules')
    },
    deleteRecord(index) {
      this.records.splice(index, 1)
    },
  },
}
</script>
