<template>
  <div
    class="d-flex align-items-center definitions-toolbar"
  >
    <!-- Verification Toolbar -->
    <div
      v-if="mainMode === 'verification'"
      class="verification-toolbar-wrapper d-flex align-items-center"
    >
      <div class="document-selector-wrapper">
        <document-selector />
      </div>
      <div class="table-selector-wrapper">
        <table-selector />
      </div>
    </div>

    <node-editor
      v-if="mainMode === 'verification'"
      width="30"
    />

    <div
      v-if="dataViewerLoading && ['automated-table-model'].includes(mode)"
      class="add-table"
    >
      <add-table
        label="Table"
        button-variant="outline-primary"
        @add="addTable"
      />
    </div>

    <template v-if="!dataViewerLoading && !dataViewerLoadingError">
      <div
        v-if="['automated-table-model', 'table-models', 'table-columns', 'table-keys', 'table-rule-items', 'table-normalizer', 'test'].includes(mode)"
        class="add-table"
        :class="{'add-table-responsive': mode === 'test'}"
      >
        <add-table
          label="Table"
          button-variant="outline-primary"
          @add="addTable"
        />
      </div>

      <defined-key-actions v-if="mainMode === 'definedKeys'" />

      <mode-options v-if="mainMode === 'keySettings' || mainMode === 'tableSettings'" />

      <b-button
        v-if="batchView === 'explore-lookup'"
        :variant="'primary'"
      >
        Explore Lookup
      </b-button>

      <div
        v-if="mode === 'table-columns'"
        class="add-columns"
      >
        <add-item
          label="Column"
          button-variant="outline-primary"
          @add="addColumns"
        />
      </div>

      <div
        v-if="mode === 'table-normalizer'"
        class="add-columns"
      >
        <add-item
          label="Rule"
          button-variant="outline-primary"
          @add="addTableNormalizerItems"
        />
      </div>
      <b-button
        v-if="mode === 'table-rule-items' && tableFields.length"
        :disabled="!label"
        variant="outline-primary"
        @click.stop="selectOption('rules')"
      >
        <feather-icon
          icon="PlusIcon"
        />
        Add Rule
      </b-button>
      <v-select
        v-if="mode === 'table-rule-items' && tableFields.length"
        v-model="label"
        :options="tableFields"
        style="min-width: 150px;"
      />

      <template v-if="mode === 'test'">
        <test-options />
        <div>
          <b-form-checkbox
            v-model="displayNotInUseFields"
            v-b-tooltip.hover.top="{boundary:'window'}"
            title="Display Not in use fields"
          >
            Not in use
          </b-form-checkbox>
        </div>

        <div>
          <batch-defintion-version />
        </div>
      </template>

      <div
        v-if="mode === 'key-models'"
      >
        <b-button
          variant="outline-primary"
          :disabled="!enableAddKeyModel"
          @click="addKeyModel"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Model</span>
        </b-button>
      </div>

      <div
        v-if="mode === 'key-items' || mode === 'table-keys'"
        class="add-keys"
      >
        <add-item
          label="Key"
          button-variant="outline-primary"
          @add="addKeys"
        />
      </div>

      <div
        v-if="mode === 'key-rules'"
        class="add-columns"
      >
        <add-item
          label="Rule"
          button-variant="outline-primary"
          @add="addKeyRules"
        />
      </div>

      <b-button
        v-if="mode === 'key-rules'"
        variant="outline-primary"
        @click="displayCopyKeyRules = true"
      >
        Copy Rules
      </b-button>

      <template v-if="lookupInitialized && (mode === 'key-lookup' || batchView === 'explore-lookup' )">
        <div
          class="add-columns"
        >
          <add-item
            label="Query"
            plural-label="Queries"
            button-variant="outline-primary"
            @add="addQueries"
          />
        </div>
        <run-lookup />
      </template>

      <div
        v-if="mode === 'table-rules'"
        class="add-columns"
      >
        <add-item
          label="Rule"
          button-variant="outline-primary"
          @add="addTableRules"
        />
      </div>

      <b-button
        v-if="mode === 'table-rules'"
        variant="outline-primary"
        @click="displayCopyTableRules = true"
      >
        Copy Rules
      </b-button>

      <b-button
        v-if="(mainMode === 'keySettings' && mode !== 'key-lookup') || (mode === 'key-lookup' && lookupInitialized)"
        variant="outline-primary"
        :disabled="savingKeys || validatingKeys"
        @click="saveKeys"
      >
        Save
        <b-spinner
          v-if="savingKeys"
          small
          label="Small Spinner"
        />
      </b-button>

      <test-options
        v-if="mainMode === 'keySettings' || batchView === 'explore-lookup' "
      />

      <div class="flex-grow-1" />

      <b-button
        v-if="mode === 'table-models' && !isExcelBatch"
        :variant="testModelDisabled ? 'outline-secondary' : 'outline-primary'"
        :disabled="testModelDisabled || submittingModelsTestRequst"
        @click="testModels"
      >
        Test Models
        <b-spinner
          v-if="submittingModelsTestRequst"
          small
          label="Small Spinner"
        />
      </b-button>
      <b-button
        v-if="isExcelBatch"
        class="ml-auto"
        variant="primary"
        @click="cellLabelControllar"
      >
        {{ sheetNamePermission }}
      </b-button>

      <b-button
        v-if="mainMode === 'tableSettings' && mode !== 'test' "
        variant="outline-primary"
        :disabled="savingTable || validatingTable"
        @click="saveTable"
      >
        Save
        <b-spinner
          v-if="savingTable"
          small
          label="Small Spinner"
        />
      </b-button>

      <b-button
        v-if="mode === 'key-rules'"
        variant="outline-primary"
        @click="closeKeyRules"
      >
        <feather-icon
          icon="ChevronsLeftIcon"
          class="mr-25"
        />
        <span class="pb_1px">Back to Rules</span>
      </b-button>

      <b-button
        v-if="mode === 'key-lookup'"
        variant="outline-primary"
        @click="closeKeyLookups"
      >
        <feather-icon
          icon="ChevronsLeftIcon"
          class="mr-25"
        />
        <span class="pb_1px">Back to Lookups</span>
      </b-button>

      <b-button
        v-if="mode === 'table-rules'"
        variant="outline-primary"
        @click="closeTableRules"
      >
        <feather-icon
          icon="ChevronsLeftIcon"
          class="mr-25"
        />
        <span class="pb_1px">Back to Table Rules</span>
      </b-button>

      <b-button
        v-if="batchView === 'explore-lookup'"
        variant="outline-primary"
        @click="switchToKeyView"
      >
        Back to Key View
      </b-button>
    </template>

    <atm-actions v-if="mainMode === 'automatedTableModel'" />

    <copy-rules
      v-if="displayCopyKeyRules"
      mode="key"
      @modal-closed="displayCopyKeyRules = false"
    />

    <copy-rules
      v-if="displayCopyTableRules"
      mode="table"
      @modal-closed="displayCopyTableRules = false"
    />
    <b-button
      v-if="mainMode === 'verification' && !isDeleteRow"
      class="ml-auto"
      :variant="isAddRow ? 'primary' : 'secondary'"
      @click="activeAddRow"
    >
      <feather-icon icon="CopyIcon" />
      Add a row
    </b-button>
    <b-button
      v-if="mainMode === 'verification' && !isAddRow && !isRowDeleteOption && isAnyDeletableRow"
      class="ml-auto"
      :variant="isDeleteRow ? 'danger' : 'secondary'"
      @click="activeDeleteRow"
    >
      <feather-icon icon="Trash2Icon" />
      Delete a row
    </b-button>
    <template v-if="isDeleteRow && isRowDeleteOption">
      <p class="text-danger mb-0">
        Delete secleted row?
      </p>
      <b-button-group
        size="sm"
      >
        <b-button
          variant="outline-danger"
          @click="confirmRowDelete"
        >
          <feather-icon
            icon="CheckIcon"
            size="16"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="cancleRowDelete"
        >
          <feather-icon
            icon="XIcon"
            size="16"
          />
        </b-button>
      </b-button-group>
    </template>
  </div>
</template>

<script>
import {
  BButton, BSpinner, BFormCheckbox, VBTooltip, BButtonGroup,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddItem from '@/components/UI/AddItem.vue'
import AddTable from '@/components/UI/AddTable.vue'
import vSelect from 'vue-select'
import bus from '@/bus'
import BatchDefintionVersion from '@/components/Batch/BatchDefinitionVersion.vue'

import TableSelector from '@/components/UI/TableSelector.vue'
import DocumentSelector from '../SelectorToolbar/DocumentSelector.vue'
import ModeOptions from './ModeOptions.vue'
import DefinedKeyActions from './DefinedKeyActions.vue'
import AtmActions from './AtmActions.vue'
import TestOptions from './TestOptions.vue'
import RunLookup from './RunLookup.vue'
import CopyRules from './CopyRules.vue'
import NodeEditor from '../NodeEditor.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BButtonGroup,
    BSpinner,
    AddItem,
    AddTable,
    BFormCheckbox,
    DefinedKeyActions,
    AtmActions,
    TestOptions,
    vSelect,
    BatchDefintionVersion,
    RunLookup,
    CopyRules,
    NodeEditor,
    ModeOptions,
    TableSelector,
    DocumentSelector,
  },
  data() {
    return {
      savingTable: false,
      savingKeys: false,
      validatingTable: false,
      validatingKeys: false,
      submittingModelsTestRequst: false,
      displayCopyKeyRules: false,
      displayCopyTableRules: false,
      isRowDeleteOption: false,
      label: '',
      isfullCellLabel: true,
      sheetNamePermission: 'Hide Sheet Name',
    }
  },
  computed: {
    getIsfullCellLabel() {
      return this.$store.getters['dataView/getIsfullCellLabel']
    },
    currentRouteName() {
      return this.$route.name
    },
    profileName() {
      // if (this.name && this.project && this.documents.docType) {
      //   return `${this.name.toUpperCase()}_${this.project}_${this.documents.docType}`
      // }
      return ''
    },
    batchView() {
      return this.$store.getters['batch/view']
    },
    dataViewerLoading() {
      return this.$store.getters['dataView/loading']
    },
    dataViewerLoadingError() {
      return this.$store.getters['dataView/loadingError']
    },
    mainMode: {
      get() {
        return this.$store.getters['dataView/mainMode']
      },
      set(value) {
        this.$store.dispatch('dataView/setMainMode', value)
      },
    },
    mode() {
      return this.$store.getters['dataView/mode']
    },
    selectedDefinition() {
      return this.$store.getters['dataView/selectedDefinition']
    },
    selectedModelType() {
      return this.$store.getters['dataView/selectedModelType']
    },
    options() {
      return this.$store.getters['applicationSettings/options']
    },
    displayNotInUseFields: {
      get() {
        return this.$store.getters['dataView/displayNotInUseFields']
      },
      set(value) {
        this.$store.commit('dataView/SET_DISPLAY_NOT_IN_USE_FIELDS', value)
      },
    },
    lookupInitialized() {
      return this.$store.getters['lookup/initialized']
    },
    isExcelBatch() {
      return this.$store.getters['batch/batch']?.isExcel
    },
    enableAddKeyModel() {
      return this.$store.getters['dataView/keyModels'].length === 0
    },
    testModelDisabled() {
      const modeltypes = this.options['options-meta-root-model-type'].items
      const modeltype = modeltypes.find(e => e.value === this.selectedModelType)

      return !modeltype?.applicableFor.includes('test')
    },
    isAddRow() {
      return this.$store.getters['batch/getIsAddRow']
    },
    isDeleteRow() {
      return this.$store.getters['batch/getIsDeleteRow']
    },
    isAnyDeletableRow() {
      return this.$store.getters['batch/getIsAnyDeletableRow']
    },
    tables() {
      // eslint-disable-next-line no-unused-expressions
      return this.$store.getters['batch/selectedDocument']?.tables || []
    },
    selectedTableId() {
      return this.$store.getters['dataView/selectedTableId']
    },
    table() {
      const definitionTables = this.$store.getters['dataView/table']

      if (!definitionTables.length) {
        return null
      }

      const { table_unique_id } = definitionTables[this.selectedTableId]

      // eslint-disable-next-line camelcase
      return this.tables.find(i => i.table_unique_id === table_unique_id)
    },
    definitionTableColumns() {
      const tableColumns = this.$store.getters['dataView/tableColumns']

      if (!tableColumns) { return [] }
      return tableColumns.map(tableColumn => tableColumn.colLabel)
    },
    tableFields() {
      if (!this.table) return []

      let keys = []
      this.table.rows.forEach(item => {
        keys = keys.concat(Object.keys(item))
      })
      keys = [...new Set(keys)]

      const sortedKeys = []

      this.definitionTableColumns.forEach(definitionTableColumn => {
        if (keys.includes(definitionTableColumn) && !sortedKeys.includes(definitionTableColumn)) {
          sortedKeys.push(definitionTableColumn)
        }
      })
      if (this.mainMode === 'verification') {
        keys.forEach(key => {
          if (!sortedKeys.includes(key) && !key.startsWith('None') && !key.startsWith('notInUse') && !key.endsWith('_1') && !key.endsWith('_2')) {
            sortedKeys.push(key)
          }
        })
      } else {
        keys.forEach(key => {
          if (!sortedKeys.includes(key)) {
            sortedKeys.push(key)
          }
        })
      }
      return sortedKeys
    },
  },
  created() {
    bus.$on('dataView/onTableChange', this.onTableChange)
    bus.$on('dataView/saveTableData', this.saveTableData)
    bus.$on('dataView/toggleRowDeleteOption', this.toggleRowDeleteOption)
    this.isfullCellLabel = this.getIsfullCellLabel
    if (this.getIsfullCellLabel) {
      this.sheetNamePermission = 'Hide Sheet Name'
    } else {
      this.sheetNamePermission = 'Show Sheet Name'
    }
  },
  destroyed() {
    bus.$off('dataView/onTableChange', this.onTableChange)
    bus.$off('dataView/saveTableData', this.saveTableData)
    bus.$off('dataView/toggleRowDeleteOption', this.toggleRowDeleteOption)
  },
  methods: {
    async onTableChange(data) {
      const { curentTableId, prevTableId } = data
      let noValidationError = true

      if (!['test', 'automated-table-model'].includes(this.mode)) {
        noValidationError = await this.saveTable()
      }

      if (!noValidationError) {
        this.$store.commit('dataView/SET_SELECTED_TABLE_ID', prevTableId)

        return
      }

      this.$store.commit('dataView/SET_SELECTED_TABLE_ID', curentTableId)
      bus.$emit('dataView/refreshData')
    },
    displayInvalidFormError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please correct the form errors',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
    async saveTable(PointerEvent) {
      this.validatingTable = true

      const tableModelsIsValid = await this.validateForm('validateTableModel')
      if (!tableModelsIsValid) {
        if (this.mode !== 'table-models') {
          this.$store.dispatch('dataView/setMode', 'table-models')
        }
        this.displayInvalidFormError()
        this.validatingTable = false
        return
      }

      const tablKeysIsValid = await this.validateForm('validateKeyItems')
      if (!tablKeysIsValid) {
        if (this.mode !== 'table-keys') {
          this.$store.dispatch('dataView/setMode', 'table-keys')
        }
        this.displayInvalidFormError()
        this.validatingTable = false
        return
      }

      const tableNormalizerItemsIsValid = await this.validateForm('validateTableNormalizerItems')
      if (!tableNormalizerItemsIsValid) {
        if (this.mode !== 'table-normalizer') {
          this.$store.dispatch('dataView/setMode', 'table-normalizer')
        }
        this.displayInvalidFormError()
        this.validatingTable = false
        return
      }

      if (this.mode === 'table-rules') {
        const tableRulesIsValid = await this.validateForm('validateTableRules')
        if (!tableRulesIsValid) {
          this.displayInvalidFormError()
          this.validatingTable = false
          return
        }
      }

      this.validatingTable = false

      if (!PointerEvent) {
        // eslint-disable-next-line consistent-return
        return true
      }

      this.saveTableData()
    },
    async saveTableData() {
      this.savingTable = true

      if (this.mode === 'table-rules') {
        await this.$store.dispatch('dataView/saveTableRuleItem')
      }

      const table = this.$store.getters['dataView/table']

      let url
      let payload
      const batch = this.$store.getters['batch/batch']

      if (this.currentRouteName === 'template-batch') {
        url = 'dashboard/update_template_definition_by_version/'
        payload = {
          template_name: batch.definitionId || '',
          definition_version: this.$store.getters['dataView/selectedDefinitionVersion'],
          table,
        }
      } else {
        url = '/update_definition_by_version/'
        payload = {
          definition_id: this.selectedDefinition.definition_id,
          type: this.selectedDefinition.type,
          name_matching_text: this.selectedDefinition.name_matching_text,
          definition_version: this.$store.getters['dataView/selectedDefinitionVersion'],
          table,
        }
      }

      axios.post(url, payload)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Definition updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.savingTable = false
          this.$store.dispatch('dataView/setSelectedDefinition', res.data)
        })
        .catch(error => {
          const message = error?.response?.data?.detail || 'Something went wrong'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.savingTable = false
        })
    },
    async addTable(data) {
      if (this.batchView === 'table') {
        const noValidationError = await this.saveTable()
        if (!noValidationError) {
          return
        }
      }

      await this.$store.dispatch('dataView/addTable', {
        tableName: data?.tableName,
        tableId: data?.tableId,
      })

      this.$store.commit('dataView/SET_SELECTED_TABLE_ID', data?.tableId)

      this.saveTableData()

      if (this.mode !== 'automated-table-model') {
        this.$store.dispatch('dataView/setMode', 'table-models')
      }
    },
    addColumns(count) {
      bus.$emit('dataView/addColumns', count)
    },
    addTableNormalizerItems(count) {
      bus.$emit('dataView/addTableNormalizerItems', count)
    },
    async saveKeys() {
      this.validatingKeys = true

      const keyItemsIsValid = await this.validateForm('validateKeyItems')
      if (!keyItemsIsValid) {
        if (this.mode !== 'key-items') {
          this.$store.dispatch('dataView/setMode', 'key-items')
        }
        this.displayInvalidFormError()
        this.validatingKeys = false
        return
      }

      if (this.mode === 'key-rules') {
        const keyRulesIsValid = await this.validateForm('validateKeyRules')
        if (!keyRulesIsValid) {
          this.displayInvalidFormError()
          this.validatingKeys = false
          return
        }
      }

      this.validatingKeys = false
      this.saveKeysData()
    },
    validateForm(eventName) {
      return new Promise(resolve => {
        bus.$emit(eventName, isValid => {
          resolve(isValid)
        })
      })
    },
    async saveKeysData() {
      this.savingKeys = true

      if (this.mode === 'key-rules') {
        await this.$store.dispatch('dataView/saveKeyRuleItem')
      }

      if (this.mode === 'key-lookup') {
        this.$store.dispatch('dataView/saveKeyLookupItem')
      }
      let url
      let payload
      const key = this.$store.getters['dataView/key']
      if (!this.isfullCellLabel) {
        key.items.forEach(item => {
          if (item.typeData && item.typeData.cellRangeItems) {
            item.typeData.cellRangeItems.forEach(cellRangeItem => {
              // eslint-disable-next-line no-param-reassign
              cellRangeItem.sheetName = ''
            })
          }
        })
      }
      const batch = this.$store.getters['batch/batch']
      if (this.currentRouteName === 'template-batch') {
        url = 'dashboard/update_template_definition_by_version/'
        payload = {
          template_name: batch?.definitionId || '',
          definition_version: this.$store.getters['dataView/selectedDefinitionVersion'],
          key,
        }
      } else {
        url = '/update_definition_by_version/'
        payload = {
          definition_id: this.selectedDefinition.definition_id,
          type: this.selectedDefinition.type,
          name_matching_text: this.selectedDefinition.name_matching_text,
          definition_version: this.$store.getters['dataView/selectedDefinitionVersion'],
          key,
        }
      }

      axios.post(url, payload)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Definition updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.savingKeys = false
          this.$store.dispatch('dataView/setSelectedDefinition', res.data)
        })
        .catch(error => {
          const message = error?.response?.data?.detail || 'Something went wrong'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.savingKeys = false
        })
    },
    addKeys(count) {
      bus.$emit('dataView/addKeys', count)
    },
    addKeyModel() {
      bus.$emit('dataView/addKeyModels', 1)
    },
    testModels() {
      this.submittingModelsTestRequst = true
      const batch = this.$store.getters['batch/batch']
      const definition = this.$store.getters['dataView/selectedDefinition']
      const selectedDocumentId = this.$store.getters['batch/selectedDocumentId']

      axios.post('/pipeline/test_models/', {
        batch_id: batch.id,
        definition,
      })
        .then(res => {
          const tableBoundaryFound = res.data.data[selectedDocumentId]?.table_boundary_found
          if (tableBoundaryFound) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Table Boundary Found',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Table Boundary Not Found',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          this.submittingModelsTestRequst = false
        })
        .catch(error => {
          const message = error?.response?.data?.detail || 'Error running model test'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.submittingModelsTestRequst = false
        })
    },
    closeKeyRules() {
      this.$store.dispatch('dataView/setMode', 'key-rule-items')
    },
    closeKeyLookups() {
      this.$store.dispatch('dataView/setMode', 'key-lookup-items')
    },
    closeTableRules() {
      this.$store.dispatch('dataView/setMode', 'table-rule-items')
    },
    switchToKeyView() {
      this.$store.commit('batch/SET_VIEW', 'key')
    },
    addKeyRules(count) {
      bus.$emit('dataView/addKeyRules', count)
    },
    addQueries(count) {
      bus.$emit('dataView/addQueries', count)
    },
    addTableRules(count) {
      bus.$emit('dataView/addTableRules', count)
    },
    activeAddRow() {
      this.$store.commit('batch/TOGGLE_ADD_ROW', !this.isAddRow)
    },
    activeDeleteRow() {
      this.$store.commit('batch/TOGGLE_DELETE_ROW', !this.isDeleteRow)
    },
    confirmRowDelete() {
      bus.$emit('dataView/confirmRowDelete')
    },
    cancleRowDelete() {
      bus.$emit('dataView/cancleRowDelete')
    },
    toggleRowDeleteOption(val) {
      this.isRowDeleteOption = val
    },
    selectOption(value) {
      if (value === 'rules') {
        this.$store.dispatch('dataView/setTableRuleItem', {
          label: this.label,
        })
        this.$store.dispatch('dataView/setMode', 'table-rules')
      }
    },
    cellLabelControllar() {
      this.isfullCellLabel = !this.isfullCellLabel
      if (this.isfullCellLabel) {
        this.sheetNamePermission = 'Hide Sheet Name'
      } else {
        this.sheetNamePermission = 'Show Sheet Name'
      }
      this.$store.commit('dataView/SET_IS_FULL_CELL_LABEL', this.isfullCellLabel)
    },
  },
}
</script>

<style scoped>
.btn {
  padding: 0.75rem !important;
  font-size: 12px;
}
.definitions-toolbar, .verification-toolbar-wrapper {
    column-gap: 1rem;
}
.document-selector-wrapper {
  width: 250px;
}
.table-selector-wrapper {
  width: 220px;
}
.add-table {
  width: 280px;
}
.add-columns, .add-keys {
  width: 180px;
  display: inline-block;
}
.add-table-responsive {
  width: 230px;
}

@media (max-width: 1080px) {
  .add-table-responsive {
    width: 300px;
  }
}

@media (max-width: 992px) {
  .add-table-responsive {
    width: 330px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.pb_1px {
  margin-bottom: 1px;
}
</style>
