<template>
  <b-button-group>
    <b-button
      variant="outline-primary"
      :disabled="runningTestBatch || runningTestDocument"
      @click="runTest('batch')"
    >
      Test Batch
      <b-spinner
        v-if="runningTestBatch"
        small
        label="Small Spinner"
      />
    </b-button>
    <b-button
      variant="outline-primary"
      :disabled="runningTestBatch || runningTestDocument"
      @click="runTest('document')"
    >
      Test Document
      <b-spinner
        v-if="runningTestDocument"
        small
        label="Small Spinner"
      />
    </b-button>
    <b-dropdown
      right
      variant="outline-primary"
      :disabled="runningTestBatch || runningTestDocument"
      no-caret
      size="sm"
    >
      <template #button-content>
        <feather-icon
          icon="ChevronDownIcon"
          size="20"
        />
      </template>
      <b-dropdown-form style="width: 250px;">
        <b-form-group>
          <b-form-checkbox
            v-model="testOptions.skipPostProcessor"
            v-b-tooltip.hover.top="{boundary:'window'}"
            title="Skip Post-Processing to view raw results"
          >
            Skip Post-Processor
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="testOptions.skipKeyProcessing"
          >
            Skip Key Processing
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="testOptions.skipTableProcessing"
          >
            Skip Table Processing
          </b-form-checkbox>
        </b-form-group>
      </b-dropdown-form>
    </b-dropdown>
  </b-button-group>
</template>

<script>
import {
  BDropdown, BDropdownForm, BFormGroup, BButton, BSpinner, BButtonGroup, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'
import { isEqual, cloneDeep } from 'lodash'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BDropdown,
    BDropdownForm,
    BFormGroup,
    BButton,
    BSpinner,
    BButtonGroup,
    BFormCheckbox,
  },
  data() {
    return {
      runningTestBatch: false,
      runningTestDocument: false,
      testOptions: {},
    }
  },
  computed: {
    storeTestOptions() {
      return this.$store.getters['batch/testOptions']
    },
    currentRouteName() {
      return this.$route.name
    },
  },
  watch: {
    testOptions: {
      deep: true,
      handler() {
        this.setTestOptions()
      },
    },
    storeTestOptions: {
      deep: true,
      handler() {
        this.loadTestOptions()
      },
    },
  },
  created() {
    this.loadTestOptions()
  },
  methods: {
    loadTestOptions() {
      if (!isEqual(this.testOptions, this.storeTestOptions)) {
        this.testOptions = cloneDeep(this.storeTestOptions)
      }
    },
    setTestOptions() {
      if (!isEqual(this.testOptions, this.storeTestOptions)) {
        this.$store.commit('batch/SET_TEST_OPTIONS', cloneDeep(this.testOptions))
      }
    },
    runTest(mode) {
      const flagname = mode === 'document' ? 'runningTestDocument' : 'runningTestBatch'

      this[flagname] = true

      const batch = this.$store.getters['batch/batch']
      const documentId = this.$store.getters['batch/selectedDocumentId']
      const selectedDefinitionVersion = this.$store.getters['dataView/selectedDefinitionVersion']
      const params = {
        batch_id: batch.id,
        skip_post_processor: this.testOptions.skipPostProcessor,
        skip_key_processing: this.testOptions.skipKeyProcessing,
        skip_table_processing: this.testOptions.skipTableProcessing,
        definition_version: selectedDefinitionVersion,
      }
      if (mode === 'document') {
        params.document_id = documentId
      }
      if (this.currentRouteName === 'template-batch') {
        params.template = batch.definitionId
      }

      axios.post('/pipeline/process_batch/', null, {
        params,
      }).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.detail,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this[flagname] = false
      }).catch(error => {
        const message = error?.response?.data?.detail || 'Something went wrong'
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this[flagname] = false
      })
    },
  },
}
</script>

<style scoped>
.btn {
  padding: 0.75rem !important;
  font-size: 12px;
}
</style>
