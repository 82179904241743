<template>
  <div
    class="node d-flex align-items-center"
    :class="{
      highlighted: highlighted,
      'search-match': searchMatch,
    }"
    @click="clickHandler"
    @dblclick="dbClickHandler"
  >
    <div class="flex-grow-1">
      <span v-if="expandable">
        {{ expanded ? '-' : '+' }}
      </span>

      <b-badge
        v-if="label !== ''"
        :variant="badgeVariant"
      >
        {{ label }}
      </b-badge>

      <span v-if="mainMode !== 'verification' || !editableNode || editableNode.id !== id">
        &nbsp;<span style="white-space: pre-wrap">{{ label !== '' ? `- ${title}`: title }}</span>
      </span>
    </div>

    <!-- Edit node value -->
    <node-editor
      v-if="mainMode === 'verification' && editableNode && editableNode.id === id"
      class="ml-1"
    />

    <div v-if="type === 'root'">
      <!-- This functinality will applied later -->
      <!-- <div
        v-if="expandAllEligibility"
        class="badge badge-primary mr-1"
        @click.stop="toExpandAll"
      >
      <feather-icon
                icon="ChevronsDownIcon"
                size="12"
              />
      </div> -->
      <div
        v-if="collapseAllEligibility"
        class="badge badge-primary mr-1"
        @click.stop="toCollapseAll"
      >
        <feather-icon
          icon="ChevronsUpIcon"
          size="12"
        />
      </div>
      <batch-defintion-version />
    </div>
    <div v-else>
      <span
        v-if="test"
        class="d-inline-block mx-1 text-primary"
      >{{
        test
      }}</span>

      <batch-node-config
        v-if="mode === 'edit' && mainMode !== 'verification'"
        :node-id="id"
        :nested-label="nestedLabel"
        :key-id="keyId"
        :config-data="configData"
      />
    </div>
  </div>
</template>

<script>
import bus from '@/bus'
import { BBadge } from 'bootstrap-vue'
import BatchDefintionVersion from '@/components/Batch/BatchDefinitionVersion.vue'
import BatchNodeConfig from './BatchNodeConfig.vue'
import NodeEditor from '../NodeEditor.vue'

export default {
  components: {
    BBadge,
    BatchNodeConfig,
    NodeEditor,
    BatchDefintionVersion,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    expandable: {
      type: Boolean,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
    test: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    highlighted: {
      type: Boolean,
      requred: true,
    },
    searchMatch: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    badgeVariant: {
      type: String,
      required: true,
    },
    nestedLabel: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    keyId: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    configData: {
      type: Object,
      required: true,
    },
    collapseAllEligibility: {
      type: Boolean,
      default: true,
    },
    expandAllEligibility: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    mainMode() {
      return this.$store.getters['dataView/mainMode']
    },
    mode() {
      return this.$store.getters['batch/mode']
    },
    isExcelBatch() {
      return this.$store.getters['batch/batch'].isExcel
    },
    selectedDocumentId() {
      return this.$store.getters['batch/selectedDocumentId']
    },
    editableNode() {
      return this.$store.getters['batch/editableNode']
    },
    manualValidation() {
      return this.$store.getters['batch/manualValidation']
    },
    verificationStatus() {
      return this.$store.getters['batch/verificationStatus']
    },
    verificationDetails() {
      return this.$store.getters['batch/verificationDetails']
    },
  },
  methods: {
    async clickHandler() {
      if (this.expandable) {
        if (!this.expanded) {
          this.$store.commit('batch/EXPAND_NODE', this.id)
        } else {
          this.$store.commit('batch/COLLAPSE_NODE', this.id)
        }
      }

      await this.$store.dispatch('batch/setSelectedNodeId', this.id)

      if (this.highlighted) {
        const batchId = `${this.id.split('.')[0]}.${this.id.split('.')[1]}`
        const documentId = `${batchId}.${this.id.split('.')[2]}`

        if (this.id.split('.').length > 3 && this.selectedDocumentId !== documentId) {
          const batch = this.verificationDetails.find(e => e.id === batchId)

          this.$store.commit('batch/SET_BATCH', { id: batchId, subPath: batch?.sub_path || '' })
          this.$store.commit('batch/SET_SELECTED_DOCUMENT_ID', documentId)

          await this.$store.dispatch('batch/loadDocumentData')
        }
      }

      if (this.type === 'table') {
        this.$store.commit('batch/SET_VIEW', 'table')
      } else if (this.isExcelBatch) {
        bus.$emit('goToSelectedNodeCell')
      } else {
        this.$nextTick(() => {
          bus.$emit('scrollToHighlightedNode')
        })
      }
    },
    async dbClickHandler() {
      if (this.mainMode !== 'verification' || this.expandable || this.verificationStatus !== 'ready' || !this.manualValidation) {
        return
      }

      await this.$store.dispatch('batch/setEditableNode', { id: this.id, v: this.title })
    },
    toCollapseAll() {
      this.$emit('to-collapse-all')
    },
    toExpandAll() {
      this.$emit('to-expand-all')
    },
  },
}
</script>

<style lang="scss" scoped>
.node {
    border: 1px solid #ebe9f1;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
    cursor: pointer;

    &.highlighted {
        border: 2px solid red !important;
    }

    &.search-match {
        border: 2px solid orange !important;
    }

    &.highlighted.search-match {
        border: 2px solid #d37523 !important;
    }
}

.dark-layout .node {
    border-color:#3b4253;
}
</style>
