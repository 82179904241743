<template>
  <div
    class="d-flex flex-column"
    :class="{'h-100 justify-content-center': loading && (atmWizardTabs.results.active || atmWizardTabs.test.active)}"
  >
    <atm-wizard>
      <template #results>
        <app-collapse
          v-if="Object.keys(atmPatternTableRows).length"
        >
          <template
            v-for="(item, index) in atmAccordionOptions"
          >
            <app-collapse-item
              v-if="Object.keys(atmPatternTableRows).includes(item.key)"
              :key="index"
              :is-visible="item.visible"
              :title="item.label"
              @visible="(val) => updateVisible(val, item)"
            >
              <atm-pattern-table
                :rows="atmPatternTableRows[item.key]"
                @checked="checked"
              />
            </app-collapse-item>
          </template>
        </app-collapse>
      </template>
    </atm-wizard>
  </div>
</template>

<script>
import bus from '@/bus'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AtmPatternTable from './AtmPatternTable.vue'
import AtmWizard from './AtmWizard/AtmWizard.vue'

export default {
  components: {
    AtmPatternTable,
    AtmWizard,
    AppCollapse,
    AppCollapseItem,
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters['dataView/loading']
      },
      set(value) {
        this.$store.commit('dataView/SET_LOADING', value)
      },
    },
    status() {
      return this.$store.getters['batch/status']
    },
    batch() {
      return this.$store.getters['batch/batch']
    },
    selectedDocumentId() {
      return this.$store.getters['batch/selectedDocumentId']
    },
    totalPages() {
      return Object.keys(this.$store.getters['batch/documentData'].pages).length
    },
    atmWizardTabs() {
      return this.$store.getters['atm/atmWizardTabs']
    },
    atmAccordionOptions() {
      return this.$store.getters['atm/atmAccordionOptions']
    },
    atmPatterns() {
      return this.$store.getters['atm/atmPatterns']
    },
    atmPatternTableRows() {
      return this.$store.getters['atm/atmPatternTableRows']
    },
    selectedAtmPatterns() {
      return this.$store.getters['atm/selectedAtmPatterns']
    },
    atmPatternRecords() {
      return this.$store.getters['atm/atmPatternRecords']
    },
    atmConfig() {
      return this.$store.getters['atm/atmConfig']
    },
    chunkLineRecords() {
      return this.$store.getters['atm/chunkLineRecords']
    },
    userSelectedPatterns() {
      return this.$store.getters['atm/userSelectedPatterns']
    },
    extendedUserSelectedPatterns() {
      return this.$store.getters['atm/extendedUserSelectedPatterns']
    },
    multipleLineRecord() {
      return this.$store.getters['dataView/modelMultipleLineRecord']
    },
    selectedDefinitionVersion() {
      return this.$store.getters['dataView/selectedDefinitionVersion']
    },
  },
  watch: {
    atmPatterns(newVal) {
      if (newVal.length) {
        this.$store.dispatch('atm/generateAtmPatternTableRows')
      } else {
        this.$store.commit('atm/SET_ATM_PATTERN_TABLE_ROWS', [])
      }
    },
    status: {
      handler() {
        if (['completed', 'failed'].includes(this.status.status)) {
          if (this.atmWizardTabs.test.active) {
            bus.$emit('atm/onChangeTab', 'test')
          } else {
            bus.$emit('atm/onChangeTab', 'results')
          }
        }
      },
      deep: true,
    },
  },
  created() {
    bus.$on('atm/updateStatus', this.updateStatus)
    bus.$on('atm/updateSelectedAtmPattern', this.updateSelectedAtmPattern)
  },
  destroyed() {
    bus.$off('atm/updateStatus', this.updateStatus)
    bus.$off('atm/updateSelectedAtmPattern', this.updateSelectedAtmPattern)
  },
  methods: {
    updateVisible(visibleState, item) {
      const atmAccordionOptions = [...this.atmAccordionOptions]

      atmAccordionOptions.forEach(({ key }, index) => {
        if (key === item.key) {
          atmAccordionOptions[index].visible = visibleState
        }
      })

      this.$store.commit('atm/SET_ATM_ACCORDION_OPTIONS', atmAccordionOptions)
    },
    checked(val) {
      if (val.scrollToPos) {
        this.$store.dispatch('batch/scrollToPos', val.data.pos)

        return
      }

      this.updateSelectedAtmPattern(val.data)

      this.updateStatus({ posRef: val.data.pos[7], autoChecked: false })
    },
    updateSelectedAtmPattern(data) {
      const selectedAtmPatterns = [...this.selectedAtmPatterns]
      const item = {
        pattern: data.pattern,
        multiLine: data.openBlock.toString(),
        digit_threshold: this.atmConfig.digit_threshold.value,
        text_threshold: this.atmConfig.text_threshold.value,
        n: this.atmConfig.record_line.value,
        pos: data.pos,
      }

      const index = selectedAtmPatterns.map(e => e.pattern).indexOf(item.pattern)

      if (index !== -1) {
        selectedAtmPatterns.splice(index, 1)

        if (this.multipleLineRecord) {
          this.removeTableRowSelectionForExtendedUserSelectedPatterns(data.pos[7])
        } else {
          this.removeTableRowSelectionForUserSelectedPatterns(data.pos[7])
        }
      } else {
        selectedAtmPatterns.push(item)
      }

      this.$store.commit('atm/SET_SELECTED_ATM_PATTERNS', selectedAtmPatterns)
    },
    removeTableRowSelectionForExtendedUserSelectedPatterns(posRef) {
      const extendedUserSelectedPatterns = []

      this.extendedUserSelectedPatterns.forEach(item => {
        let pageId = null
        let documentId = null
        let batchId = null
        let left
        let top
        let right
        let bottom

        item.forEach(e => {
          const [itemLeft, itemTop, itemRight, itemBottom, itemPageId, itemDocumentId, itemBatchId] = e.pos.split(',')

          if (pageId == null || documentId == null || batchId == null) {
            pageId = itemPageId
            documentId = itemDocumentId
            batchId = itemBatchId
          }

          if (!left || left > parseInt(itemLeft, 10)) {
            left = parseInt(itemLeft, 10)
          }

          if (!top || top > parseInt(itemTop, 10)) {
            top = parseInt(itemTop, 10)
          }

          if (!right || right < parseInt(itemRight, 10)) {
            right = parseInt(itemRight, 10)
          }

          if (!bottom || bottom < parseInt(itemBottom, 10)) {
            bottom = parseInt(itemBottom, 10)
          }
        })

        const pos = [left, top, right, bottom, pageId, documentId, batchId, posRef, 'green', 'green']

        if (this.atmPatternRecords.includes(pos.join(','))) {
          item.forEach(e => {
            this.$store.dispatch('atm/updateChunkLineRecords', e.pos)
          })
        } else {
          extendedUserSelectedPatterns.push(item)
        }
      })

      this.$store.commit('atm/SET_EXTENDED_USER_SELECTED_PATTERNS', extendedUserSelectedPatterns)
      this.$store.commit('dataView/SET_MODEL_EXTENDED_USER_SELECTED_PATTERNS', extendedUserSelectedPatterns)
    },
    removeTableRowSelectionForUserSelectedPatterns(posRef) {
      const userSelectedPatterns = []

      this.userSelectedPatterns.forEach(e => {
        const pos = e.pos.split(',')
        pos[7] = posRef
        pos[9] = 'green'

        if (this.atmPatternRecords.includes(pos.join(','))) {
          this.$store.dispatch('atm/updateChunkLineRecords', e.pos)
        } else {
          userSelectedPatterns.push(e)
        }
      })

      this.$store.commit('atm/SET_USER_SELECTED_PATTERNS', userSelectedPatterns)
      this.$store.commit('dataView/SET_MODEL_USER_SELECTED_PATTERNS', userSelectedPatterns)
    },
    updateStatus(data) {
      const posList = []
      const atmPatternRecords = [...this.atmPatternRecords]

      atmPatternRecords.forEach((e, i) => {
        const highlightedPos = e.split(',')

        const status = highlightedPos[8]
        let refStatus = highlightedPos[9]

        if (status === 'green' && refStatus === 'green') {
          refStatus = 'blank'
        }

        if (highlightedPos[7] === data.posRef) {
          if (data.autoChecked) {
            highlightedPos[8] = 'green'
          } else {
            highlightedPos[8] = status === 'green' ? refStatus : 'green'
          }

          posList.push({ index: i, pos: highlightedPos })
        }
      })

      posList.forEach((e, index) => {
        atmPatternRecords.splice(e.index - index, 1)
        atmPatternRecords.push(e.pos.join(','))
      })

      this.$store.commit('atm/SET_ATM_PATTERN_RECORDS', atmPatternRecords)
    },
  },
}
</script>
