<template>
  <div class="d-inline-block">
    <span class="font-italic mr-25">{{ configValue }}</span>
    <b-dropdown
      dropleft
      no-flip
      no-caret
      variant="link"
      toggle-class="text-decoration-none"
      boundary="window"
      class="text-primary config-dropdown"
      offset="-10"
    >
      <template #button-content>
        <feather-icon
          icon="SettingsIcon"
          size="16"
          class="cursor-pointer"
        />
      </template>

      <b-dropdown-item
        v-for="option of options"
        :key="option.value"
        :active="configValue === option"
        :disabled="option.disabled"
        @click.stop="setConfigValue(option.value)"
      >
        {{ option.label }}
      </b-dropdown-item>

      <b-dropdown-item
        v-if="options.length === 0"
        disabled
      >
        No Options
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdownItem, BDropdown } from 'bootstrap-vue'
import bus from '@/bus'

export default {
  components: {
    BDropdownItem,
    BDropdown,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    nestedLabel: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    keyId: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    configData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectedDefintionVersion() {
      return this.$store.getters['dataView/selectedDefinitionVersion']
    },
    batchDefinitionVersion() {
      return this.$store.getters['batch/batch'].definitionVersion
    },
    batchTestedWithDiffrentVersion() {
      return this.selectedDefintionVersion !== this.batchDefinitionVersion
    },
    enableLookups() {
      return this.$store.getters['applicationSettings/enableLookups']
    },
    options() {
      const configOptions = this.configData.options
      const options = []

      if (configOptions.includes('rules')) {
        options.push({
          label: 'Rules',
          value: 'rules',
          disabled: this.batchTestedWithDiffrentVersion,
        })
      }

      if (configOptions.includes('notInUse')) {
        options.push({
          label: 'Not in Use',
          value: 'notInUse',
          disabled: this.batchTestedWithDiffrentVersion,
        })
      }

      if (configOptions.includes('lookup') && this.enableLookups) {
        options.push({
          label: 'Lookup',
          value: 'lookup',
          disabled: this.batchTestedWithDiffrentVersion,
        })
      }

      if (configOptions.includes('addToDB') && this.enableLookups) {
        options.push({
          label: 'Add to DB',
          value: 'addToDB',
          disabled: false,
        })
      }

      return options
    },
    configValue() {
      return this.$store.getters['batch/nodeConfig'][this.nodeId]
    },
  },
  methods: {
    setConfigValue(value) {
      if (value === 'rules') {
        this.$store.dispatch('dataView/setKeyRuleItem', {
          id: this.nestedLabel,
          keyId: this.keyId,
        })
        this.$store.dispatch('dataView/setMode', 'key-rules')
      } else if (value === 'notInUse') {
        bus.$emit('addNotInUseItem', {
          keyId: this.keyId,
          nestedLabel: this.nestedLabel,
        })
        this.$store.dispatch('dataView/setMode', 'key-not-in-use-items')
      } else if (value === 'lookup') {
        const batchView = this.$store.getters['batch/view']
        if (batchView !== 'key') {
          this.$store.commit('batch/SET_VIEW', 'key')
        }

        this.$nextTick(() => {
          this.$store.dispatch('dataView/setKeyLookupItem', {
            keyId: this.keyId,
            nestedLabel: this.nestedLabel,
          })

          this.$store.dispatch('dataView/setMode', 'key-lookup')
        })
      } else if (value === 'addToDB') {
        const defaultData = this.configData.addToDBData
        bus.$emit('lookup/displayAddRecordForm', {
          record: defaultData.record,
          tableName: defaultData.tableName,
        })
      } else {
        // this.$store.dispatch('batch/setNodeConfig', { id: this.nodeId, value })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.config-dropdown {
    opacity: 0;

    &.show {
        opacity: 1;
    }

    &:hover {
        opacity: 1;
    }

    ::v-deep .btn {
        padding: 0px !important;
    }
    ::v-deep .dropdown-menu {
        margin-left: 1rem;
    }
}
</style>
