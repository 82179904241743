<template>
  <feather-icon
    v-b-tooltip.hover
    icon="MinimizeIcon"
    size="20"
    class="cursor-pointer"
    title="Fit to Width"
    @click="fitToWidth"
  />
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import bus from '@/bus'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    fitToWidth() {
      bus.$emit('fitToWidth')
    },
  },
}
</script>
