<template>
  <div>
    <feather-icon
      v-b-tooltip.hover
      icon="CopyIcon"
      size="20"
      class="cursor-pointer"
      title="Copy Definition Data"
      @click="copyData"
    />
    <copy-data-modal
      v-if="showModal"
      @modal-closed="showModal = false"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import CopyDataModal from './CopyDataModal.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    CopyDataModal,
  },
  data() {
    return {
      showModal: false,
    }
  },
  methods: {
    copyData() {
      this.showModal = true
    },
  },
}
</script>
