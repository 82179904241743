<template>
  <v-select
    v-model="resultIndex"
    :options="queryResultOptions"
    label="label"
    :reduce="option => option.value"
    :clearable="false"
  />
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      search: null,
    }
  },
  computed: {
    resultIndex: {
      get() {
        return this.$store.getters['lookup/resultIndex']
      },
      set(value) {
        this.$store.commit('lookup/SET_RESULT_INDEX', value)
      },
    },
    results() {
      return this.$store.getters['lookup/results']
    },
    queryResultOptions() {
      const options = []
      for (let index = 0; index < this.results.length; index += 1) {
        options.push({
          label: `Query Result #${index + 1}`,
          value: index,
        })
      }
      return options
    },
  },
  watch: {
    queryResultOptions: {
      deep: true,
      handler() {
        if (this.resultIndex > this.queryResultOptions.length - 1) {
          this.resultIndex = null
        }

        if (this.resultIndex === null && this.queryResultOptions.length > 0) {
          this.resultIndex = 0
        }
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
