<template>
  <div
    ref="keyModelsWrapper"
    class="key-models-wrapper"
  >
    <b-col md="12">
      <b-row
        v-for="(model, modelIndex) of models"
        :key="modelIndex"
        class="key-model-item"
      >
        <b-col
          cols="12"
        >
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="mb-0">
              Model #{{ modelIndex + 1 }}
            </h5>
            <feather-icon
              v-b-tooltip.hover
              title="Delete Model"
              icon="Trash2Icon"
              class="cursor-pointer delete-model-btn"
              size="20"
              @click.stop="deleteModel(modelIndex)"
            />
          </div>
        </b-col>
        <b-col
          v-for="(field, fieldIndex) of modelFields"
          :key="fieldIndex"
          lg="3"
        >
          <b-form-group
            v-if="field.type === 'text'"
            :label="field.label ? field.label: field.key"
          >
            <form-input
              v-model="models[modelIndex][field.key]"
              type="text"

              :placeholder="field.key"
            />
          </b-form-group>
          <b-form-group
            v-if="field.type === 'select'"
            :label="field.label ? field.label: field.key"
          >
            <v-select
              v-model="models[modelIndex][field.key]"
              :label="options[field.optionsId].lableKey"
              :options="options[field.optionsId].items"
              :reduce="option => option[options[field.optionsId].valueKey]"
            />
          </b-form-group>
          <pipe-separated-input
            v-if="field.type === 'pipeSeparatedInput'"
            v-model="models[modelIndex][field.key]"
            :label="field.label ? field.label: field.key"
            selection-value-attr="text"
            listenable-input
          />

          <multiple-text-input
            v-if="field.type === 'multiple-text'"
            v-model="models[modelIndex][field.key]"
            :placeholder="field.label ? field.label: field.key"
            :input-field-placeholder="field.inputFieldPlaceholder"
          />
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import {
  BFormGroup, VBTooltip, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { isEqual, cloneDeep } from 'lodash'
import bus from '@/bus'
import FormInput from '@/components/UI/FormInput.vue'
import PipeSeparatedInput from '@/components/UI/PipeSeparatedInput.vue'
import MultipleTextInput from '@/components/UI/MultipleTextInput/MultipleTextInput.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormGroup,
    vSelect,
    FormInput,
    BRow,
    BCol,
    PipeSeparatedInput,
    MultipleTextInput,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      models: [],
    }
  },
  computed: {
    modelFields() {
      return this.$store.getters['applicationSettings/keySettings'].model.fields
    },
    options() {
      return {
        ...this.$store.getters['applicationSettings/options'],
        ...this.$store.getters['definitionSettings/options'],
      }
    },
    out() {
      return cloneDeep(this.models)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
    bus.$on('dataView/addKeyModels', this.addKeyModels)
  },
  destroyed() {
    bus.$off('dataView/addKeyModels', this.addKeyModels)
  },
  methods: {
    setInternalState() {
      const items = this.value.map(record => {
        const item = {}
        this.modelFields.forEach(modelField => {
          let value
          if (modelField.type === 'multiple-text') {
            value = record[modelField.key] || []
          } else {
            value = String(record[modelField.key] || '')
          }
          item[modelField.key] = value
        })
        return item
      })
      this.models = items
    },
    addKeyModels(count) {
      const lastRowIndex = this.models.length - 1
      const expandStatus = []
      const cols = []
      for (let i = 0; i < count; i += 1) {
        const col = {}
        this.modelFields.forEach(modelField => {
          col[modelField.key] = modelField.defaultValue || ''
        })
        cols.push(col)
        expandStatus.push(false)
      }

      this.models = this.models.concat(cols)
      this.$nextTick(() => {
        this.scrollToIndex(lastRowIndex + 1)
      })
    },
    deleteModel(index) {
      this.models.splice(index, 1)
    },
    scrollToIndex(index) {
      const { keyModelsWrapper } = this.$refs
      const keyModelItem = keyModelsWrapper.querySelectorAll('.key-model-item')[index]
      keyModelsWrapper.scrollTop = keyModelItem.offsetTop
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.key-models-wrapper {
    height: 100%!important;
    overflow-y: scroll;
}
</style>
