<template>
  <div class="batch-page">
    <h4 class="m-50">
      {{ pageTitle }}
    </h4>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="!loading && error ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ error }}
        </p>
      </div>
    </b-alert>

    <div
      v-if="!loading && !error"
      :class="{ 'verification-box': dataViewMode === 'verification', 'box-extend': togoleNavbar, 'box': !togoleNavbar }"
    >
      <div
        v-if="dataViewMode !== 'verification' && false"
        style="margin: 0.5rem 0.5rem 1rem 0.5rem"
      >
        <selector-toolbar :image-viewer-area-content="imageViewerAreaContent" />
      </div>
      <splitpanes class="default-theme">
        <pane
          v-if="['key', 'explore-lookup'].includes(view)"
          :size="panelSizes.leftPaneSize"
        >
          <b-card
            class="mb-0"
            style="height:100%"
          >
            <div class="node-tree-conent d-flex flex-column">
              <div>
              <!-- <node-tree-toolbar /> -->
              </div>
              <!-- <div class="separator" /> -->
              <div class="flex-grow-1">
                <batch-node-tree />
              </div>
            </div>
          </b-card>
        </pane>
        <pane :size="100 - panelSizes.leftPaneSize">
          <splitpanes
            class="default-theme"
            horizontal
          >
            <pane :size="panelSizes.rightTopPaneSize">
              <b-card
                class="mb-0"
                style="height:100%"
              >
                <div
                  v-if="batch"
                  class="section-content"
                >
                  <div class="image-viewer-conent d-flex flex-column">
                    <div>
                      <image-view-toolbar
                        :image-viewer-area-content="imageViewerAreaContent"
                        :loaded-files="loadedFiles"
                        :total-files="totalFiles"
                      />
                    </div>
                    <div class="separator" />
                    <div class="flex-grow-1">
                      <!-- Using v-show below (instead of v-if) for image-viewer and excel-viewer to prevent re-creation (Expensive operation) of components when switching back from query-results view
                          v-if is used (along with v-show) to control when that component should initialzed and when not.
                          i.e. excel viewer should not be rendered/initialized in case of pdf batch -->
                      <image-viewer
                        v-if="!isExcelBatch"
                        v-show="imageViewerAreaContent === 'image-viewer'"
                        @calculate-loaded-files="onCalculateLoadedFiles"
                      />
                      <excel-viewer
                        v-if="isExcelBatch"
                        v-show="imageViewerAreaContent === 'excel-viewer'"
                      />
                      <query-results
                        v-if="imageViewerAreaContent === 'query-results'"
                      />
                    </div>
                  </div>
                </div>
              </b-card>
            </pane>
            <pane
              v-if="!(dataViewMode === 'verification' && view === 'key')"
              :size="100 - panelSizes.rightTopPaneSize"
            >
              <b-card
                class="mb-0"
                style="height:100%"
              >
                <div class="section-content">
                  <div
                    class="image-viewer-conent d-flex flex-column"
                  >
                    <template v-if="view === 'analyzer' || (!dataViewerLoading && !dataViewerLoadingError)">
                      <div>
                        <data-view-toolbar />
                      </div>
                      <div class="separator" />
                    </template>
                    <div class="flex-grow-1">
                      <data-viewer />
                    </div>
                  </div>
                </div>
              </b-card>
            </pane>
          </splitpanes>
        </pane>
      </splitpanes>
    </div>

    <record-form
      v-if="addRecordToDB"
      :is-edit="false"
      :default-record="addRecordData.record"
      :default-table-name="addRecordData.tableName"
      @modal-closed="closeAddRecordForm"
    />

  </div>
</template>

<script>
import axios from 'axios'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import {
  BCard, BSpinner, BAlert,
} from 'bootstrap-vue'

import BatchNodeTree from '@/components/Batch/BatchNodeTree/BatchNodeTree.vue'
import ImageViewer from '@/components/Batch/ImageViewer/ImageViewer.vue'
import ExcelViewer from '@/components/Batch/ExcelViewer/ExcelViewer.vue'
// import NodeTreeToolbar from '@/components/Batch/NodeTreeToolbar/NodeTreeToolbar.vue'
import ImageViewToolbar from '@/components/Batch/ImageViewToolbar/ImageViewToolbar.vue'
import SelectorToolbar from '@/components/Batch/SelectorToolbar/SelectorToolbar.vue'
import DataViewer from '@/components/Batch/DataViewer/DataViewer.vue'
import DataViewToolbar from '@/components/Batch/DataViewToolbar/DataViewToolbar.vue'
import QueryResults from '@/components/Lookup/QueryResults/QueryResults.vue'
import RecordForm from '@/components/Lookup/RecordForm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import bus from '@/bus'
import WS from '@/utils/ws'

export default {
  components: {
    BCard,
    BSpinner,
    BAlert,
    BatchNodeTree,
    ImageViewer,
    ExcelViewer,
    // NodeTreeToolbar,
    ImageViewToolbar,
    SelectorToolbar,
    DataViewer,
    DataViewToolbar,
    Splitpanes,
    Pane,
    QueryResults,
    RecordForm,
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: true,
      connection: null,
      panelSizes: {
        leftPaneSize: 0,
        rightTopPaneSize: 0,
      },
      addRecordToDB: false,
      addRecordData: null,
      loadedFiles: 0,
      totalFiles: 0,
      togoleNavbar: false,
    }
  },
  computed: {
    batch() {
      return this.$store.getters['batch/batch']
    },
    selectedProjectCountries() {
      return this.$store.getters['auth/selectedProjectCountries']
    },
    currentRouteName() {
      return this.$route.name
    },
    view() {
      return this.$store.getters['batch/view']
    },
    mode() {
      return this.$store.getters['batch/mode']
    },
    dataViewMode() {
      return this.$store.getters['dataView/mode']
    },
    status() {
      return this.$store.getters['batch/status']
    },
    dataViewerLoading() {
      return this.$store.getters['dataView/loading']
    },
    error: {
      get() {
        return this.$store.getters['batch/error']
      },
      set(value) {
        this.$store.commit('batch/SET_ERROR', value)
      },
    },
    dataViewerLoadingError() {
      return this.$store.getters['dataView/loadingError']
    },
    allDefinitions() {
      return this.$store.getters['dataView/allDefinitions']
    },
    typesByDefinition() {
      return this.$store.getters['dataView/typesByDefinition']
    },
    batchesByDefinitionType() {
      return this.$store.getters['dataView/batchesByDefinitionType']
    },
    defaultDefinitionVersion() {
      return this.$store.getters['applicationSettings/defaultDefinitionVersion']
    },
    isExcelBatch() {
      return this.$store.getters['batch/batch']?.isExcel
    },
    imageViewerAreaContent() {
      let content = 'image-viewer'

      if (this.dataViewMode === 'key-lookup' || this.view === 'explore-lookup') {
        content = 'query-results'
      } else if (this.isExcelBatch) {
        content = 'excel-viewer'
      }
      return content
    },
    batchProject() {
      return this.$store.getters['batch/batch']?.project
    },
    selectedDefinition() {
      return this.$store.getters['dataView/selectedDefinition']
    },
    pageTitle() {
      if (!this.batch) return ''
      if (this.currentRouteName === 'verification') {
        return `Validate Transaction - ${this.id || ''}`
      }
      let title = ''
      const { batchProject, selectedDefinition } = this
      title = `Batch - ${this.batch?.id || ''}`

      if (batchProject) {
        title += ` | ${batchProject}`
      }

      if (this.currentRouteName === 'template-batch') {
        return `${title} | ${this.batch?.definitionId}`
      }

      if (selectedDefinition) {
        title += ` | ${selectedDefinition.definition_id} - ${selectedDefinition.vendor} - ${selectedDefinition.type}`
      }

      if (selectedDefinition && selectedDefinition.type_seq_no) {
        title += ` ${selectedDefinition.type_seq_no}`
      }

      return title
    },
    selectedDocumentId() {
      return this.$store.getters['batch/selectedDocumentId']
    },
  },
  watch: {
    currentRouteName(newVal, oldVal) {
      if (newVal === 'automated-table-model') {
        this.$store.commit('batch/SET_VIEW', 'analyzer')
        this.$store.commit('dataView/SET_DISPLAY_NOT_IN_USE_FIELDS', true)
      }

      if (newVal === 'batch' && oldVal === 'automated-table-model') {
        this.$store.commit('batch/SET_VIEW', 'table')
      }
    },
    batch(newVal, oldVal) {
      if (!oldVal || oldVal.id === newVal.id || this.view === 'analyzer' || this.currentRouteName === 'verification') {
        return
      }
      this.$router.replace({
        name: this.currentRouteName,
        params: {
          id: newVal.id,
        },
      })
    },
    view(newVal, oldVal) {
      this.$store.dispatch('batch/setSelectedNodeId', null)
      this.$store.dispatch('batch/setEditableNode', null)

      if (this.currentRouteName !== 'verification') {
        this.$store.dispatch('dataView/selectFirstMode')
      }

      this.setPanelSizes()

      if (oldVal === 'explore-lookup') {
        this.$store.dispatch('lookup/resetResultData')
      }
    },
    dataViewMode(newVal, oldVal) {
      if (oldVal === 'key-lookup') {
        this.$store.dispatch('lookup/resetResultData')
      }
    },
    status: {
      handler(newVal, oldVal) {
        if (['completed', 'failed'].includes(this.status.status)) {
          this.$store.commit('dataView/SET_LOADING', false)

          if (oldVal) {
            this.$store.dispatch('batch/refreshBatchData')
          }
        }
      },
      deep: true,
    },
    pageTitle() {
      this.setPageTitle()
    },
    selectedDocumentId: {
      deep: true,
      handler() {
        this.loadedFiles = 0
        this.totalFiles = 0
      },
    },
  },
  created() {
    if (this.currentRouteName === 'automated-table-model') {
      this.$store.commit('batch/SET_VIEW', 'analyzer')
    }

    if (this.currentRouteName === 'verification') {
      this.initBatchForVerification()

      return
    }

    this.initBatch()
    bus.$on('wsData/batchStatus', this.onBatchStatus)
    bus.$on('lookup/displayAddRecordForm', this.displayAddRecordForm)
    bus.$on('toggle-navbar', this.gettogoleNavbar)
    this.$store.dispatch('dataView/selectFirstMode')
    this.setPanelSizes()
    this.setPageTitle()
  },
  destroyed() {
    WS.leaveRoom(`batch_status_${this.id}`)
    bus.$off('wsData/batchStatus', this.onBatchStatus)
    bus.$off('lookup/displayAddRecordForm', this.displayAddRecordForm)
    bus.$off('toggle-navbar', this.gettogoleNavbar)
    this.$store.dispatch('batch/reset')
    this.$store.dispatch('dataView/reset')
    this.$store.dispatch('lookup/reset')
  },
  methods: {
    async initBatchForVerification() {
      await this.$store.dispatch('dataView/setMainMode', 'verification')
      await this.$store.dispatch('batch/fetchVerificationDetails', this.id)

      this.setPanelSizes()
      this.setPageTitle()

      this.error = null
      this.loading = false
    },
    async getBatchId() {
      if (this.$route.params.id) {
        return this.$route.params.id
      }

      const previousBatchId = localStorage.getItem('previous_batch_id')

      if (previousBatchId) {
        const isBatchAvailable = await this.$store.dispatch('batch/checkBatchAvailability', previousBatchId)

        if (isBatchAvailable) {
          return previousBatchId
        }
      }

      const res = await axios.post('/latest_batch_info/', {
        project_countries: this.selectedProjectCountries.map(e => `${e.countryCode}_${e.project}`).join(','),
      })

      if (!res.data.batch_id || !this.allDefinitions.includes(res.data.definition_id)) {
        return null
      }

      return res.data.batch_id
    },
    async initBatch() {
      this.loading = true

      try {
        this.$store.commit('dataView/SET_BATCHES_BY_DEFINITION_TYPE', [])
        this.$store.commit('dataView/SET_TYPES_BY_DEFINITION', [])

        // Fetch Batch
        await this.$store.dispatch('dataView/fetchAllDefinitions')

        if (!this.allDefinitions.length) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No batch available',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          this.$router.push({ name: 'home' })

          return
        }

        const batchId = await this.getBatchId()

        if (!batchId) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No batch available',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          this.$router.push({ name: 'home' })

          return
        }

        await this.$store.dispatch('batch/fetchBatch', batchId)

        // Fetch Application & Definition Settings
        await this.$store.dispatch('applicationSettings/fetchApplicationSettings')
        await this.$store.dispatch('definitionSettings/fetchDefinitionSettings')

        // Fetch Definition
        await this.$store.dispatch('dataView/fetchDefinition', this.currentRouteName)

        // Fetch Position Shift Data
        // await this.$store.dispatch('batch/fetchPositionShiftData')

        // Load Documents
        await this.$store.dispatch('batch/loadDocumentData')

        this.error = null
        this.loading = false

        WS.joinRoom(`batch_status_${this.batch.id}`)
      } catch (error) {
        this.error = error
        this.loading = false
      }
    },
    onBatchStatus(data) {
      const batch = this.$store.getters['batch/batch']
      if (batch.id === data.batch_id) {
        this.$store.commit('batch/SET_STATUS', {
          status: data.status,
          remarks: data.remarks,
          event_time: data.event_time,
        })
      }
    },
    setPanelSizes() {
      this.panelSizes = {
        leftPaneSize: 1,
        rightTopPaneSize: 1,
      }
      this.$nextTick(() => {
        if (this.view === 'key' || this.view === 'explore-lookup') {
          this.panelSizes = {
            leftPaneSize: 30,
            rightTopPaneSize: this.currentRouteName === 'verification' ? 100 : 50,
          }
        } else {
          this.panelSizes = {
            leftPaneSize: 0,
            rightTopPaneSize: 50,
          }
        }

        this.$forceNextTick(() => {
          bus.$emit('fitToWidth')
        })
      })
    },
    displayAddRecordForm(defaultData) {
      this.addRecordToDB = true
      this.addRecordData = defaultData
    },
    closeAddRecordForm() {
      this.addRecordToDB = false
      this.addRecordData = null
    },
    setPageTitle() {
      this.$store.commit('app/SET_CURRENT_PAGE_TITLE', this.pageTitle)
    },
    onCalculateLoadedFiles(totalFiles) {
      this.loadedFiles += 1
      this.totalFiles = totalFiles
    },
    gettogoleNavbar() {
      this.togoleNavbar = !this.togoleNavbar
    },
  },
}
</script>

<style scoped>
.box {
    height: calc(100vh - 155px);
}
.box-extend {
    height: calc(100vh - 86.5px);
}
.verification-box {
    height: calc(100vh - 10.85rem);
}
.node-tree-conent {
    height:100%;
    /* row-gap: 0.5rem; */
}
.image-viewer-conent {
    height:100%;
    row-gap: 0.5rem;
}
.section-content {
    position:absolute;
    top:0px;
    left:0px;
    width:calc(100% - 1rem);
    height:calc(100% - 1rem);
    overflow:auto;
    margin:0.5rem;
}
</style>
