<template>
  <div>
    <b-table
      :items="rows"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      striped
      responsive
      @sort-changed="generateRows"
    >
      <template #cell(show_patterns)="row">
        <b-form-checkbox
          v-model="row.item.checked"
          @change="() => onClick(row, false)"
        />
      </template>
      <template #cell(pattern)="row">
        <div
          :ref="`${row.item.pos[7]}`"
          class="d-flex"
          :class="{
            'cursor-pointer': !row.item.nitb,
            'highlight-cell': highlightAtmPatternRecord === `${row.item.pos[7]}`
          }"
          @click="onClick(row, true)"
        >
          <div
            class="dot-container mr-1 align-self-center"
            :style="{
              'background-color': row.item.pos[8] === 'blank' ? 'transparent' : row.item.pos[8]
            }"
          />

          <div>
            <p
              v-for="(pattern, index) in row.item.pattern.split('\n')"
              :key="index"
              class="m-0"
            >
              {{ pattern }}
            </p>
          </div>
        </div>
      </template>
      <template #cell(openBlock)="row">
        <b-form-checkbox
          v-model="row.item.openBlock"
          @change="() => onCheckOpenBlock(row.item)"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import bus from '@/bus'
import {
  BTable, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BFormCheckbox,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sortBy: 'confidenceScore',
      sortDesc: true,
      renderedItems: [],
      highlightAtmPatternRecord: null,
      highlightAtmPatternRecordTimer: null,
    }
  },
  computed: {
    selectedAtmPatterns() {
      return this.$store.getters['atm/selectedAtmPatterns']
    },
    atmAccordionOptions() {
      return this.$store.getters['atm/atmAccordionOptions']
    },
    multipleLineRecord() {
      return this.$store.getters['dataView/modelMultipleLineRecord']
    },
    fields() {
      const fields = [
        { key: 'show_patterns' },
        { key: 'pattern', label: 'Patterns' },
        { key: 'confidenceScore', label: 'confidence Score', sortable: true },
      ]

      if (!this.multipleLineRecord) {
        fields.push({ key: 'openBlock', label: 'Open Record' })
      }

      return fields
    },
  },
  created() {
    bus.$on('atm/scrollToAtmPatternRecord', this.scrollToAtmPatternRecord)
    bus.$on('atm/onShiftClick', this.onShiftClick)
  },
  destroyed() {
    bus.$off('atm/scrollToAtmPatternRecord', this.scrollToAtmPatternRecord)
    bus.$off('atm/onShiftClick', this.onShiftClick)
  },
  methods: {
    onCheckOpenBlock(item) {
      const selectedAtmPatterns = [...this.selectedAtmPatterns]

      const index = selectedAtmPatterns.map(e => e.pattern).indexOf(item.pattern)

      if (index !== -1) {
        selectedAtmPatterns[index] = {
          ...selectedAtmPatterns[index],
          multiLine: item.openBlock.toString(),
        }
      }

      this.$store.commit('atm/SET_SELECTED_ATM_PATTERNS', selectedAtmPatterns)
    },
    onShiftClick(posRef) {
      const index = this.rows.findIndex(e => e.pos[7] === posRef)

      if (index === -1) {
        return
      }

      this.rows[index].checked = !this.rows[index].checked

      this.$emit('checked', { data: this.rows[index], scrollToPos: false })
    },
    onClick(row, scrollToPos) {
      if (!row.item.nitb) {
        this.$emit('checked', { data: row.item, scrollToPos })
      }
    },
    generateRows() {
      this.$store.dispatch('atm/generateAtmPatternTableRows')
    },
    scrollToAtmPatternRecord(data) {
      const atmAccordionOptions = [...this.atmAccordionOptions]

      atmAccordionOptions.forEach((item, index) => {
        if (!item.visible && item.key === data.refStatus) {
          atmAccordionOptions[index].visible = true
        }
      })

      this.$store.commit('atm/SET_ATM_ACCORDION_OPTIONS', atmAccordionOptions)

      const refElement = this.$refs[data.posRef]

      if (!refElement) {
        return
      }

      clearTimeout(this.highlightAtmPatternRecordTimer)

      this.highlightAtmPatternRecord = data.posRef

      this.$nextTick(() => {
        setTimeout(() => {
          refElement.parentNode.scrollIntoView()
        }, 200)

        this.highlightAtmPatternRecordTimer = setTimeout(() => {
          this.highlightAtmPatternRecord = null
        }, 1500)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.highlight-cell {
  border: 2px solid red;
}
.dot-container {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: green;
}
</style>
