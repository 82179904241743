<template>
  <div
    v-if="scrollTop > 10"
    class="scroll-to-top"
  >
    <feather-icon
      icon="ChevronsUpIcon"
      size="30"
      class="cursor-pointer mx-1"
      :style="{
        'opacity': scrollToTopIconHover ? 1 : 0.5
      }"
      @mouseover="scrollToTopIconHover = true"
      @mouseleave="scrollToTopIconHover = false"
      @click="onScrollToTop"
    />
  </div>
</template>

<script>
import bus from '@/bus'

export default {
  data() {
    return {
      scrollTop: 0,
      scrollToTopIconHover: false,
    }
  },
  created() {
    bus.$on('dataView/onScroll', this.onScroll)
  },
  destroyed() {
    bus.$off('dataView/onScroll', this.onScroll)
  },
  methods: {
    onScroll(e) {
      this.scrollTop = e.currentTarget.scrollTop
    },
    onScrollToTop() {
      const querySelector = document.querySelector('.definitions-content-wrapper')

      querySelector.scroll({
        top: 0,
        behavior: 'instant',
      })

      this.scrollToTopIconHover = false
    },
  },
}
</script>

<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: 3rem;
  right: 2rem;
}
</style>
