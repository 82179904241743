<template>
  <b-button-group>
    <template
      v-if="displayActionableModes"
    >
      <b-button
        v-for="(modeOption, modeOptionIndex) of regularModeOptions"
        :key="modeOptionIndex"
        :variant="mode === modeOption.mode ? 'primary' : 'outline-primary'"
        @click="setMode(modeOption.mode)"
      >
        {{ modeOption.label }}
      </b-button>

      <b-dropdown
        v-if="advanceModeOptions.length > 0"
        right
        variant="outline-primary"
        no-caret
        size="sm"
      >
        <template #button-content>
          <feather-icon
            icon="ChevronDownIcon"
            size="20"
          />
        </template>

        <b-dropdown-item-button
          v-for="(modeOption, modeOptionIndex) of advanceModeOptions"
          :key="modeOptionIndex"
          button-class="w-100"
          :active="mode === modeOption.mode"
          @click="setMode(modeOption.mode)"
        >
          {{ modeOption.label }}
        </b-dropdown-item-button>
      </b-dropdown>
    </template>

    <template v-if="!displayActionableModes">
      <b-button
        v-if="mode === 'key-rules'"
        :variant="'primary'"
      >
        {{ keyRuleItem ? `Rules - ${keyRuleItem.id}` : 'Rules' }}
      </b-button>

      <b-button
        v-if="mode === 'key-lookup'"
        :variant="'primary'"
      >
        {{ keyLookupItem ? `Lookup - ${keyLookupItem.nestedLabel}` : 'Lookup' }}
      </b-button>

      <b-button
        v-if="mode === 'table-rules'"
        :variant="'primary'"
      >
        {{ tableRuleItem ? `Rules - ${tableRuleItem.label}` : 'Rules' }}
      </b-button>
    </template>
  </b-button-group>
</template>

<script>
import {
  VBTooltip, BDropdown, BDropdownItemButton, BButtonGroup, BButton,
} from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BButton,
  },
  computed: {
    mainMode() {
      return this.$store.getters['dataView/mainMode']
    },
    mode() {
      return this.$store.getters['dataView/mode']
    },
    enableLookups() {
      return this.$store.getters['applicationSettings/enableLookups']
    },
    keyModeOptions() {
      let options = [
        {
          label: 'Keys',
          mode: 'key-items',
          advanced: false,
        },
        {
          label: 'Rules',
          mode: 'key-rule-items',
          advanced: false,
        },
        {
          label: 'Not in Use',
          mode: 'key-not-in-use-items',
          advanced: false,
        },
      ]

      if (this.enableLookups) {
        options.push({
          label: 'Lookups',
          mode: 'key-lookup-items',
          advanced: false,
        })
      }

      options = options.concat([
        {
          label: 'Models',
          mode: 'key-models',
          advanced: true,
        },
      ])

      return options
    },
    tableModeOptions() {
      const options = [
        {
          label: 'Models',
          mode: 'table-models',
          advanced: false,
        },
        {
          label: 'Columns',
          mode: 'table-columns',
          advanced: false,
        },
        {
          label: 'Keys',
          mode: 'table-keys',
          advanced: false,
        },
        {
          label: 'Rules',
          mode: 'table-rule-items',
          advanced: false,
        },
        {
          label: 'Normalizer',
          mode: 'table-normalizer',
          advanced: false,
        },
        {
          label: 'Test',
          mode: 'test',
          advanced: false,
        },
      ]
      return options
    },
    modeOptions() {
      if (this.mainMode === 'keySettings') {
        return this.keyModeOptions
      } if (this.mainMode === 'tableSettings') {
        return this.tableModeOptions
      }
      return []
    },
    regularModeOptions() {
      return this.modeOptions.filter(modeOption => !modeOption.advanced).map((option, index) => {
        const newOption = { ...option }
        newOption.label = `${index + 1}. ${option.label}`
        return newOption
      })
    },
    advanceModeOptions() {
      return this.modeOptions.filter(modeOption => modeOption.advanced).map((option, index) => {
        const newOption = { ...option }
        newOption.label = `${this.regularModeOptions.length + index + 1}. ${option.label}`
        return newOption
      })
    },
    displayActionableModes() {
      return this.modeOptions.map(modeOption => modeOption.mode).includes(this.mode)
    },
    keyRuleItem() {
      return this.$store.getters['dataView/keyRuleItem']
    },
    keyLookupItem() {
      return this.$store.getters['dataView/keyLookupItem']
    },
    tableRuleItem() {
      return this.$store.getters['dataView/tableRuleItem']
    },
  },
  methods: {
    setMode(mode) {
      return this.$store.dispatch('dataView/setMode', mode)
    },
  },
}
</script>

<style scoped>
.btn {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  font-size: 12px;
}
</style>
