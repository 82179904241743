<template>
  <div
    v-if="tableOptions.length"
    class="h-100"
  >
    <v-select
      v-model="selectedTable"
      :clearable="false"
      :options="tableOptions"
      :reduce="option => option.value"
      class="table-selector h-100"
      :class="{'verification-table-selector': mainMode === 'verification'}"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { VBTooltip } from 'bootstrap-vue'
import bus from '@/bus'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    vSelect,
  },
  computed: {
    mainMode() {
      return this.$store.getters['dataView/mainMode']
    },
    table() {
      return this.$store.getters['dataView/table']
    },
    selectedTableId() {
      return this.$store.getters['dataView/selectedTableId']
    },
    tableOptions() {
      return this.table.map(item => ({
        label: item.table_name,
        value: item.table_id,
      }))
    },
    selectedTable: {
      get() {
        return {
          label: this.table[this.selectedTableId].table_name,
          value: this.table[this.selectedTableId].table_id,
        }
      },
      async set(value) {
        if (this.mainMode === 'verification') {
          this.$store.commit('dataView/SET_SELECTED_TABLE_ID', value)
          this.$store.dispatch('dataView/setDJsonTableList')
          this.$store.dispatch('batch/setDocument')

          return
        }

        bus.$emit('dataView/onTableChange', {
          curentTableId: value,
          prevTableId: this.selectedTableId,
        })
      },
    },
  },
}
</script>

<style lang="scss">
.table-selector {
  .vs__dropdown-toggle {
    height: 100%;
    border-top-left-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
  }
}

.verification-table-selector {
  .vs__dropdown-toggle {
    border-top-left-radius: 0.357rem !important;
    border-bottom-left-radius: 0.357rem !important;
  }
}
</style>
