<template>
  <div>
    <div
      :id="`key-item-${keyItem.id}`"
      class="key-item d-flex my-50"
    >
      <validation-provider
        #default="{ errors }"
        name="Key Field Name"
        rules="required"
        :vid="`keyLabel_${keyItem.id}`"
        class="regular-column flex-grow-1"
      >
        <b-form-group
          class="mb-0"
          :state="errors.length > 0 ? false:null"
        >
          <v-select
            v-model="keyItem['keyLabel']"
            :label="sortedOptions['options-keys'].lableKey"
            :options="keySelectOptions"
            :reduce="option => option[sortedOptions['options-keys'].valueKey]"
            @input="onKeyFieldNameChange"
            @open="onDropdownOpen"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <div
        v-if="keyItem.isCompoundKey"
        class="dummy-column flex-grow-1"
      >
        <div class="my-50">
          Compound Items
          <feather-icon
            :icon="displayCompoundItems ? 'ChevronDownIcon' : 'ChevronUpIcon'"
            class="cursor-pointer"
            style="margin-right: 20px;"
            size="20"
            @click="displayCompoundItems = !displayCompoundItems"
          />
          <b-button
            variant="outline-primary"
            @click="add"
          >
            Add +1
          </b-button>
        </div>
      </div>

      <div
        v-if="!keyItem.isCompoundKey"
        class="regular-column flex-grow-1"
      >
        <qualifier-select
          v-model="keyItem['qualifierValue']"
          :key-value="keyItem['keyLabel']"
          :key-options="keySelectOptions"
          label="Qualifier"
          validation-rules="required"
          :validation-key="`qualifierValue_${keyItem.id}`"
          @dropdownOpen="onDropdownOpen"
          @input="onQualifierChange"
        />
      </div>

      <validation-provider
        v-if="!keyItem.isCompoundKey"
        #default="{ errors }"
        name="Type"
        rules="required"
        :vid="`type_${keyItem.id}`"
        class="regular-column flex-grow-1"
      >
        <b-form-group
          class="mb-0"
          :state="errors.length > 0 ? false:null"
        >
          <v-select
            v-model="keyItem['type']"
            :label="applicationOptions['options-key-items-type'].lableKey"
            :options="inputTypeOptions('options-key-items-type')"
            :reduce="option => option[applicationOptions['options-key-items-type'].valueKey]"
            :clearable="false"
            @input="onTypeChange"
            @open="onDropdownOpen"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <div
        v-if="!keyItem.isCompoundKey"
        class="regular-column flex-grow-1"
      >
        <pipe-separated-input
          v-if="keyItem.type === 'keys'"
          v-model="keyItem['shape']"
          label="Label"
          selection-value-attr="text"
          listenable-input
          :hide-form-group-label="true"
          validation-rules="required|selectTextFromImage"
          :validation-key="`shape_${keyItem.id}`"
          :initialize-expanded="autoExpandInputSectionFields"
          @selection-input="shapeSelectionInputHandler($event)"
          @item-deleted="shapeSelectionItemDeleteHandler($event)"
        />

        <selector-input
          v-if="keyItem.type === 'selector'"
          v-model="selectorItems"
          :pos-fields="posFields"
          label="Label"
          :validation-key="`shape_${keyItem.id}`"
          :initialize-expanded="autoExpandInputSectionFields"
        />

        <cell-range-selector
          v-if="keyItem.type === 'cellRange'"
          v-model="cellRangeItems"
          label="Label"
          :validation-key="`shape_${keyItem.id}`"
          validation-rules="required"
          :initialize-expanded="autoExpandInputSectionFields"
          multiple
        />

        <validation-provider
          v-if="keyItem.type === 'anchors'"
          #default="{ failedRules }"
          name="Label"
          rules="requireAtleastOneAnchorShape:root"
          :vid="`shape_${keyItem.id}`"
        >
          <anchor-shapes
            v-model="anchorShapes"
            :validation-key="`shape_${keyItem.id}`"
            :failed-rules="failedRules"
            :initialize-expanded="autoExpandInputSectionFields"
            @focus="$emit('highlightAnchors', $event)"
          />
        </validation-provider>

        <validation-provider
          v-if="keyItem.type === 'barcode'"
          #default="{ errors }"
          name="Barcode Index"
          rules="required"
          :vid="`shape_${keyItem.id}`"
        >
          <b-form-input
            v-model="barcodeIndex"
            type="number"
            placeholder="Barcode Index"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <validation-provider
          v-if="keyItem.type === 'static'"
          #default="{ errors }"
          name="Label"
          rules="required"
          :vid="`shape_${keyItem.id}`"
        >
          <form-input
            v-model="keyItem['shape']"
            type="text"
            placeholder="Label"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <single-column-extractor-inputs
          v-if="keyItem.type === 'singleColumn'"
          v-model="singleColumnExtractor"
          :validation-key="`shape_${keyItem.id}`"
          :initialize-expanded="autoExpandInputSectionFields"
        />

        <single-column-extractor-excel-inputs
          v-if="keyItem.type === 'singleColumnExcel'"
          v-model="singleColumnExtractorExcel"
          :validation-key="`shape_${keyItem.id}`"
          validation-rules="required"
        />

        <regex-extractor
          v-if="keyItem.type === 'regexExtractor'"
          v-model="regexExtractor"
          :validation-key="`shape_${keyItem.id}`"
          :initialize-expanded="autoExpandInputSectionFields"
          @focus="$emit('highlightAnchors', $event)"
        />
      </div>

      <div class="action-column">
        <div class="action-column-content d-flex">
          <b-dropdown
            :disabled="!(displayAdditionalOptions || displayDateFormatOption)"
            right
            variant="link"
            no-caret
            toggle-class="p-0"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="20"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-form
              class="advance-settings-dropdown-form"
            >
              <p class="font-weight-bold">
                Advanced Settings
              </p>
              <template v-if="displayAdditionalOptions">
                <b-form-group>
                  <b-form-checkbox
                    v-model="keyItem.extractMultiple"
                  >
                    {{ isSysFieldRegexExtractor ? 'Multiple Match' : 'Extract Multiple' }}
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  v-if="!isSysFieldRegexExtractor"
                >
                  <b-form-checkbox
                    v-model="keyItem.removeDuplicates"
                  >
                    Remove Duplicates
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  v-if="!isSysFieldRegexExtractor"
                >
                  <b-form-checkbox
                    v-model="advanceSettings.groupMultiple"
                  >
                    Group Multiple
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  v-if="advanceSettings.groupMultiple"
                  label="Group Multiple Separator"
                >
                  <b-form-input
                    v-model="advanceSettings.groupMultipleSeparator"
                  />
                </b-form-group>
              </template>
              <template v-if="displayDateFormatOption">
                <b-form-group>
                  <b-form-checkbox
                    v-model="advanceSettings.dateFormat"
                    value="European"
                    unchecked-value=""
                  >
                    European Date
                  </b-form-checkbox>
                </b-form-group>
              </template>
            </b-dropdown-form>
          </b-dropdown>

          <feather-icon
            v-if="!keyItem.isCompoundItem"
            icon="AlignJustifyIcon"
            class="cursor-move handle"
            size="20"
          />
          <feather-icon
            v-b-tooltip.hover
            title="Delete Key"
            icon="Trash2Icon"
            class="cursor-pointer mx-auto"
            size="20"
            @click.stop="$emit('deleteItem')"
          />
        </div>
      </div>
    </div>
    <div
      v-if="keyItem.isCompoundKey"
      v-show="displayCompoundItems"
    >
      <compound-keys
        v-model="keyItem.compoundItems"
        :auto-expand-input-section-fields="autoExpandCompoundItemInputSectionFields"
        :compound-key-setting-name="compoundKeySettingName"
        @dropdownOpen="$emit('dropdownOpenCompoundItem', $event)"
        @highlightAnchors="$emit('highlightAnchorsCompoundItem', $event)"
      />
    </div>
    <hr
      v-if="!keyItem.isCompoundItem"
      class="key-item-separator my-50"
    >
  </div>
</template>

<script>

import {
  VBTooltip, BFormGroup, BDropdown, BDropdownForm, BFormCheckbox, BFormInput, BButton,
} from 'bootstrap-vue'
import { isEqual, cloneDeep } from 'lodash'
import vSelect from 'vue-select'
import PipeSeparatedInput from '@/components/UI/PipeSeparatedInput.vue'
import FormInput from '@/components/UI/FormInput.vue'
import SelectorInput from '@/components/UI/SelectorInput/SelectorInput.vue'
import QualifierSelect from '@/components/UI/QualifierSelect.vue'
import AnchorShapes from '@/components/UI/AnchorShapes/AnchorShapes.vue'
import SingleColumnExtractorInputs from '@/components/UI/SingleColumnExtractorInputs/SingleColumnExtractorInputs.vue'
import SingleColumnExtractorExcelInputs from '@/components/UI/SingleColumnExtractorExcelInputs/SingleColumnExtractorExcelInputs.vue'
import RegexExtractor from '@/components/UI/RegexExtractor/RegexExtractor.vue'
import CellRangeSelector from '@/components/UI/CellRangeSelector/CellRangeSelector.vue'
import CompoundKeys from '@/components/Batch/DataViewer/KeyItems/CompoundKeys.vue'

import { ValidationProvider } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

import { getDefaultCompoundKeys, getDefaultKey } from './key-helper'

const defaultAnchorShapes = {
  top: {
    text: null,
    pos: null,
    pageIndex: null,
    documentIndex: null,
    threshold: null,
  },
  bottom: {
    text: null,
    pos: null,
    pageIndex: null,
    documentIndex: null,
    threshold: null,
  },
  left: {
    text: null,
    pos: null,
    pageIndex: null,
    documentIndex: null,
    threshold: null,
  },
  right: {
    text: null,
    pos: null,
    pageIndex: null,
    documentIndex: null,
    threshold: null,
  },
}

const defaultSingleColumnExtractor = {
  tableStart: null,
  tableEnd: null,
  startPos: null,
  endPos: null,
  shape: null,
}

const defaultSingleColumnExtractorExcel = {
  columnStartCell: null,
  columnEndCell: null,
}

const defaultRegexExtractor = {
  anchors: {
    top: {
      text: null,
      pos: null,
      pageIndex: null,
      documentIndex: null,
      threshold: null,
    },
    bottom: {
      text: null,
      pos: null,
      pageIndex: null,
      documentIndex: null,
      threshold: null,
    },
  },
  patterns: [null],
}

const defaultAdvanceSettings = {
  dateFormat: '',
  groupMultiple: false,
  groupMultipleSeparator: ',',
}

export default {
  name: 'KeyItem',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    vSelect,
    PipeSeparatedInput,
    FormInput,
    QualifierSelect,
    SelectorInput,
    AnchorShapes,
    ValidationProvider,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BFormCheckbox,
    BFormInput,
    SingleColumnExtractorInputs,
    SingleColumnExtractorExcelInputs,
    RegexExtractor,
    CellRangeSelector,
    CompoundKeys,
    BButton,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    autoExpandInputSectionFields: {
      type: Boolean,
      required: true,
    },
    parent: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    keyLableOptions: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      keyItem: {},
      selectorItems: [],
      anchorShapes: cloneDeep(defaultAnchorShapes),
      barcodeIndex: null,
      singleColumnExtractor: cloneDeep(defaultSingleColumnExtractor),
      singleColumnExtractorExcel: cloneDeep(defaultSingleColumnExtractorExcel),
      regexExtractor: cloneDeep(defaultRegexExtractor),
      advanceSettings: {},
      cellRangeItems: [],
      posFields: ['startPos', 'topPos', 'endPos', 'bottomPos', 'pageId', 'pageIndex'],
      autoExpandCompoundItemInputSectionFields: false,
      initialized: false,
      displayCompoundItems: false,
    }
  },
  computed: {
    applicationOptions() {
      return this.$store.getters['applicationSettings/options']
    },
    sortedOptions() {
      return this.$store.getters['definitionSettings/sortedOptions']
    },
    batchView() {
      return this.$store.getters['batch/view']
    },
    batchProject() {
      return this.$store.getters['batch/batch']?.project
    },
    keySelectOptions() {
      if (this.keyItem.isCompoundItem) {
        return this.keyLableOptions
      }

      if (this.batchView === 'table') {
        return this.$store.getters['definitionSettings/keyOptionsApplicableForTable']
      }
      return this.$store.getters['definitionSettings/keyOptionsApplicableForKeys']
    },
    out() {
      const keyItem = cloneDeep(this.keyItem)
      if (keyItem.type === 'anchors') {
        keyItem.anchorShapes = this.anchorShapes
      } else {
        keyItem.anchorShapes = null
      }

      if (keyItem.type === 'singleColumn') {
        keyItem.singleColumnExtractor = this.singleColumnExtractor
      } else {
        keyItem.singleColumnExtractor = null
      }

      if (keyItem.type === 'selector') {
        this.posFields.forEach(posField => {
          keyItem[posField] = this.selectorItems.map(posItem => posItem[posField]).join('|')
        })
        keyItem.shape = this.selectorItems.map(() => 'Selector').join('|')
        keyItem.selector = true
      } else {
        keyItem.selector = false
      }

      if (keyItem.type === 'regexExtractor') {
        keyItem.regexExtractor = this.regexExtractor
      } else {
        keyItem.regexExtractor = null
      }

      const advanceSettings = {}
      if (this.displayDateFormatOption) {
        advanceSettings.dateFormat = this.advanceSettings.dateFormat
      }
      if (this.displayAdditionalOptions) {
        advanceSettings.groupMultiple = this.advanceSettings.groupMultiple
        advanceSettings.groupMultipleSeparator = this.advanceSettings.groupMultipleSeparator
      }
      keyItem.advanceSettings = advanceSettings

      const typeData = {}

      if (keyItem.type === 'barcode') {
        typeData.barcodeIndex = parseInt(this.barcodeIndex, 10)
      }

      if (keyItem.type === 'cellRange') {
        typeData.cellRangeItems = this.cellRangeItems
      }

      if (keyItem.type === 'singleColumnExcel') {
        typeData.singleColumnExtractorExcel = this.singleColumnExtractorExcel

        keyItem.singleColumnExtractor = true
      }

      keyItem.typeData = typeData

      return keyItem
    },
    displayAdditionalOptions() {
      const applicableForKeyLabel = ['references', 'filler', 'notes'].includes(this.keyItem.keyLabel)

      return this.isSysFieldRegexExtractor || applicableForKeyLabel || this.batchProject === 'CustomsEntryUpdate'
    },
    isSysFieldRegexExtractor() {
      return this.keyItem.keyLabel === 'sysField' && this.keyItem.type === 'regexExtractor'
    },
    displayDateFormatOption() {
      const { keyLabel } = this.keyItem

      if (!keyLabel) {
        return false
      }

      if (keyLabel.toLowerCase().endsWith('date')) {
        return true
      }

      return false
    },
    isExcelBatch() {
      return this.$store.getters['batch/batch'].isExcel
    },
    compoundKeySettingName() {
      const { keyLabel } = this.keyItem
      if (!keyLabel) {
        return null
      }

      const keyOptions = this.sortedOptions['options-keys'].items
      const keyOption = keyOptions.find(item => item.keyValue === keyLabel)
      if (!keyOption) {
        return null
      }

      return keyOption.compoundKeys
    },
    isCompoundKey() {
      if (this.compoundKeySettingName) {
        return true
      }
      return false
    },
    optionsKeyItems() {
      return this.$store.getters['definitionSettings/options']['options-keys'].items
    },
    defaultBehaviour() {
      return this.$store.getters['defaultSettings/defaultBehaviour']
    },
  },
  watch: {
    displayAdditionalOptions() {
      if (!this.displayAdditionalOptions) {
        this.keyItem.extractMultiple = false
        this.keyItem.removeDuplicates = false
        this.advanceSettings.groupMultiple = defaultAdvanceSettings.groupMultiple
        this.advanceSettings.groupMultipleSeparator = defaultAdvanceSettings.groupMultipleSeparator
      }
    },
    displayDateFormatOption() {
      if (!this.displayDateFormatOption) {
        this.advanceSettings.dateFormat = defaultAdvanceSettings.dateFormat
      }
    },
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
    isCompoundKey() {
      if (this.initialized) {
        this.setCompoundKeyData()
      }
    },
  },
  created() {
    this.setInternalState()

    this.$nextTick(() => {
      this.initialized = true
    })
  },
  methods: {
    setInternalState() {
      const keyItem = cloneDeep(this.value)
      delete keyItem.advanceSettings
      delete keyItem.typeData
      const advanceSettings = cloneDeep(this.value.advanceSettings)

      this.keyItem = keyItem

      if (this.keyItem.type === 'anchors') {
        this.anchorShapes = this.value.anchorShapes
      }

      if (this.keyItem.type === 'singleColumn') {
        this.singleColumnExtractor = this.value.singleColumnExtractor
      }

      const items = []
      if (this.keyItem.type === 'selector') {
        const noOfItems = this.keyItem[this.posFields[0]].split('|').length
        for (let index = 0; index < noOfItems; index += 1) {
          const item = {}
          this.posFields.forEach(posField => {
            const fieldValue = this.keyItem[posField]
            item[posField] = fieldValue !== '' ? fieldValue.split('|')[index] : ''
          })
          items[index] = item
        }
      }
      this.selectorItems = items

      if (this.keyItem.type === 'regexExtractor') {
        this.regexExtractor = this.value.regexExtractor
      }

      if (this.keyItem.type === 'barcode') {
        if (this.value.typeData.barcodeIndex) {
          this.barcodeIndex = parseInt(this.value.typeData.barcodeIndex, 10)
        } else {
          this.barcodeIndex = null
        }
      }

      if (this.keyItem.type === 'cellRange') {
        this.cellRangeItems = this.value.typeData.cellRangeItems || []
      }

      if (this.keyItem.type === 'singleColumnExcel') {
        this.singleColumnExtractorExcel = this.value.typeData.singleColumnExtractorExcel
      }

      this.advanceSettings = {
        ...cloneDeep(defaultAdvanceSettings),
        ...advanceSettings,
      }
    },
    shapeSelectionInputHandler(data) {
      this.posFields.forEach(posField => {
        let newValue
        if (data.index === -1) {
          newValue = data.selectionData[posField] || ''
        } else {
          const currentValue = this.keyItem[posField]
          const newValueArray = currentValue.split('|')
          newValueArray[data.index] = data.selectionData[posField] || ''
          newValue = newValueArray.join('|')
        }
        this.keyItem[posField] = newValue
      })
    },
    shapeSelectionItemDeleteHandler(itemIndex) {
      this.posFields.forEach(posField => {
        const currentValue = this.keyItem[posField]
        const newValueArray = currentValue.split('|')
        newValueArray.splice(itemIndex, 1)
        const newValue = newValueArray.join('|')
        this.keyItem[posField] = newValue
      })
    },
    onKeyFieldNameChange(keyValue) {
      if (this.defaultBehaviour.compoundKeys.includes(this.parent)) {
        this.keyItem.type = 'static'
        this.keyItem.shape = 'ERROR'
      }
      /* else {
        this.keyItem.type = 'keys'
        this.keyItem.shape = ''
      } */

      if (this.keyItem.isCompoundItem) {
        return
      }

      const optionsKeyItem = this.optionsKeyItems.find(e => e.keyValue === keyValue)

      this.keyItem.export = optionsKeyItem.export
    },
    onTypeChange() {
      this.anchorShapes = cloneDeep(defaultAnchorShapes)
      this.barcodeIndex = null
      this.singleColumnExtractor = cloneDeep(defaultSingleColumnExtractor)
      this.singleColumnExtractorExcel = cloneDeep(defaultSingleColumnExtractorExcel)
      this.selectorItems = []
      this.keyItem.shape = ''
      this.shapeSelectionInputHandler({
        index: -1,
        selectionData: {
          startPos: '',
          endPos: '',
          topPos: '',
          bottomPos: '',
          pageId: '',
        },
      })
      this.regexExtractor = cloneDeep(defaultRegexExtractor)
      this.cellRangeItems = []

      if (this.keyItem.id === this.$store.getters['batch/highlightKeyAnchorsData'].keyItemId) {
        this.$store.dispatch('batch/clearAnchorHighlights')
      }
    },
    onDropdownOpen() {
      this.$nextTick(() => {
        this.$emit('dropdownOpen')
      })
    },
    inputTypeOptions(optionsId) {
      const options = this.applicationOptions[optionsId].items
      const batchFileType = this.isExcelBatch ? 'excel' : 'pdf'
      let applicableOptions = options.filter(option => option.applicableFor.includes(batchFileType))
      // const optionsKeyItem = this.optionsKeyItems.find(e => e.keyValue === this.keyItem.keyLabel)
      const keyOptionItem = this.keySelectOptions.find(item => item.keyValue === this.keyItem.keyLabel)
      if (!(this.keyItem.keyLabel.toLowerCase().includes('date') || keyOptionItem?.qualifier.toLowerCase() === 'milestone')) {
        applicableOptions = applicableOptions.filter(option => option.value !== 'today')
      }
      return applicableOptions
    },
    setCompoundKeyData() {
      if (this.isCompoundKey) {
        this.keyItem.isCompoundKey = true
        this.autoExpandCompoundItemInputSectionFields = true
        this.keyItem.compoundItems = getDefaultCompoundKeys(this.isExcelBatch, this.compoundKeySettingName)
        this.keyItem.qualifierValue = ''
        this.keyItem.type = null
        this.displayCompoundItems = true
      } else {
        this.keyItem.isCompoundKey = false
        this.keyItem.compoundItems = []
        this.keyItem.type = getDefaultKey(this.isExcelBatch).type
        this.displayCompoundItems = false
      }
      this.onTypeChange()
    },
    add() {
      const record = getDefaultKey(this.isExcelBatch)
      record.isCompoundItem = true

      const compoundItems = [...this.keyItem.compoundItems]
      compoundItems.push(record)
      this.keyItem.compoundItems = compoundItems

      this.displayCompoundItems = true
    },
    onQualifierChange(qualifierValue) {
      if (!qualifierValue) {
        return
      }

      if (['AWBPackWeight', 'AWBHouseBill', 'AWBMasterBill'].includes(qualifierValue)) {
        this.keyItem.type = 'regexExtractor'

        return
      }

      if (qualifierValue.split('AWB')[0] === '') {
        this.keyItem.type = 'auto'
        this.keyItem.shape = ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.key-item {
  column-gap: 10px;
}

.regular-column {
  flex-basis:300px;
}

.dummy-column {
  flex-basis:900px;
}

.action-column {
  flex-basis:80px;
}

.action-column-content {
 column-gap: 10px;
}

.advance-settings-dropdown-form {
  width: 250px;

  ::v-deep .form-group:last-child {
    margin-bottom: 0.3rem !important
  }
}
</style>
