<template>
  <b-tr>
    <b-td>
      <form-input
        v-model="definedKey.label"
        type="text"
        @selection-input="onSelectionInput"
      />
    </b-td>
    <b-td>{{ definedKey.data.pageIndex }}</b-td>
    <b-td>{{ definedKey.data.pos }}</b-td>
    <b-td>{{ definedKey.data.xPercentage }}</b-td>
    <b-td>{{ definedKey.data.yPercentage }}</b-td>
    <b-td>{{ definedKey.data.language }}</b-td>
    <b-td>{{ definedKey.data.style }}</b-td>
    <b-td>
      <div>
        <feather-icon
          v-b-tooltip.hover
          title="Delete Key"
          icon="Trash2Icon"
          class="cursor-pointer mx-auto"
          size="20"
          @click.stop="$emit('deleteItem')"
        />
      </div>
    </b-td>
  </b-tr>
</template>

<script>

import { VBTooltip, BTr, BTd } from 'bootstrap-vue'
import { isEqual, cloneDeep } from 'lodash'
import FormInput from '@/components/UI/FormInput.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    FormInput,
    BTr,
    BTd,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      definedKey: null,
    }
  },
  computed: {
    out() {
      return cloneDeep(this.definedKey)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.definedKey = cloneDeep(this.value)
    },
    onSelectionInput(data) {
      const xDistance = parseFloat(data.startPos) + ((parseFloat(data.endPos) - parseFloat(data.startPos)) / 2)
      const xPercentage = (xDistance / data.pageWidth) * 100

      const yDistance = parseFloat(data.topPos) + ((parseFloat(data.bottomPos) - parseFloat(data.topPos)) / 2)
      const yPercentage = (yDistance / data.pageHeight) * 100

      this.definedKey.data = {
        pos: `${data.startPos},${data.topPos},${data.endPos},${data.bottomPos}`,
        xPercentage,
        yPercentage,
        pageIndex: data.pageIndex,
        style: data.style,
        language: this.$store.getters['batch/selectedDocument'].language,
      }
    },
  },
}
</script>
