<template>
  <validation-observer
    ref="tableRulesForm"
    mode="eager"
  >
    <b-table-simple
      ref="table"
      class="custom-table h-100"
      sticky-header="100%"
    >
      <colgroup>
        <col style="width: 30%">
        <col style="width: 65%">
        <col style="width: 5%">
      </colgroup>

      <b-thead>
        <b-tr>
          <b-th>
            Type
          </b-th>
          <b-th>
            Inputs
          </b-th>
          <b-th />
        </b-tr>
      </b-thead>

      <draggable
        v-model="items"
        tag="tbody"
        handle=".handle"
        v-bind="dragOptions"
      >
        <rule
          v-for="(item, itemIndex) of items"
          :key="itemIndex"
          v-model="items[itemIndex]"
          :rule-types="ruleTypes"
          :rule-index="itemIndex"
          @deleteItem="deleteItem(itemIndex)"
          @dropdownOpen="scrollToIndex(itemIndex)"
        />
      </draggable>
    </b-table-simple>
  </validation-observer>
</template>

<script>
import {
  VBTooltip, BTableSimple, BThead, BTh, BTr,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { isEqual, cloneDeep } from 'lodash'
import { ValidationObserver } from 'vee-validate'

import bus from '@/bus'
import Rule from '@/components/UI/Rule.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BTableSimple,
    Rule,
    draggable,
    BThead,
    BTh,
    BTr,
    ValidationObserver,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      dragOptions: {
        animation: 0,
        ghostClass: 'draggable-ghost',
      },
    }
  },
  computed: {
    defaultType() {
      return this.$store.getters['applicationSettings/tableRuleSettings'].defaultType
    },
    ruleTypes() {
      return this.$store.getters['applicationSettings/tableRuleSettings'].types
    },
    out() {
      return cloneDeep(this.items)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
    bus.$on('dataView/addTableRules', this.addItems)
    bus.$on('validateTableRules', this.validateTableRules)
    bus.$on('dataView/tableRules/scrollToIndex', this.scrollToIndex)
  },
  destroyed() {
    bus.$off('dataView/addTableRules', this.addItems)
    bus.$off('validateTableRules', this.validateTableRules)
    bus.$off('dataView/tableRules/scrollToIndex', this.scrollToIndex)
  },
  methods: {
    setInternalState() {
      this.items = cloneDeep(this.value)
    },
    addItems(count) {
      const lastRowIndex = this.items.length - 1
      const newItems = []
      for (let i = 0; i < count; i += 1) {
        const newRuleType = this.ruleTypes.find(ruleType => ruleType.key === this.defaultType)
        newItems.push({
          type: this.defaultType,
          inputs: cloneDeep(newRuleType.defaultValue),
        })
      }

      this.items = this.items.concat(newItems)
      this.$nextTick(() => {
        this.scrollToIndex(lastRowIndex + 1)
      })
    },
    scrollToIndex(index) {
      const table = this.$refs.table.$el
      const tbody = table.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[index]
      const thead = table.querySelector('thead')
      table.scrollTop = row.offsetTop - (thead.offsetHeight + 10)
    },
    deleteItem(index) {
      this.items.splice(index, 1)
    },
    validateTableRules(callback) {
      this.$refs.tableRulesForm.validate().then(success => {
        callback(success)
      })
    },
  },
}
</script>
