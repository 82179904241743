<template>
  <div class="h-100">
    <b-table-simple
      ref="table"
      sticky-header="100%"
      class="custom-table h-100"
    >
      <colgroup>
        <col
          v-for="(tableColumn) of tableColumns"
          :key="tableColumn.key"
          :style="{ width: tableColumn.width + '%' }"
        >
      </colgroup>

      <b-thead>
        <b-tr>
          <b-th
            v-for="(tableColumn) of tableColumns"
            :key="tableColumn.key"
          >
            <span
              v-b-tooltip.hover
              class="text-capitalize"
              :title="tableColumn.tooltip"
            >
              {{ tableColumn.label }}
            </span>
          </b-th>
          <b-th />
        </b-tr>
      </b-thead>
      <draggable
        v-model="columns"
        tag="tbody"
        handle=".handle"
        v-bind="dragOptions"
      >
        <b-tr
          v-for="(column, columnIndex) of columns"
          :key="columnIndex"
        >
          <b-td
            v-for="(columnField) of columnFields"
            :key="columnField.key"
          >
            <form-input
              v-if="columnField.type === 'text'"
              v-model="columns[columnIndex][columnField.key]"
              type="text"
              :placeholder="columnField.key"
            />

            <v-select
              v-if="columnField.type === 'keySelect'"
              v-model="columns[columnIndex][columnField.key]"
              :label="sortedOptions[columnField.optionsId].lableKey"
              :options="keySelectOptions"
              :reduce="option => option[sortedOptions[columnField.optionsId].valueKey]"
              @open="onDropdownOpen(columnIndex)"
              @input="(e) => onColumnFieldNameChange(e, columnIndex)"
            />

            <v-select
              v-if="columnField.type === 'select'"
              v-model="columns[columnIndex][columnField.key]"
              :label="sortedOptions[columnField.optionsId].lableKey"
              :options="sortedOptions[columnField.optionsId].items"
              :reduce="option => option[sortedOptions[columnField.optionsId].valueKey]"
              @open="onDropdownOpen(columnIndex)"
            />

            <qualifier-select
              v-if="columnField.type === 'qualifierSelect'"
              v-model="columns[columnIndex][columnField.key]"
              :label="columnField.label"
              :key-value="columns[columnIndex][columnField.selectionField]"
              :key-options="keySelectOptions"
              :field-name="columnField.key"
              @dropdownOpen="onDropdownOpen(columnIndex)"
            />

            <pipe-separated-input
              v-if="columnField.type === 'pipeSeparatedInput' && columnField.key === 'shape'"
              v-model="columns[columnIndex][columnField.key]"
              :label="columnField.label"
              selection-value-attr="text"
              listenable-input
              :hide-form-group-label="true"
              @selection-input="shapeSelectionInputHandler(columnIndex,$event)"
              @item-deleted="shapeSelectionItemDeleteHandler(columnIndex, $event)"
            />

            <pipe-separated-input
              v-if="columnField.type === 'pipeSeparatedInput' && columnField.key !== 'shape'"
              v-model="columns[columnIndex][columnField.key]"
              :label="columnField.label"
              :selection-value-attr="columnField.key === 'startPos' ? 'startPos' : columnField.key === 'endPos' ? 'endPos': 'text'"
              listenable-input
              :hide-form-group-label="true"
            />

            <capture-text-input
              v-if="columnField.type === 'captureStartPos'"
              v-model="columns[columnIndex][columnField.key]"
              :label="columnField.label"
              :validation-key="`${columnField.key}_${columnIndex}`"
              type="startPos"
            />

            <capture-text-input
              v-if="columnField.type === 'captureEndPos'"
              v-model="columns[columnIndex][columnField.key]"
              :label="columnField.label"
              :validation-key="`${columnField.key}_${columnIndex}`"
              type="endPos"
            />

            <cell-range-selector
              v-if="columnField.type === 'cellRangeSelectorMultiple'"
              v-model="columns[columnIndex][columnField.key]"
              :label="columnField.label"
              :validation-key="`${columnField.key}_${columnIndex}`"
              :initialize-expanded="false"
              multiple
            />
          </b-td>
          <b-td>
            <div class="d-flex">
              <feather-icon
                icon="AlignJustifyIcon"
                class="cursor-move handle mr-1"
                size="20"
              />
              <feather-icon
                v-b-tooltip.hover
                title="Delete Column"
                icon="Trash2Icon"
                class="cursor-pointer delete-column-btn"
                size="20"
                @click.stop="deleteColumn(columnIndex)"
              />
            </div>
          </b-td>
        </b-tr>
      </draggable>

    </b-table-simple>
  </div>
</template>

<script>
import {
  VBTooltip, BTableSimple, BThead, BTr, BTh, BTd,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { isEqual, cloneDeep } from 'lodash'
import draggable from 'vuedraggable'
import PipeSeparatedInput from '@/components/UI/PipeSeparatedInput.vue'
import bus from '@/bus'
import FormInput from '@/components/UI/FormInput.vue'
import QualifierSelect from '@/components/UI/QualifierSelect.vue'
import CaptureTextInput from '@/components/UI/CaptureTextInput/CaptureTextInput.vue'
import CellRangeSelector from '@/components/UI/CellRangeSelector/CellRangeSelector.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    vSelect,
    PipeSeparatedInput,
    FormInput,
    QualifierSelect,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    draggable,
    CaptureTextInput,
    CellRangeSelector,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: [],
      posFields: ['startPos', 'endPos'],
    }
  },
  computed: {
    optionsKeyItems() {
      return this.$store.getters['definitionSettings/options']['options-keys'].items
    },
    isExcelBatch() {
      return this.$store.getters['batch/batch'].isExcel
    },
    columnFields() {
      const columnFields = this.$store.getters['applicationSettings/tableSettings'].column.fields
      const batchFileType = this.isExcelBatch ? 'excel' : 'pdf'
      return columnFields.filter(columnField => columnField.applicableFor.includes(batchFileType))
    },
    sortedOptions() {
      return {
        ...this.$store.getters['applicationSettings/options'],
        ...this.$store.getters['definitionSettings/sortedOptions'],
      }
    },
    keySelectOptions() {
      return this.$store.getters['definitionSettings/keyOptionsApplicableForTable']
    },
    out() {
      return cloneDeep(this.columns)
    },
    tableColumns() {
      const columns = this.columnFields.map(columnField => ({
        ...columnField,
        key: columnField.key,
        label: columnField.label,
      }))
      columns.push({
        key: 'actions', label: '',
      })
      return columns
    },
    dragOptions() {
      return {
        animation: 0,
        ghostClass: 'draggable-ghost',
      }
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
    bus.$on('dataView/addColumns', this.addColumns)
  },
  destroyed() {
    bus.$off('dataView/addColumns', this.addColumns)
  },
  methods: {
    onColumnFieldNameChange(keyValue, index) {
      const optionsKeyItem = this.optionsKeyItems.find(e => e.keyValue === keyValue)

      this.columns[index].export = optionsKeyItem.export
    },
    setInternalState() {
      const items = this.value.map(record => {
        const item = {}
        this.columnFields.forEach(columnField => {
          const value = record[columnField.key] !== undefined ? record[columnField.key] : (columnField.defaultValue || '')
          item[columnField.key] = value
        })

        if (record?.colLabel) {
          const optionsKeyItem = this.optionsKeyItems.find(e => e.keyValue === record.colLabel)

          if (optionsKeyItem) {
            item.export = optionsKeyItem.export
          }
        }

        return item
      })
      this.columns = items
    },
    addColumns(count) {
      const lastRowIndex = this.columns.length - 1
      const expandStatus = []
      const cols = []
      for (let i = 0; i < count; i += 1) {
        const col = {}
        this.columnFields.forEach(columnField => {
          col[columnField.key] = columnField.defaultValue || ''
        })
        cols.push(col)
        expandStatus.push(false)
      }

      this.columns = this.columns.concat(cols)
      this.$nextTick(() => {
        this.scrollToIndex(lastRowIndex + 1)
      })
    },
    scrollToIndex(index) {
      const table = this.$refs.table.$el
      const tbody = table.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[index]
      const thead = table.querySelector('thead')
      table.scrollTop = row.offsetTop - (thead.offsetHeight + 10)
    },
    deleteColumn(index) {
      this.columns.splice(index, 1)
    },
    shapeSelectionInputHandler(colIndex, data) {
      this.posFields.forEach(posField => {
        let newValue
        if (data.index === -1) {
          newValue = data.selectionData[posField]
        } else {
          const currentValue = this.columns[colIndex][posField]
          const newValueArray = currentValue.split('|')
          newValueArray[data.index] = data.selectionData[posField]
          newValue = newValueArray.join('|')
        }
        this.columns[colIndex][posField] = newValue
      })
    },
    shapeSelectionItemDeleteHandler(colIndex, itemIndex) {
      this.posFields.forEach(posField => {
        const currentValue = this.columns[colIndex][posField]
        const newValueArray = currentValue.split('|')
        newValueArray.splice(itemIndex, 1)
        const newValue = newValueArray.join('|')
        this.columns[colIndex][posField] = newValue
      })
    },
    onDropdownOpen(index) {
      this.$nextTick(() => {
        this.scrollToIndex(index)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
