var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"Top Anchor Shape","rules":"validAnchorShape","vid":(_vm.validationKey + "-anchors-top")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('anchor-shape-input',{staticClass:"flex-grow-1",class:{
          'invalid-input': errors.length > 0 ? true : false
        },attrs:{"variant":"top"},on:{"focus":function($event){return _vm.$emit('focus', 'top')}},model:{value:(_vm.anchors.top),callback:function ($$v) {_vm.$set(_vm.anchors, "top", $$v)},expression:"anchors.top"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',[_c('feather-icon',{staticClass:"cursor-pointer mx-50",attrs:{"icon":_vm.expanded ? 'ChevronDownIcon' : 'ChevronUpIcon',"size":"20"},on:{"click":function($event){_vm.expanded = !_vm.expanded}}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}]},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"row-gap":"0.25rem","margin-top":"0.25rem"}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"Bottom Anchor Shape","rules":"validAnchorShape","vid":(_vm.validationKey + "-anchors-bottom")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('anchor-shape-input',{class:{
            'invalid-input': errors.length > 0 ? true : false
          },attrs:{"variant":"bottom"},on:{"focus":function($event){return _vm.$emit('focus', 'bottom')}},model:{value:(_vm.anchors.bottom),callback:function ($$v) {_vm.$set(_vm.anchors, "bottom", $$v)},expression:"anchors.bottom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._l((_vm.patterns),function(pattern,patternIndex){return _c('div',{key:patternIndex,staticClass:"d-flex align-items-baseline"},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"Pattern","rules":"required","vid":(_vm.validationKey + "-pattern-" + patternIndex)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Pattern"},model:{value:(_vm.patterns[patternIndex]),callback:function ($$v) {_vm.$set(_vm.patterns, patternIndex, $$v)},expression:"patterns[patternIndex]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.patterns.length > 1)?_c('div',[_c('feather-icon',{staticClass:"cursor-pointer ml-50",attrs:{"icon":"Trash2Icon","size":"20"},on:{"click":function($event){$event.stopPropagation();return _vm.deletePattern(patternIndex)}}})],1):_vm._e()],1)}),_c('div',[_c('add-item',{attrs:{"label":"Pattern"},on:{"add":_vm.addPatterns}})],1)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }