<template>
  <feather-icon
    v-b-tooltip.hover
    icon="ZoomOutIcon"
    size="20"
    class="cursor-pointer"
    title="Zoom Out"
    @click="zoomOut"
  />
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    zoomOut() {
      this.$store.dispatch('batch/zoomOut')
    },
  },
}
</script>
