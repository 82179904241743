<template>
  <div class="d-flex align-items-center">
    <v-select
      v-model="batchId"
      :clearable="false"
      :options="options"
      :loading="dataViewLoading"
      style="width: 100%"
      class="batch-selector"
    >
      <template #spinner="{ loading }">
        <b-spinner
          v-if="loading"
          variant="primary"
          small
        />
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BSpinner,
  // BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    vSelect,
    BSpinner,
    // BFormCheckbox,
  },
  computed: {
    batchId: {
      get() {
        return this.$store.getters['batch/batch'].id
      },
      set(value) {
        this.$store.dispatch('dataView/onChangeBatch', { batchId: value, refresh: false })
      },
    },
    options() {
      return this.$store.getters['dataView/batchesByDefinitionType']
    },
    dataViewLoading() {
      return this.$store.getters['dataView/loading']
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
