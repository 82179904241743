<template>
  <div
    class="d-flex align-items-center atm-actions"
  >
    <b-button
      v-if="atmWizardTabs.tableRowSelection.active"
      :disabled="loading"
      variant="outline-primary"
      class="ml-auto cursor-pointer-not-allowed"
      @click="onChangeTab"
    >
      Next
    </b-button>

    <b-button
      v-if="atmWizardTabs.results.active"
      :disabled="loading"
      variant="outline-primary"
      class="ml-auto cursor-pointer-not-allowed"
      @click="findAtmPatterns"
    >
      Find Patterns
    </b-button>

    <b-button
      v-if="!atmWizardTabs.test.active"
      :disabled="loading"
      variant="outline-primary"
      class="ml-auto cursor-pointer-not-allowed"
      @click="save"
    >
      Save
      <b-spinner
        v-if="loading"
        small
        label="Small Spinner"
      />
    </b-button>

    <b-button
      v-if="atmWizardTabs.test.active"
      :disabled="loading"
      variant="outline-primary"
      class="ml-auto cursor-pointer-not-allowed"
      @click="goToModel"
    >
      Go to Model
    </b-button>

    <b-button
      v-if="atmWizardTabs.test.active"
      :disabled="loading"
      variant="outline-primary"
      class="ml-auto cursor-pointer-not-allowed"
      @click="test"
    >
      Test
    </b-button>
  </div>
</template>

<script>
import bus from '@/bus'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BButton, BSpinner,
  },
  data() {
    return {
      warn: false,
    }
  },
  computed: {
    batchId() {
      return this.$store.getters['batch/batch'].id
    },
    selectedAtmPatterns() {
      return this.$store.getters['atm/selectedAtmPatterns']
    },
    atmPatterns() {
      return this.$store.getters['atm/atmPatterns']
    },
    atmWizardTabs() {
      return this.$store.getters['atm/atmWizardTabs']
    },
    userSelectedPatterns() {
      return this.$store.getters['atm/userSelectedPatterns']
    },
    extendedUserSelectedPatterns() {
      return this.$store.getters['atm/extendedUserSelectedPatterns']
    },
    modelUserSelectedPatterns() {
      return this.$store.getters['dataView/modelUserSelectedPatterns']
    },
    userSelectedOb() {
      return this.$store.getters['atm/userSelectedOb']
    },
    modelUserSelectedOb() {
      return this.$store.getters['dataView/modelUserSelectedOb']
    },
    status() {
      return this.$store.getters['batch/status']
    },
    loading: {
      get() {
        return this.$store.getters['dataView/loading']
      },
      set(value) {
        this.$store.commit('dataView/SET_LOADING', value)
      },
    },
  },
  watch: {
    atmWizardTabs(newVal) {
      if (!newVal.results.active && this.warn) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please save before leave',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  created() {
    bus.$on('findAtmPatterns', this.findAtmPatterns)
  },
  destroyed() {
    bus.$off('findAtmPatterns', this.findAtmPatterns)
  },
  methods: {
    onChangeTab() {
      this.$store.commit('atm/SET_ATM_PATTERNS', [])
      bus.$emit('atm/onChangeTab', 'results')
    },
    save() {
      this.loading = true

      if (this.warn && this.atmWizardTabs.results.active) {
        this.warn = false
      }

      if (this.atmWizardTabs.results.active && this.atmPatterns.length) {
        this.$store.commit('dataView/SET_MODEL_AUTO_PATTERNS', this.selectedAtmPatterns)
        this.$store.commit('dataView/SET_MODEL_AUTO_TYPE', 'autoPattern')
      }

      // Set modelUserSelectedPatterns
      this.$store.commit('dataView/SET_MODEL_USER_SELECTED_PATTERNS', this.userSelectedPatterns)

      // Set modelExtendedUserSelectedPatterns
      this.$store.commit('dataView/SET_MODEL_EXTENDED_USER_SELECTED_PATTERNS', this.extendedUserSelectedPatterns.filter(e => e.length))

      // Set modelUserSelectedOb
      this.$store.commit('dataView/SET_MODEL_USER_SELECTED_OB', this.userSelectedOb)

      bus.$emit('dataView/saveTableData')

      setTimeout(() => {
        this.loading = false
      }, 100)
    },
    findAtmPatterns() {
      if (!this.warn) {
        this.warn = true
      }

      this.$store.commit('atm/SET_ATM_PATTERNS', [])

      bus.$emit('dataView/saveTableData')

      this.$store.dispatch('atm/findAtmPatterns')
    },
    goToModel() {
      this.$store.commit('dataView/SET_MODEL_AUTO_TYPE', 'autoPattern')

      bus.$emit('dataView/saveTableData')

      setTimeout(() => {
        this.$store.commit('batch/SET_VIEW', 'table')

        this.$router.push({
          name: 'batch',
          params: {
            id: this.batchId,
          },
        })
      }, 200)
    },
    test() {
      this.$store.commit('dataView/SET_MODEL_AUTO_TYPE', 'autoPattern')

      this.$store.dispatch('atm/runTest')
    },
  },
}
</script>

<style scoped>
.atm-actions {
  column-gap: 1rem;
  margin-left: auto;
}
</style>
