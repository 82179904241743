<template>
  <validation-provider
    #default="{ errors }"
    :name="label"
    :rules="qualifier ? validationRules : ''"
    :vid="validationKey"
  >
    <b-form-group
      class="mb-0"
      :state="errors.length > 0 ? false:null"
    >
      <v-select
        v-model="qualifierValue"
        :disabled="!qualifier"
        :options="options"
        label="label"
        :reduce="option => option.value"
        @open="$emit('dropdownOpen')"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  components: {
    vSelect,
    BFormGroup,
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    keyValue: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    label: {
      type: String,
      required: true,
    },
    validationKey: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    validationRules: {
      type: String,
      required: false,
      default() {
        return ''
      },
    },
    keyOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      qualifierValue: null,
    }
  },
  computed: {
    qualifiers() {
      return this.$store.getters['definitionSettings/keyQualifiers']
    },
    qualifier() {
      if (!this.keyValue) {
        return null
      }
      const keyOptionItem = this.keyOptions.find(item => item.keyValue === this.keyValue)
      if (!keyOptionItem?.qualifier) {
        return null
      }
      return this.qualifiers.find((qualifier => qualifier.name === keyOptionItem.qualifier))
    },
    out() {
      return this.qualifierValue
    },
    options() {
      if (this.qualifier) {
        return this.qualifier.options
      }
      return []
    },
  },
  watch: {
    qualifier: {
      deep: true,
      handler() {
        this.qualifierValue = null
      },
    },
    out: {
      handler(val) {
        if (val !== this.value) {
          this.$emit('input', val)
        }
      },
    },
    value: {
      handler(val) {
        if (val !== this.out) {
          this.setInternalState()
        }
      },
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.qualifierValue = this.value
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
