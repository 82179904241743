<template>
  <div
    class="d-flex align-items-center my-50"
    style="column-gap: 10px;"
  >
    <div style="flex-basis:300px;">
      <v-select
        v-model="item.column"
        :options="columnOptions"
        :clearable="false"
      />
    </div>
    <div style="flex-basis:170px;">
      <v-select
        v-model="item.operator"
        :options="operatorOptions"
        :clearable="false"
      />
    </div>
    <div style="flex-basis:500px;">
      <div class="d-flex">
        <div>
          <v-select
            v-model="item.valueType"
            style="width: 160px;"
            :options="valueTypeOptions"
            label="label"
            :reduce="option => option.value"
            :clearable="false"
            @input="onValueTypeInput"
          />
        </div>
        <div class="flex-grow-1">
          <b-form-input
            v-if="item.valueType === 'input'"
            v-model="item.value"
          />
          <v-select
            v-if="item.valueType === 'queryResult'"
            v-model="item.value"
            :options="queryResultOptions"
            :clearable="false"
            label="label"
            :reduce="option => option.value"
          />
          <v-select
            v-if="item.valueType === 'key'"
            v-model="item.value"
            :options="keyOptions"
            :clearable="false"
            label="label"
            :reduce="option => option.value"
          />
        </div>
      </div>
    </div>
    <div>
      <feather-icon
        v-b-tooltip.hover
        title="Delete Rule"
        icon="Trash2Icon"
        class="cursor-pointer mx-auto"
        size="20"
        @click.stop="$emit('delete')"
      />
    </div>
  </div>
</template>

<script>
import { BFormInput, VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { isEqual, cloneDeep } from 'lodash'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    vSelect,
    BFormInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    queryResultOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      item: {},
      valueTypeOptions: [
        {
          label: 'Key',
          value: 'key',
        },
        {
          label: 'Input',
          value: 'input',
        },
        {
          label: 'Query Result',
          value: 'queryResult',
        },
      ],
    }
  },
  computed: {
    out() {
      return cloneDeep(this.item)
    },
    columnOptions() {
      return this.tableColumns.map(tableColumn => tableColumn.name)
    },
    operatorOptions() {
      let operators = []

      if (!this.item.column) {
        return operators
      }

      const tableColumn = this.tableColumns.find(tableCol => tableCol.name === this.item.column)
      if (!tableColumn) {
        return operators
      }

      if (['NUMBER'].includes(tableColumn.type)) {
        operators = ['=', '>', '>=', '<', '<=']
      } else if (['VARCHAR2'].includes(tableColumn.type)) {
        operators = ['FUZZY MATCH', '=', 'LIKE', 'ILIKE']
      } else {
        operators = ['FUZZY MATCH', '=', 'LIKE', 'ILIKE']
      }

      return operators
    },
    keyOptions() {
      const keys = this.$store.getters['batch/selectedDocumentKeysForLookup']
      return keys.map(key => ({
        label: key.label,
        value: `<K>${key.label}</K>`,
      }))
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.item = cloneDeep(this.value)
    },
    onValueTypeInput() {
      this.item.value = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
