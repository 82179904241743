<template>
  <div class="d-inline-block">
    <b-dropdown
      right
      no-caret
      variant="link"
      toggle-class="text-decoration-none"
      class="text-primary config-dropdown"
    >
      <template #button-content>
        <feather-icon
          icon="SettingsIcon"
          size="16"
          class="cursor-pointer"
        />
      </template>

      <b-dropdown-item
        v-for="option of options"
        :key="option.value"
        @click.stop="selectOption(option.value)"
      >
        {{ option.label }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdownItem, BDropdown } from 'bootstrap-vue'

export default {
  components: {
    BDropdownItem,
    BDropdown,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [
        {
          label: 'Rules',
          value: 'rules',
        },
      ],
    }
  },
  computed: {
    configValue() {
      return this.$store.getters['batch/nodeConfig'][this.nodeId]
    },
  },
  methods: {
    selectOption(value) {
      if (value === 'rules') {
        this.$store.dispatch('dataView/setTableRuleItem', {
          label: this.label,
        })
        this.$store.dispatch('dataView/setMode', 'table-rules')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.config-dropdown {
    opacity: 0;

    &.show {
        opacity: 1;
    }

    &:hover {
        opacity: 1;
    }

    ::v-deep .btn {
        padding: 0px !important;
    }
}
</style>
