<template>
  <validation-provider
    #default="{ errors }"
    :name="label"
    :rules="validationRules"
    :vid="validationKey"
  >
    <b-input-group
      class="input-group-merge"
      :class="errors.length > 0 ? 'is-invalid':null"
    >
      <template #append>
        <b-input-group-text class="py-0 my-0">
          <span>
            <feather-icon
              v-if="selectedCellDetails === null"
              v-b-tooltip.hover
              icon="InfoIcon"
              size="15"
              title="Select cell range in excel view to capture value"
            />

            <feather-icon
              v-if="selectedCellDetails !== null"
              v-b-tooltip.hover
              icon="CrosshairIcon"
              size="15"
              class="cursor-pointer"
              :title="captured ? 'Captured!' : 'Capture'"
              @click="capture()"
            />
          </span>
        </b-input-group-text>

        <span
          v-if="deletable && displayValue"
          class="my-auto"
        >
          <feather-icon
            v-b-tooltip.hover
            icon="Trash2Icon"
            class="cursor-pointer"
            style="margin-left: 0.5rem;"
            size="18"
            title="Clear Selection"
            @click="onDelete()"
          />
        </span>
      </template>
      <b-form-input
        v-model="displayValue"
        :state="errors.length > 0 ? false:null"
        :placeholder="placeholder"
        disabled
      />
    </b-input-group>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { isEqual, cloneDeep } from 'lodash'
import {
  VBTooltip, BFormInput, BInputGroup, BInputGroupText,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupText,
    ValidationProvider,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    validationKey: {
      type: String,
      required: true,
    },
    validationRules: {
      type: String,
      required: false,
      default() {
        return ''
      },
    },
    placeholder: {
      type: String,
      required: false,
      default() {
        return ''
      },
    },
    deletable: {
      type: Boolean,
      required: false,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      inputItem: {},
      captured: false,
    }
  },
  computed: {
    out() {
      return cloneDeep(this.inputItem)
    },
    displayValue() {
      const { sheetName, cellRange } = this.inputItem

      if (!sheetName && !cellRange) {
        return null
      }

      if (!sheetName || !this.getIsfullCellLabel) {
        return `${cellRange}`
      }

      return `${sheetName} ${cellRange}`
    },
    selectedCellDetails() {
      return this.$store.getters['batch/selectedCellDetails']
    },
    getIsfullCellLabel() {
      return this.$store.getters['dataView/getIsfullCellLabel']
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.inputItem = cloneDeep(this.value)
    },
    capture() {
      this.inputItem = this.selectedCellDetails
      this.captured = true

      setTimeout(() => {
        this.captured = false
      }, 1000)
    },
    onDelete() {
      this.inputItem = {
        cellRange: null,
        sheetName: null,
      }
    },
  },
}
</script>
