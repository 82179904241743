<template>
  <div class="mx-1">
    <key-item
      v-for="(keyItem, keyItemIndex) of keys"
      :key="keyItemIndex"
      v-model="keys[keyItemIndex]"
      :parent="compoundKeySettingName"
      :auto-expand-input-section-fields="autoExpandInputSectionFields"
      :key-lable-options="keyLableOptions"
      @deleteItem="deleteKey(keyItemIndex, keyItem.id)"
      @dropdownOpen="$emit('dropdownOpen', keyItem.id)"
      @highlightAnchors="$emit('highlightAnchors', { keyItemId: keyItem.id, data: $event})"
    />
  </div>
</template>

<script>
import { isEqual, cloneDeep } from 'lodash'
import { normalizeKeyItemValues } from './key-helper'

export default {
  components: {
    KeyItem: () => import('@/components/Batch/DataViewer/KeyItems/KeyItem.vue'),
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    autoExpandInputSectionFields: {
      type: Boolean,
      required: true,
    },
    compoundKeySettingName: {
      type: String,
      required: false,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      keys: [],
    }
  },
  computed: {
    out() {
      return cloneDeep(this.keys)
    },
    isExcelBatch() {
      return this.$store.getters['batch/batch'].isExcel
    },
    keyLableOptions() {
      const compoundKeySettings = this.$store.getters['definitionSettings/compoundKeys']
      const compoundKeySetting = compoundKeySettings.find(item => item.name === this.compoundKeySettingName)
      const keyItems = compoundKeySetting ? compoundKeySetting.keyItems : []
      return keyItems
    },
    optionsKeyItems() {
      return this.$store.getters['definitionSettings/options']['options-keys'].items
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.keys = normalizeKeyItemValues(this.value, this.optionsKeyItems)
    },
    deleteKey(index, keyItemId) {
      if (keyItemId === this.$store.getters['batch/highlightKeyAnchorsData'].keyItemId) {
        this.$store.dispatch('batch/clearAnchorHighlights')
      }
      this.keys.splice(index, 1)
    },
  },
}
</script>
