<template>
  <b-table-simple
    v-if="table && items && items.length"
    hover
    striped
    sticky-header="100%"
    class="test-result-table h-100"
    responsive
  >
    <b-thead>
      <b-tr>
        <b-th
          v-for="(tableField, indexField) of tableFields"
          :key="indexField+'field'"
        >
          <span>{{ tableField.toUpperCase() }}</span>
          <span class="table-column-config">
            <table-column-config
              v-if="mainMode !== 'verification'"
              class="ml-25"
              :label="tableField"
            />
          </span>
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr
        v-for="(item, itemIndex) of items"
        :key="itemIndex"
        :class="[{'hover-background-add': isAddRow}, {'hover-background-delete': isDeleteRow}, {'selected-background-delete': selectedIndexForDelete === itemIndex}]"
        @click="rowAction(itemIndex)"
      >
        <template v-for="(tableField, indexed) of tableFields">
          <b-td
            v-if="item[tableField] && item[tableField] !== undefined && item[tableField].v"
            :key="indexed + 'row-with-value'"
            :variant="cellVariant(item[tableField])"
            @click="onCellClick(item[tableField])"
            @dblclick="onCellDbClick(item[tableField])"
          >
            <div
              :class="{
                'highlight-node': mainMode === 'verification' && editableNode && item[tableField] && item[tableField].id === editableNode.id
              }"
            >
              <span
                v-if="item[tableField] && item[tableField].v"
              >{{ item[tableField].v }}
              </span>
            </div>
          </b-td>
          <b-td
            v-else
            :key="indexed + 'row-without-value'"
          >
            <div
              v-if="mainMode === 'verification'"
              class="d-flex"
            >
              <template v-if="itemIndexG === itemIndex && fieldIndex === indexed">
                <form-input
                  id="cell-field"
                  ref="AddRowInput"
                  :class="{'highlight-node':foucued}"
                  style="max-width: 300px;"
                  type="text"
                  :value="newCell.text"
                  :placeholder="title"
                  @input="onInput"
                  @selection-input="onSelectionInput"
                  @focus="foucued=true"
                />
                <b-button-group
                  v-if="newCell.pos"
                  size="sm"
                  style="padding: 4px;"
                >
                  <b-button
                    variant="outline-primary"
                    @click="saveCell(item, tableField, itemIndex)"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="16"
                    />
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    @click="closeCell"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="16"
                    />
                  </b-button>
                </b-button-group>
              </template>
              <p
                v-else
                class="text-danger flex-grow-1"
                @click="showAddField(itemIndex, indexed)"
              >NULL
              </p>
            </div>
          </b-td>
        </template>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>
<script>
import bus from '@/bus'
import {
  BTableSimple, BThead, BTbody, BTh, BTr, BTd, BButtonGroup, BButton,
} from 'bootstrap-vue'
import { cloneDeep } from 'lodash'

import FormInput from '@/components/UI/FormInput.vue'
import TableColumnConfig from './TableColumnConfig.vue'

const defaultValue = {
  text: null,
  pos: null,
  pageIndex: null,
  documentIndex: null,
  threshold: null,
}

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
    TableColumnConfig,
    FormInput,
    BButtonGroup,
    BButton,
  },
  data() {
    return {
      newCell: {
        text: '',
      },
      title: '',
      trigger: 0,
      foucued: false,
      itemIndexG: -1,
      fieldIndex: '',
      selectedIndexForDelete: -1,
    }
  },
  computed: {
    mainMode() {
      return this.$store.getters['dataView/mainMode']
    },
    tables() {
      // eslint-disable-next-line no-unused-expressions
      this.trigger
      return this.$store.getters['batch/selectedDocument']?.tables || []
    },

    displayNotInUseFields() {
      return this.$store.getters['dataView/displayNotInUseFields']
    },
    selectedTableId() {
      return this.$store.getters['dataView/selectedTableId']
    },
    table() {
      const definitionTables = this.$store.getters['dataView/table']

      if (!definitionTables.length) {
        return null
      }

      const { table_unique_id } = definitionTables[this.selectedTableId]

      // eslint-disable-next-line camelcase
      return this.tables.find(i => i.table_unique_id === table_unique_id)
    },
    items() {
      let items = this.table?.rows || []

      items = items.filter(item => {
        let isEmptyRow = true

        for (let index = 0; index < this.tableFields.length; index += 1) {
          // const tableField = this.tableFields[index]
          if (item) {
            isEmptyRow = false
            break
          }
        }

        return !isEmptyRow
      })
      if (items.length > 1) {
        this.$store.commit('batch/CHECK_DELETE_ROW', true)
      } else {
        this.$store.commit('batch/CHECK_DELETE_ROW', false)
      }
      return items
    },
    definitionTableColumns() {
      const tableColumns = this.$store.getters['dataView/tableColumns']

      if (!tableColumns) { return [] }
      return tableColumns.map(tableColumn => tableColumn.colLabel)
    },

    tableFields() {
      if (!this.table) return []

      let keys = []
      this.table.rows.forEach(item => {
        keys = keys.concat(Object.keys(item))
      })
      keys = [...new Set(keys)]

      if (!this.displayNotInUseFields) {
        keys = keys.filter(key => !key.startsWith('None') && !key.startsWith('notInUse') && !key.endsWith('_1') && !key.endsWith('_2'))
      }

      const sortedKeys = []

      this.definitionTableColumns.forEach(definitionTableColumn => {
        if (keys.includes(definitionTableColumn) && !sortedKeys.includes(definitionTableColumn)) {
          sortedKeys.push(definitionTableColumn)
        }
      })
      if (this.mainMode === 'verification') {
        keys.forEach(key => {
          if (!sortedKeys.includes(key) && !key.startsWith('None') && !key.startsWith('notInUse') && !key.endsWith('_1') && !key.endsWith('_2')) {
            sortedKeys.push(key)
          }
        })
      } else {
        keys.forEach(key => {
          if (!sortedKeys.includes(key)) {
            sortedKeys.push(key)
          }
        })
      }

      return sortedKeys
    },
    isExcelBatch() {
      return this.$store.getters['batch/batch'].isExcel
    },
    editableNode() {
      return this.$store.getters['batch/editableNode']
    },
    manualValidation() {
      return this.$store.getters['batch/manualValidation']
    },
    verificationStatus() {
      return this.$store.getters['batch/verificationStatus']
    },
    isAddRow() {
      return this.$store.getters['batch/getIsAddRow']
    },
    isDeleteRow() {
      return this.$store.getters['batch/getIsDeleteRow']
    },
  },
  watch: {
    selectedTableId() {
      this.trigger += 1
    },
    selectedIndexForDelete(value) {
      if (value > -1) {
        bus.$emit('dataView/toggleRowDeleteOption', true)
      } else {
        bus.$emit('dataView/toggleRowDeleteOption', false)
        this.$store.commit('batch/TOGGLE_DELETE_ROW', false)
      }
    },
  },
  created() {
    bus.$on('dataView/confirmRowDelete', this.removeExistingRow)
    bus.$on('dataView/cancleRowDelete', this.cancleRowDelete)
  },
  destroyed() {
    bus.$off('dataView/confirmRowDelete', this.removeExistingRow)
    bus.$off('dataView/cancleRowDelete', this.cancleRowDelete)
  },
  methods: {
    async showAddField(index, fieldIndex) {
      this.itemIndexG = index
      this.fieldIndex = fieldIndex
      this.newCell = {
        text: '',
      }
    },
    async onCellClick(cellData) {
      if (this.isExcelBatch) {
        bus.$emit('excelViewer/goToCell', {
          sheetName: cellData.worksheet_name,
          cellRange: cellData.cellRange,
        })
      } else {
        bus.$emit('scrollToPos', {
          pos: cellData.pos,
          pageId: cellData.pageId,
        })
      }
      await this.showAddField(-1, -1)
    },
    async onCellDbClick(cellData) {
      if (this.mainMode !== 'verification' || this.verificationStatus !== 'ready' || !this.manualValidation) {
        return
      }

      if (this.editableNode && this.editableNode.id === cellData.id) {
        await this.$store.dispatch('batch/setEditableNode', null)

        return
      }

      await this.$store.dispatch('batch/setEditableNode', cellData)
    },
    cellVariant(cellData) {
      let variant = null
      if (cellData.STATUS === -2) {
        variant = 'danger'
      } else if (cellData.STATUS === -1) {
        variant = 'warning'
      }
      return variant
    },
    onInput(data) {
      if (data) {
        this.newCell.text = data
      } else {
        this.newCell = cloneDeep(defaultValue)
      }
    },
    onSelectionInput(data) {
      this.newCell.pos = `${data.startPos},${data.topPos},${data.endPos},${data.bottomPos}`
    },
    async saveCell(rowItem, selectedLabel, itemIndex) {
      const incrementLastPartOfId = id => {
        const parts = id.split('.')
        const last = parts.length - 1
        parts[last] = (parseInt(parts[last], 10) + 1).toString().padStart(3, '0')
        return parts.join('.')
      }
      let currentRowLastCell
      for (let i = Object.keys(rowItem).length - 1; i >= 0; i -= 1) {
        currentRowLastCell = Object.values(rowItem)[i]
        if (currentRowLastCell !== undefined) {
          break
        }
      }

      if (currentRowLastCell === undefined) {
        return
      }

      const newCell = {
        id: '',
        v: '',
        pos: '',
        label: '',
      }
      newCell.id = incrementLastPartOfId(currentRowLastCell.id)
      newCell.pos = this.newCell.pos
      newCell.v = this.newCell.text
      newCell.label = selectedLabel

      await this.$store.dispatch('batch/addNodeWithValue', newCell)
      await this.$store.dispatch('batch/setEditableNode', newCell)
      this.items[itemIndex][selectedLabel] = newCell
      this.closeCell()
    },
    closeCell() {
      this.newCell = {
        text: '',
        pos: '',
      }
      this.$store.dispatch('batch/setEditableNode', null)
      const inputCell = document.getElementById('cell-field')
      this.itemIndexG = -1
      if (inputCell) {
        document.getElementById('cell-field').autofocus = false
      }
    },
    async insertNewRow(rowIndex) {
      await this.$store.dispatch('batch/setEditableNode', this.items[rowIndex])
      this.items.forEach((item, index) => {
        if (index > rowIndex) {
          Object.keys(item).forEach(key => {
            const parts = item[key]?.id?.split('.')
            if (parts?.length === 6) {
              let secondLast = parseInt(parts[4], 10)
              // eslint-disable-next-line no-restricted-globals
              if (!isNaN(secondLast)) {
                secondLast += 1
                parts[4] = secondLast.toString().padStart(3, '0')
                // eslint-disable-next-line no-param-reassign
                item[key].id = parts.join('.')
              }
            }
          })
        }
      })
      function updateObject(obj) {
        const copyObj = JSON.parse(JSON.stringify(obj))
        Object.keys(copyObj).forEach(key => {
          const idParts = copyObj[key].id.split('.')
          idParts[idParts.length - 2] = (parseInt(idParts[idParts.length - 2], 10) + 1).toString().padStart(3, '0')
          // eslint-disable-next-line no-param-reassign
          copyObj[key].id = idParts.join('.')
          // eslint-disable-next-line no-param-reassign
          copyObj[key].v = ''
          // eslint-disable-next-line no-param-reassign
          copyObj[key].pos = ''
          // eslint-disable-next-line no-param-reassign
        })
        return copyObj
      }
      this.items.splice((rowIndex + 1), 0, updateObject(this.items[rowIndex]))
      await this.$store.dispatch('batch/addRow', this.items[rowIndex][Object.keys(this.items[rowIndex])[0]])
      await this.$store.commit('batch/TOGGLE_ADD_ROW', false)
      this.$store.commit('batch/CHECK_DELETE_ROW', true)
    },
    async removeExistingRow() {
      await this.$store.dispatch('batch/setEditableNode', this.items[this.selectedIndexForDelete])
      if (this.selectedIndexForDelete > -1 && this.isDeleteRow) {
        await this.$store.dispatch('batch/deleteRow', this.items[this.selectedIndexForDelete][Object.keys(this.items[this.selectedIndexForDelete])[0]])
        this.items.splice(this.selectedIndexForDelete, 1)
        await this.$store.commit('batch/TOGGLE_DELETE_ROW', false)
        if (this.items.length > 1) {
          this.$store.commit('batch/CHECK_DELETE_ROW', true)
        } else {
          this.$store.commit('batch/CHECK_DELETE_ROW', false)
        }
        this.selectedIndexForDelete = -1
      }
    },
    cancleRowDelete() {
      this.selectedIndexForDelete = -1
      if (this.items.length > 1) {
        this.$store.commit('batch/CHECK_DELETE_ROW', true)
      } else {
        this.$store.commit('batch/CHECK_DELETE_ROW', false)
      }
    },
    async rowAction(rowIndex) {
      if (this.isAddRow) {
        await this.showAddField(-1, -1)
        await this.insertNewRow(rowIndex)
      }
      if (this.isDeleteRow) {
        await this.showAddField(-1, -1)
        // await this.removeExistingRow(rowIndex)
        this.selectForDelete(rowIndex)
      }
    },
    selectForDelete(rowIndex) {
      this.selectedIndexForDelete = rowIndex
    },
  },
}
</script>
<!-- worksheet_name -->
<style scoped lang="scss">
.test-result-table {

    ::v-deep th, ::v-deep td {
        padding: 0.5rem;
    }
}
.table-column-config {
  text-transform: none;
}
.highlight-node {
  border: 1px solid red;
}
.hover-background-add {
  &:hover {
    background-color: rgba(115, 103, 240, 0.15)
  }
}
.hover-background-delete {
  &:hover {
    background-color: rgba(234, 84, 85, 0.15)
  }
}
.selected-background-delete {
    background-color: rgba(234, 84, 85, 0.15) !important
}
</style>
