<template>
  <div>
    <chip
      v-if="status"
      :variant="chipVariant"
    >
      {{ status }}
    </chip>

    <b-spinner
      v-if="refreshing"
      class="mx-50"
      small
    />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import Chip from '@/components/UI/Chip.vue'

export default {
  components: {
    BSpinner,
    Chip,
  },
  computed: {
    refreshing() {
      return this.$store.getters['batch/refreshing']
    },
    batchStatus() {
      return this.$store.getters['batch/status']
    },
    status() {
      return this.batchStatus?.status
    },
    chipVariant() {
      if (this.status === 'completed') {
        return 'success'
      }
      if (this.status === 'failed') {
        return 'danger'
      }
      return 'primary'
    },
  },
}
</script>
