<template>
  <div class="scroller-container">
    <div class="scroller">
      <div
        v-for="item of flatNodes"
        :key="item.id"
        :style="{
          'margin-left': (item.depth * 25) + 'px'
        }"
      >
        <BatchNode
          :id="item.id"
          :label="item.label"
          :title="item.title"
          :expandable="item.expandable"
          :expanded="item.expanded"
          :test="item.test"
          :highlighted="item.highlighted"
          :search-match="item.searchMatch"
          :type="item.type"
          :badge-variant="item.badgeVariant"
          :nested-label="item.nestedLabel"
          :key-id="item.keyId"
          :config-data="item.configData"
          :collapse-all-eligibility="collapseAllEligibility"
          :expand-all-eligibility="expandAllEligibility"
          @to-collapse-all="toCollapseAll"
          @to-expand-all="toExpandAll"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BatchNode from './BatchNode.vue'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

export default {
  components: {
    BatchNode,
  },
  computed: {
    flatNodes() {
      return this.$store.getters['batch/flatNodes']
    },
    collapseAllEligibility() {
      return this.flatNodes.some(element => element.expanded && !(element.type === 'root'))
    },
    expandAllEligibility() {
      return this.flatNodes.some(element => !element.expanded && element.type === 'document')
    },
  },
  methods: {
    toCollapseAll() {
      return this.flatNodes
        .filter(({ type }) => type !== 'root')
        .map(({ type, id, ...node }) => {
          this.$store.commit('batch/COLLAPSE_NODE', id)
          return { ...node, expanded: false }
        })
    },
    toExpandAll() {
      return this.flatNodes
        .filter(({ type }) => type !== 'root')
        .map(({ type, id, ...node }) => {
          this.$store.commit('batch/EXPAND_NODE', id)
          return { ...node, expanded: true }
        })
    },
  },
}
</script>

<style scoped>
.scroller-container {
    height:100%;
    position:relative;
}
.scroller {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
</style>
