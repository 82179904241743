<template>
  <div
    ref="imageViewer"
    class="image-viewer"
  >
    <b-alert
      variant="danger"
      :show="!loading && loadingError !== null ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
      />
    </div>
    <canvas-view
      v-if="!loading && !loadingError"
      class="canvas-view"
      :pages="pages"
      :highlighted-node="highlightedNode"
      :selectable-word-nodes="selectableWordNodes"
      :chunk-data="chunkData"
      :key-blocks="keyBlocks"
      :chunk-node-listening="chunkNodeListening"
      :atm-pattern-records="atmPatternRecords"
      :chunk-line-records="chunkLineRecords"
    />
  </div>
</template>

<script>
import Tiff from 'tiff.js'
import { BSpinner, BAlert } from 'bootstrap-vue'
import { getBatchMediaURL } from '@/store/batch/helper'
import CanvasView from './CanvasView.vue'

export default {
  components: { BSpinner, BAlert, CanvasView },
  data() {
    return {
      loading: false,
      loadingError: null,
      pages: [],
      isDocidChanged: false,
      cancelLoading: false,
      tempPages: [],
    }
  },
  computed: {
    node() {
      return this.$store.getters['batch/selectedNode']
    },
    page() {
      return this.$store.getters['batch/selectedPage']
    },
    pageList() {
      return this.$store.getters['batch/documentData'].pages
    },
    highlightedNode() {
      let highlightedNode = null

      if (this.page && this.node && this.node.highlight) {
        highlightedNode = { pageId: this.page.id, pos: this.node.pos }
      }
      return highlightedNode
    },
    displayChunkData() {
      return this.$store.getters['batch/view'] === 'chunk-data'
    },
    chunkNodeListening() {
      return this.$store.getters['dataView/mainMode'] === 'chunkData'
    },
    selectableWordNodes() {
      return this.$store.getters['batch/mode'] === 'edit' && !this.chunkNodeListening
    },
    chunkData() {
      if (!this.displayChunkData) {
        return null
      }
      return this.$store.getters['batch/chunkData']
    },
    keyBlocks() {
      return this.$store.getters['batch/selectedDocument']?.keyBlocks || []
    },
    chunkLineRecords() {
      return this.$store.getters['atm/chunkLineRecords']
    },
    atmPatternRecords() {
      return this.$store.getters['atm/atmPatternRecords']
    },
    selectedDocumentId() {
      return this.$store.getters['batch/selectedDocumentId']
    },
  },
  watch: {
    pageList: {
      deep: true,
      handler() {
        this.loadPages()
      },
    },
    selectedDocumentId: {
      deep: true,
      handler() {
        this.isDocidChanged = true
      },
    },
    // tempPages: {
    //   deep: true,
    //   handler(value) {
    //     for (let index = 0; index < value.length; index += 1) {
    //       const el = value[index]
    //       if (el === undefined || !el.image) {
    //         break
    //       }
    //       if (this.pages[index] === undefined) {
    //         this.pages.push(el)
    //       }
    //     }
    //   },
    // },
  },
  created() {
    this.loadPages()
  },
  beforeRouteLeave(to, from, next) {
    this.cancelLoading = true
    next()
  },
  methods: {
    async loadPages() {
      this.loading = true
      const batch = this.$store.getters['batch/batch']
      const { subPath } = batch
      this.pages = []

      const pages = Object.keys(this.pageList).map(pageId => {
        const page = this.pageList[pageId]
        const positionInfo = page.pos.split(',').map(num => +num)

        const fileName = page.imageFile
        const imageUrl = getBatchMediaURL(batch.id, subPath, fileName)

        return {
          id: pageId,
          width: positionInfo[2],
          height: positionInfo[3],
          imageUrl,
          image: null,
          wordNodes: page.wordNodes,
          styles: page.styles,
        }
      })
      this.tempPages[pages.length - 1] = undefined
      this.isDocidChanged = false

      const assignPages = async imageIndex => {
        try {
          const image = await this.fetchImage(pages[imageIndex].imageUrl)
          this.$emit('calculate-loaded-files', pages.length)

          pages[imageIndex].image = image

          if (this.pages.length === imageIndex) {
            this.pages.push(pages[imageIndex])
            let currentIndex = imageIndex + 1
            let nextPage
            if (currentIndex < pages.length) {
              nextPage = this.tempPages[currentIndex]
            }
            while (nextPage !== undefined) {
              this.pages.push(nextPage)
              currentIndex += 1
              if (currentIndex >= pages.length) {
                break
              }
              nextPage = this.tempPages[currentIndex]
            }
          } else {
            this.tempPages[imageIndex] = pages[imageIndex]
            if ((pages.length - 1) === imageIndex) {
              setTimeout(() => {
                for (let indexLocal = this.pages.length; indexLocal < this.tempPages.length; indexLocal += 1) {
                  if (this.tempPages[indexLocal] === undefined) {
                    break
                  }
                  this.pages.push(this.tempPages[indexLocal])
                }
              }, 1000)
            }
          }
        } catch (error) {
          this.loadingError = error.message
        }
        this.loading = false
      }

      let index = 0
      while (index < pages.length) {
        if (!this.isDocidChanged && !this.cancelLoading) {
          assignPages(index)
          index += 1
        } else {
          this.isDocidChanged = false
          this.loading = true
          this.$emit('calculate-loaded-files', 0)
          break
        }
      }
    },
    async fetchImage(imageUrl) {
      try {
        const response = await fetch(imageUrl)
        const buffer = await response.arrayBuffer()
        const tiff = new Tiff({ buffer })
        return tiff.toCanvas()
      } catch (error) {
        throw new Error('Error loading image')
      }
    },
  },
}
</script>

<style scoped>
.image-viewer {
    height: 100%;
    position: relative;
}
.canvas-view {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
</style>
