<template>
  <div class="chunk-data-container">
    <b-table-simple
      v-if="chunkData"
      class="custom-table chunks-table"
      hover
      striped
      bordered
    >
      <b-tbody>
        <b-tr
          v-for="(chunkLine, chunkLineIndex) of chunkLines"
          :key="chunkLineIndex"
        >
          <b-td
            v-for="columnNumber in numberOfColumns"
            :key="columnNumber"
          >
            <div
              v-if="chunkLine.chunks[columnNumber - 1]"
              :ref="`${chunkLine.chunks[columnNumber - 1]['pageId']}+${chunkLine.chunks[columnNumber - 1]['pos']}`"
              :class="{
                'highlight-cell': highlightChunkData === `${chunkLine.chunks[columnNumber - 1]['pageId']}+${chunkLine.chunks[columnNumber - 1]['pos']}`
              }"
              @click="onCellClick(chunkLine.chunks[columnNumber - 1])"
            >
              <div>{{ chunkLine.chunks[columnNumber - 1]['value'] }}</div>
              <div class="text-muted">
                {{ chunkLine.chunks[columnNumber - 1]['shape'] }}
              </div>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import bus from '@/bus'
import {
  BTableSimple, BTbody, BTd, BTr,
} from 'bootstrap-vue'
import { max } from 'lodash'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTd,
    BTr,
  },
  data() {
    return {
      highlightChunkData: null,
      highlightChunkDataTimer: null,
    }
  },
  computed: {
    chunkData() {
      return this.$store.getters['batch/chunkData']
    },
    chunkLines() {
      if (!this.chunkData) {
        return null
      }

      return this.chunkData.chunkLines || []
    },
    numberOfColumns() {
      const chunkItemCounts = this.chunkLines.map(chunkLine => chunkLine.chunks.length)
      return max(chunkItemCounts)
    },
  },
  created() {
    bus.$on('scrollToChunkCell', this.scrollToChunkCell)
  },
  destroyed() {
    bus.$off('scrollToChunkCell', this.scrollToChunkCell)
  },
  methods: {
    onCellClick(cellData) {
      bus.$emit('scrollToPos', {
        pos: cellData.pos,
        pageId: cellData.pageId,
      })
    },
    scrollToChunkCell({ pos, pageId }) {
      const refElement = this.$refs[`${pageId}+${pos}`]
      if (!refElement) {
        return
      }

      clearTimeout(this.highlightChunkDataTimer)
      this.highlightChunkData = `${pageId}+${pos}`
      this.$nextTick(() => {
        refElement[0].parentNode.scrollIntoView()
        this.highlightChunkDataTimer = setTimeout(() => {
          this.highlightChunkData = null
        }, 1500)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.chunk-data-container {
  ::v-deep .chunks-table td {
    white-space: nowrap;
  }
}
.highlight-cell {
  border: 2px solid red;
}
</style>
