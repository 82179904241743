<template>
  <b-modal
    v-model="showModal"
    title="Copy Definition Data"
    @ok="onSubmit"
    @hidden="$emit('modal-closed')"
  >
    <b-card-text>
      <b-form-group
        label="Copy:"
      >
        <b-form-checkbox
          v-model="copyKeySettings"
        >
          Key Settings
        </b-form-checkbox>

        <b-form-checkbox
          v-model="copyTableSettings"
        >
          Table Settings
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        label="From Version:"
      >
        <v-select
          v-model="fromVersion"
          transition=""
          :clearable="true"
          :options="fromDefinitionVersionOptions"
          :reduce="option => option.value"
        />
      </b-form-group>

      <b-form-group
        label="To Version:"
      >
        <v-select
          v-model="toVersion"
          transition=""
          :clearable="true"
          :options="toDefinitionVersionOptions"
          :reduce="option => option.value"
        />
      </b-form-group>
    </b-card-text>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="submitting || !enableSubmit"
        @click="ok()"
      >
        Copy Data
        <b-spinner
          v-if="submitting"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BModal, BSpinner, BCardText, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import bus from '@/bus'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BModal,
    BSpinner,
    BCardText,
    BFormCheckbox,
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      showModal: true,
      submitting: false,
      copyKeySettings: false,
      copyTableSettings: false,
      fromVersion: null,
      toVersion: null,
    }
  },
  computed: {
    templatesRoute() {
      return this.$route.name === 'template-batch'
    },
    definitionVersions() {
      return this.$store.getters['applicationSettings/definitionVersions']
    },
    definitionVersionOptions() {
      return this.definitionVersions.map(definitionVersion => ({
        label: definitionVersion.toUpperCase(),
        value: definitionVersion,
      }))
    },
    fromDefinitionVersionOptions() {
      return this.definitionVersionOptions.filter(option => option.value !== this.toVersion)
    },
    toDefinitionVersionOptions() {
      return this.definitionVersionOptions.filter(option => option.value !== this.fromVersion)
    },
    enableSubmit() {
      return (this.copyKeySettings || this.copyTableSettings) && this.fromVersion && this.toVersion
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.submitting = true
      const definition = this.$store.getters['dataView/selectedDefinition']
      const { definitionId } = this.$store.getters['batch/batch']
      let tamplateName = null
      if (this.templatesRoute) {
        tamplateName = definitionId
      }
      axios.post('/copy_definition_data/', null, {
        params: {
          id: definition.id,
          key: this.copyKeySettings,
          table: this.copyTableSettings,
          from_version: this.fromVersion,
          to_version: this.toVersion,
          template: tamplateName,
        },
      }).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.detail,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        const selectedDefinitionVersion = this.$store.getters['dataView/selectedDefinitionVersion']
        if (this.toVersion === selectedDefinitionVersion) {
          bus.$emit('dataView/refreshData')
        }

        this.submitting = false
        this.showModal = false
      }).catch(error => {
        const message = error?.response?.data?.detail || 'Error copying definition data'
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.submitting = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
