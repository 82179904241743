<template>
  <b-form>
    <b-input-group>
      <b-input-group-prepend>
        <b-button
          :variant="buttonVariant"
          :disabled="mode === 'test'"
          style="opacity: 1"
          @click="onAdd"
        >
          <feather-icon
            v-if="mode !== 'test'"
            icon="PlusIcon"
            size="12"
          />
          <span>{{ label }}</span>
        </b-button>
      </b-input-group-prepend>
      <b-form-input
        v-if="onCreate || onUpdate"
        v-model="tableName"
        type="text"
        placeholder="table name"
        :autofocus="true"
        class="mr-7px"
        @keydown.enter.prevent="onPressEnter"
      />
      <feather-icon
        v-if="onCreate"
        v-b-tooltip.hover
        title="Create Table"
        icon="SaveIcon"
        size="16"
        class="my-auto mr-5px cursor-pointer"
        @click="onCreateTable"
      />
      <div
        v-if="!onCreate && !onUpdate"
        class="table-selector-wrapper mr-7px"
      >
        <TableSelector />
      </div>
      <feather-icon
        v-if="!onCreate && !onUpdate && mode !== 'test'"
        v-b-tooltip.hover
        title="Rename Table"
        icon="EditIcon"
        size="16"
        class="my-auto mr-5px cursor-pointer"
        @click="onEdit"
      />
      <feather-icon
        v-if="!onCreate && !onUpdate && table.length > 1 && mode !== 'test'"
        v-b-tooltip.hover
        title="Delete Table"
        icon="TrashIcon"
        size="16"
        class="my-auto cursor-pointer"
        @click="onDelete"
      />
      <feather-icon
        v-if="onUpdate && tableName !== table[selectedTableId].table_name"
        v-b-tooltip.hover
        title="Save Table"
        icon="SaveIcon"
        size="16"
        class="my-auto mr-5px cursor-pointer"
        @click="onRename"
      />
      <feather-icon
        v-if="onCreate || onUpdate"
        v-b-tooltip.hover
        title="Close"
        icon="DeleteIcon"
        size="16"
        class="my-auto ml-5px cursor-pointer"
        @click="onClose"
      />
    </b-input-group>
  </b-form>
</template>

<script>
import {
  BInputGroup, BInputGroupPrepend, BButton, BFormInput, BForm, VBTooltip,
} from 'bootstrap-vue'
import TableSelector from '@/components/UI/TableSelector.vue'
import bus from '@/bus'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BFormInput,
    BForm,
    TableSelector,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    buttonVariant: {
      type: String,
      required: false,
      default() {
        return 'primary'
      },
    },
  },
  data() {
    return {
      tableName: '',
      onCreate: false,
      onUpdate: false,
    }
  },
  computed: {
    table() {
      return this.$store.getters['dataView/table']
    },
    selectedTableId() {
      return this.$store.getters['dataView/selectedTableId']
    },
    mode() {
      return this.$store.getters['dataView/mode']
    },
  },
  created() {
    bus.$on('dataView/deleteTable', this.onDelete)
  },
  destroyed() {
    bus.$off('dataView/deleteTable', this.onDelete)
    this.onCreate = false
    this.onUpdate = false
  },
  methods: {
    onAdd() {
      this.tableName = `Main_${this.tableNamePostfix()}`
      this.onUpdate = false
      this.onCreate = true
    },
    onCreateTable() {
      if (!this.isUnique()) {
        return
      }

      const data = {
        tableId: this.table.length,
        tableName: this.tableName,
        selectedTableId: this.selectedTableId,
      }

      this.onCreate = false

      this.$emit('add', data)
    },
    onEdit() {
      this.onCreate = false
      this.onUpdate = true
      this.tableName = this.table[this.selectedTableId].table_name
    },
    onRename() {
      if (!this.isUnique()) return

      this.$store.dispatch('dataView/renameTable', {
        tableName: this.tableName,
        tableId: this.selectedTableId,
      })

      bus.$emit('dataView/saveTableData')

      this.onUpdate = false
    },
    onDelete(data) {
      const { tableId, selectedTableId } = data

      this.$store.dispatch('dataView/deleteTable', {
        tableId: tableId || this.selectedTableId,
      })

      this.$store.commit('dataView/SET_SELECTED_TABLE_ID', selectedTableId || 0)

      bus.$emit('dataView/saveTableData')
    },
    onClose() {
      this.onUpdate = false

      this.onCreate = false
    },
    onPressEnter() {
      if (this.onCreate) {
        this.onCreateTable()
      } else if (this.onUpdate) {
        if (this.tableName !== this.table[this.selectedTableId].table_name) {
          this.onRename()
        }
      }
    },
    tableNamePostfix() {
      let tableNamePostfix = -1

      this.table.forEach(item => {
        const splitedTableName = item.table_name.split('_')

        if (splitedTableName[0] === 'Main') {
          if (splitedTableName.length === 2) {
            const currentTablePostfix = parseInt(splitedTableName[1], 10)

            if (!Number.isInteger(currentTablePostfix)) return

            if (currentTablePostfix > tableNamePostfix) {
              tableNamePostfix = currentTablePostfix
            }
          }
        }
      })

      return tableNamePostfix + 1
    },
    isUnique() {
      const unique = this.table.every(item => this.tableName !== item.table_name)

      if (!unique) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Duplicate Table Name',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }

      return unique
    },
  },
}
</script>

<style scoped>
.btn {
  padding: 0.75rem !important;
  font-size: 12px;
}

.input-group:focus-within {
  box-shadow: none;
}

.form-control {
  height: 35px;
}

.table-selector-wrapper {
  width: 150px;
  height: 35px;
  font-size: 12px;
}

.mr-5px {
  margin-right: 5px !important;
}

.mr-7px {
  margin-right: 7px !important;
}

.ml-5px {
  margin-left: 5px !important;
}

.font-12px {
  font-size: 12px;
}
</style>
