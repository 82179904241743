<template>
  <b-badge
    v-if="batchDefintionVersion"
    v-b-tooltip.hover
    :title="tooltipText"
    variant="primary"
  >
    {{ batchDefintionVersion }}
  </b-badge>
</template>

<script>
import { BBadge, VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BBadge,
  },
  computed: {
    batchDefintionVersion() {
      return this.$store.getters['batch/batch'].definitionVersion?.toUpperCase() || null
    },
    tooltipText() {
      return `Batch tested with ${this.batchDefintionVersion} version of definition`
    },
  },
}
</script>
