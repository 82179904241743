<template>
  <div class="d-flex align-items-center defined-key-actions">
    <div
      class="add-keys"
    >
      <add-item
        label="Key"
        button-variant="outline-primary"
        @add="add"
      />
    </div>

    <b-button
      variant="outline-primary"
      :disabled="saving"
      @click="save"
    >
      Save
      <b-spinner
        v-if="saving"
        small
        label="Small Spinner"
      />
    </b-button>
  </div>
</template>

<script>
import AddItem from '@/components/UI/AddItem.vue'
import bus from '@/bus'
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    AddItem,
    BButton,
    BSpinner,
  },
  data() {
    return {
      saving: false,
    }
  },
  methods: {
    add(count) {
      bus.$emit('dataView/addDefinedKeys', count)
    },
    save() {
      this.saving = true
      bus.$emit('dataView/saveDefinedKeys', () => {
        this.saving = false
      })
    },
  },
}
</script>

<style scoped>
.defined-key-actions {
  column-gap: 1rem;
}
.add-keys {
  width: 220px;
  display: inline-block;
}
</style>
