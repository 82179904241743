<template>
  <div
    ref="excelViewer"
    class="excel-viewer"
  >
    <b-alert
      variant="danger"
      :show="!loading && loadingError !== null ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
      />
    </div>

    <gc-spread-sheets
      v-show="!loading && loadingError === null"
      class="spreadsheets"
      host-class="spreadHost"
      @workbookInitialized="initSpread"
    >
      <gc-worksheet />
    </gc-spread-sheets>
  </div>
</template>

<script>
import Vue from 'vue'

// eslint-disable-next-line no-unused-vars
import { GcSpreadSheets, GcWorksheet, GcColumn } from '@grapecity/spread-sheets-vue'
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css'
import * as GC from '@grapecity/spread-sheets'
import * as ExcelIO from '@grapecity/spread-excelio'
import { BSpinner, BAlert } from 'bootstrap-vue'

import getEnv from '@/utils/env'
import { getBatchMediaURL } from '@/store/batch/helper'
import bus from '@/bus'

const spreadJSLicenseKey = getEnv('VUE_APP_GRAPECITY_SPREADJS_LICENSE')
GC.Spread.Sheets.LicenseKey = spreadJSLicenseKey
ExcelIO.LicenseKey = spreadJSLicenseKey
const spreadNS = GC.Spread.Sheets

const localBus = new Vue()
const resizeOb = new ResizeObserver(() => {
  localBus.$emit('containerSizeChanged')
})

export default {
  components: {
    GcSpreadSheets,
    GcWorksheet,
    BSpinner,
    BAlert,
  },
  data() {
    return {
      spread: null,
      excelFile: null,
      selectedCellDetails: null,
      loading: true,
      loadingError: null,
      licenseStatus: null,
    }
  },
  computed: {
    excelData() {
      return this.$store.getters['batch/documentData'].excelData
    },
    selectedNode() {
      return this.$store.getters['batch/selectedNode']
    },
  },
  watch: {
    excelData: {
      deep: true,
      handler() {
        this.loadFile()
      },
    },
    selectedCellDetails: {
      handler() {
        this.$store.commit('batch/SET_SELECTED_CELL_DETAILS', this.selectedCellDetails)
      },
      deep: true,
    },
  },
  created() {
    localBus.$on('containerSizeChanged', this.onContainerSizeChange)
    bus.$on('goToSelectedNodeCell', this.goToSelectedNodeCell)
    bus.$on('excelViewer/goToCell', this.goToCellEventHandler)
  },
  mounted() {
    resizeOb.observe(this.$refs.excelViewer)
  },
  beforeDestroy() {
    resizeOb.unobserve(this.$refs.excelViewer)
  },
  destroyed() {
    localBus.$off('containerSizeChanged', this.onContainerSizeChange)
    bus.$off('goToSelectedNodeCell', this.goToSelectedNodeCell)
    bus.$off('excelViewer/goToCell', this.goToCellEventHandler)
  },
  methods: {
    initSpread(spread) {
      this.spread = spread

      this.syncLicenseStatus()

      if (this.licenseStatus !== 'valid') {
        this.loading = false
        return
      }

      // eslint-disable-next-line no-param-reassign
      spread.options.calcOnDemand = true

      spread.suspendPaint()

      spread.bind(spreadNS.Events.SelectionChanged, () => {
        this.updateSelectedCellDetails()
      })
      spread.bind(spreadNS.Events.ActiveSheetChanged, () => {
        this.updateSelectedCellDetails()
        this.setSheetOptions()
      })

      spread.resumePaint()

      this.loadFile()
    },
    updateSelectedCellDetails() {
      const activeSheet = this.spread.getActiveSheet()
      const ranges = activeSheet.getSelections()
      const rangesAddress = spreadNS.CalcEngine.rangesToFormula(ranges, 0, 0, spreadNS.CalcEngine.RangeReferenceRelative.allRelative, false)

      this.selectedCellDetails = {
        sheetName: activeSheet.name(),
        cellRange: rangesAddress,
      }
    },
    async loadFile() {
      if (this.licenseStatus !== 'valid') {
        return
      }

      if (!this.excelData) {
        return
      }

      this.loading = true

      let response
      try {
        response = await this.fetchFile()
      } catch {
        this.loadingError = 'Error fetching excel file'
        this.loading = false
        return
      }

      const fileBlob = await response.blob()
      const file = new File([fileBlob], 'Sample.xlsx') // convert the blob into file
      await this.loadExcelDataFromFile(file)

      this.setSpreadOptions()
      this.setSheetOptions()
      this.updateSelectedCellDetails()

      this.loading = false
      this.loadingError = null
    },
    async fetchFile() {
      const batch = this.$store.getters['batch/batch']
      const { subPath } = batch
      const { fileName } = this.excelData
      const fileUrl = getBatchMediaURL(batch.id, subPath, fileName)
      return fetch(`${fileUrl}`)
    },
    loadExcelDataFromFile(excelFile) {
      return new Promise(resolve => {
        const excelIo = new ExcelIO.IO()
        excelIo.open(excelFile, json => {
          const workbookObj = json
          this.spread.fromJSON(workbookObj)
          resolve()
        })
      })
    },
    setSpreadOptions() {
      const { spread } = this
      spread.options.allowContextMenu = false
      spread.options.tabEditable = false
      spread.options.allowSheetReorder = false
      spread.options.newTabVisible = false
    },
    setSheetOptions() {
      const sheet = this.spread.getActiveSheet()
      sheet.options.protectionOptions = {
        allowSelectLockedCells: true,
        allowSelectUnlockedCells: true,
        allowSort: true,
        allowFilter: true,
        allowResizeRows: true,
        allowResizeColumns: true,
        allowEditObjects: false,
        allowDragInsertRows: false,
        allowDragInsertColumns: false,
        allowInsertRows: false,
        allowInsertColumns: false,
        allowDeleteRows: false,
        allowDeleteColumns: false,
        allowOutlineColumns: true,
        allowOutlineRows: true,
      }
      sheet.options.isProtected = true
      sheet.selectionPolicy(GC.Spread.Sheets.SelectionPolicy.range)
    },
    onContainerSizeChange() {
      this.spread.refresh()
    },
    syncLicenseStatus() {
      // Custom logic to detect spreadjs license error, not provided officially
      const activeSheet = this.spread.getActiveSheet()
      this.licenseStatus = activeSheet ? 'valid' : 'invalid'
    },
    goToSelectedNodeCell() {
      const { selectedNode } = this

      if (selectedNode && selectedNode.highlight) {
        this.goToCell(selectedNode.sheetName, selectedNode.cellRange)
      }
    },
    goToCellEventHandler(data) {
      this.goToCell(data.sheetName, data.cellRange)
    },
    goToCell(sheetName, cellRange) {
      this.spread.setActiveSheet(sheetName)

      const activeSheet = this.spread.getActiveSheet()
      const ranges = spreadNS.CalcEngine.formulaToRanges(activeSheet, cellRange, 0, 0)
      const range = ranges[0].ranges[0]
      activeSheet.setSelection(range.row, range.col, range.rowCount, range.colCount)
      activeSheet.showCell(range.row, range.col, spreadNS.VerticalPosition.top, spreadNS.HorizontalPosition.left)

      this.updateSelectedCellDetails()
    },
  },
}
</script>

<style scoped>
.excel-viewer {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.spreadsheets {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
</style>
