<template>
  <div class="h-100">
    <b-table
      ref="table"
      sticky-header="100%"
      :items="items"
      :fields="tableColumns"
      class="h-100 mb-0"
    >
      <template #cell(actions)="data">
        <feather-icon
          v-b-tooltip.hover
          title="Delete Item"
          icon="Trash2Icon"
          class="cursor-pointer mx-auto"
          size="20"
          @click.stop="deleteItem(data.index)"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { VBTooltip, BTable } from 'bootstrap-vue'
import bus from '@/bus'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BTable,
  },
  data() {
    return {
      tableColumns: [
        { key: 'nestedLabel', label: 'Nested Label' },
        { key: 'keyId', label: 'Key ID' },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  computed: {
    items: {
      get() {
        return this.$store.getters['dataView/keyNotInUseItems']
      },
      set(value) {
        this.$store.commit('dataView/SET_KEY_NOT_IN_USE_ITEMS', value)
      },
    },
  },
  created() {
    bus.$on('addNotInUseItem', this.additem)
  },
  destroyed() {
    bus.$off('addNotInUseItem', this.additem)
  },
  methods: {
    additem(newItem) {
      let index
      const itemIndex = this.items.findIndex(item => item.nestedLabel === newItem.nestedLabel && item.keyId === newItem.keyId)
      if (itemIndex === -1) {
        this.items.push(newItem)
        index = this.items.length - 1
      } else {
        index = itemIndex
      }
      this.$nextTick(() => {
        this.scrollToIndex(index)
      })
    },
    deleteItem(index) {
      this.items.splice(index, 1)
    },
    scrollToIndex(index) {
      const table = this.$refs.table.$el
      const tbody = table.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[index]
      const thead = table.querySelector('thead')
      table.scrollTop = row.offsetTop - (thead.offsetHeight + 10)
    },
  },
}
</script>
