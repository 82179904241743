<template>
  <div
    class="d-flex justify-content-end align-items-center wrapper"
    style="position: relative;"
  >
    <template v-if="isPageLoading">
      <div
        class="d-flex align-items-center flex-grow-1 doc-load"
      >
        <span
          class="font-weight-bold"
        >
          Loaded Pages: {{ loadedFiles }} / {{ totalFiles }}
        </span>
      </div>
    </template>
    <div class="d-flex wrapper flex-grow-1 font-0-95">
      <div
        v-if="false"
        class="definition-selector-wrapper"
      >
        <definition-selector />
      </div>
      <div
        v-if="false"
        class="definition-type-selector-wrapper"
      >
        <definition-type-selector />
      </div>
      <div
        v-if="imageViewerAreaContent === 'image-viewer'"
        class="batch-selector-wrapper"
      >
        <batch-selector />
      </div>
      <div class="document-selector-wrapper">
        <document-selector />
      </div>
      <div class="definition-version-selector-wrapper">
        <definition-version-selector />
      </div>
    </div>

    <template v-if="imageViewerAreaContent === 'query-results'">
      <div class="query-result-selector font-0-95">
        <query-result-selector />
      </div>
      <div class="query-result-search font-0-95">
        <query-result-search />
      </div>
    </template>
    <template v-if="lookupInitialized && imageViewerAreaContent === 'query-results'">
      <div>
        <download-data />
      </div>
      <div>
        <import-data />
      </div>
      <div>
        <add-record />
      </div>
    </template>

    <div v-if="!templatesRoute && mainMode !== 'verification' && imageViewerAreaContent !== 'query-results'">
      <create-template />
    </div>
    <div v-if="mainMode !== 'verification' && imageViewerAreaContent !== 'query-results'">
      <copy-definition-data />
    </div>
    <!-- <div>
        <save-config />
      </div> -->
    <!-- <div>
        <highlight-root-nodes />
      </div> -->

    <template v-if="imageViewerAreaContent === 'image-viewer'">
      <div>
        <zoom-in />
      </div>
      <div>
        <zoom-out />
      </div>
      <div v-if="view !== 'analyzer'">
        <fit-to-width />
      </div>
      <div v-if="mainMode !== 'verification'">
        <selector />
      </div>
    </template>

    <div
      v-if="view !== 'analyzer'"
      class="view-selector-wrapper"
    >
      <view-selector :image-viewer-area-content="imageViewerAreaContent" />
    </div>
    <!-- <template v-if="view === 'analyzer'">
      <div v-if="!isExcelBatch">
        <highlight-key-blocks />
      </div>
      <div v-if="!isExcelBatch">
        <measure />
      </div>
      <div>
        <download-docbuilder-payload />
      </div>
    </template> -->
    <div v-if="mainMode !== 'verification'">
      <timeline
        :batch-id="batchId"
        placement="bottom"
        icon-size="20"
        auto-initialize
      />
    </div>
    <!-- <div class="mode-selector-wrapper">
      <mode-selector />
    </div> -->
    <div v-if="displayKeyAnchors">
      <clear-anchor-highlights />
    </div>
    <div v-if="mainMode !== 'verification'">
      <batch-status />
    </div>

    <div v-if="mainMode === 'verification' && verificationStatus === 'ready' && manualValidation">
      <verification-actions />
    </div>
    <!-- <div class="flex-grow-1" /> -->
    <!-- <div>
      <submit-definition-data />
    </div> -->
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
// import SaveConfig from './SaveConfig.vue'
// import HighlightRootNodes from './HighlightRootNodes.vue'
import ZoomIn from '@/components/Batch/ImageViewToolbar/ZoomIn.vue'
import ZoomOut from '@/components/Batch/ImageViewToolbar/ZoomOut.vue'
import Selector from '@/components/Batch/ImageViewToolbar/Selector.vue'
// import Measure from './Measure.vue'
import FitToWidth from '@/components/Batch/ImageViewToolbar/FitToWidth.vue'
import ViewSelector from '@/components/Batch/ImageViewToolbar/ViewSelector.vue'
import VerificationActions from '@/components/Batch/ImageViewToolbar/VerificationActions.vue'
// import ModeSelector from './ModeSelector.vue'
import Timeline from '@/components/UI/Timeline/Timeline.vue'
import BatchStatus from '@/components/Batch/ImageViewToolbar/BatchStatus.vue'
// import SubmitDefinitionData from './SubmitDefinitionData.vue'
// import HighlightKeyBlocks from './HighlightKeyBlocks.vue'
import ClearAnchorHighlights from '@/components/Batch/ImageViewToolbar/ClearAnchorHighlights.vue'
// import DownloadDocbuilderPayload from './DownloadDocbuilderPayload.vue'
import CopyDefinitionData from '@/components/Batch/ImageViewToolbar/CopyDefinitionData/CopyDefinitionData.vue'
import CreateTemplate from '@/components/Batch/ImageViewToolbar/CreateTemplate/CreateTemplate.vue'

import ImportData from '@/components/Batch/ImageViewToolbar/Lookup/ImportDataOption.vue'
import DownloadData from '@/components/Batch/ImageViewToolbar/Lookup/DownloadDataOption.vue'
import AddRecord from '@/components/Batch/ImageViewToolbar/Lookup/AddRecordOption.vue'

import DefinitionSelector from '@/components/Batch/SelectorToolbar/DefinitionSelector.vue'
import DefinitionTypeSelector from '@/components/Batch/SelectorToolbar/DefinitionTypeSelector.vue'
import BatchSelector from '@/components/Batch/SelectorToolbar/BatchSelector.vue'
import DocumentSelector from '@/components/Batch/SelectorToolbar/DocumentSelector.vue'
import DefinitionVersionSelector from '@/components/Batch/SelectorToolbar/DefinitionVersionSelector.vue'

import QueryResultSelector from '@/components/Batch/SelectorToolbar/Lookup/QueryResultSelector.vue'
import QueryResultSearch from '@/components/Batch/SelectorToolbar/Lookup/QueryResultSearch.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // SaveConfig,
    ZoomIn,
    ZoomOut,
    Selector,
    FitToWidth,
    ViewSelector,
    VerificationActions,
    // ModeSelector,
    Timeline,
    BatchStatus,
    // SubmitDefinitionData,
    // HighlightKeyBlocks,
    // Measure,
    ClearAnchorHighlights,
    // DownloadDocbuilderPayload,
    CopyDefinitionData,
    ImportData,
    DownloadData,
    AddRecord,
    DefinitionSelector,
    DefinitionTypeSelector,
    BatchSelector,
    DocumentSelector,
    DefinitionVersionSelector,
    QueryResultSelector,
    QueryResultSearch,
    CreateTemplate,
  },
  props: {
    imageViewerAreaContent: {
      type: String,
      required: true,
    },
    loadedFiles: {
      type: Number,
      default: 0,
    },
    totalFiles: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    templatesRoute() {
      return this.$route.name === 'template-batch'
    },
    batchId() {
      return this.$store.getters['batch/batch'].id
    },
    manualValidation() {
      return this.$store.getters['batch/manualValidation']
    },
    verificationStatus() {
      return this.$store.getters['batch/verificationStatus']
    },
    mainMode() {
      return this.$store.getters['dataView/mainMode']
    },
    view() {
      return this.$store.getters['batch/view']
    },
    displayKeyAnchors() {
      return this.$store.getters['batch/displayKeyAnchors']
    },
    dataViewMode() {
      return this.$store.getters['dataView/mode']
    },
    lookupInitialized() {
      return this.$store.getters['lookup/initialized']
    },
    isExcelBatch() {
      return this.$store.getters['batch/batch'].isExcel
    },
    isPageLoading() {
      return this.totalFiles && this.loadedFiles < this.totalFiles
    },
  },
}
</script>

<style scoped lang="scss">
.font-0-95 {
  font-size: 0.90rem !important;
  input {
    font-size: 0.90rem !important;
    height: 2.5rem;
  }
}
.wrapper {
  column-gap: .65rem !important;
}
.mode-selector-wrapper {
  flex-basis: 149px;
}
.mode-selector-wrapper {
  flex-basis: 149px;
}
.definition-selector-wrapper {
  flex-basis: 178px;
}
.definition-type-selector-wrapper, .batch-selector-wrapper {
  min-width: 187px;
}
.document-selector-wrapper {
  min-width: 186px;
}
.definition-version-selector-wrapper {
  min-width: 89px;
}
.query-result-selector {
  min-width: 162px;
}
.query-result-search {
  min-width: 155px;
}
.doc-load {
  position: absolute;
  top: 4rem;
  left: 10px;
  z-index: 2;
  font-weight: 700;
}
</style>
