<template>
  <div class="h-100">
    <div class="key-items-container d-flex flex-column h-100">
      <div>
        <div class="heading-row d-flex">
          <div class="regular-column flex-grow-1">
            <span
              v-b-tooltip.hover
              class="text-capitalize"
              title="Refer to the Yellow Book iCap Reference Files to check mapping to CW1 Fields"
            >
              Key Field Name
            </span>
          </div>
          <div class="regular-column flex-grow-1">
            <span
              v-b-tooltip.hover
              class="text-capitalize"
              title="Becomes available only for FieldNames with multiple Options. Choose, when Activated"
            >
              Qualifier
            </span>
          </div>
          <div class="regular-column flex-grow-1">
            <span
              v-b-tooltip.hover
              class="text-capitalize"
              title=""
            >
              Type
            </span>
          </div>
          <div class="regular-column flex-grow-1">
            <span
              v-b-tooltip.hover
              class="text-capitalize"
              title="Mark Label against which value of the Key Point Pair can be found"
            >
              Label
            </span>
          </div>
          <div class="action-column" />
        </div>
      </div>
      <div
        ref="keyItemsWrapper"
        class="flex-grow-1 key-items-wrapper"
      >
        <validation-observer
          ref="keyItemsForm"
          mode="eager"
        >
          <draggable
            v-model="keys"
            tag="div"
            handle=".handle"
            v-bind="dragOptions"
          >
            <key-item
              v-for="(keyItem, keyItemIndex) of keys"
              :key="keyItem.id"
              v-model="keys[keyItemIndex]"
              class="key-item-main"
              :auto-expand-input-section-fields="autoExpandInputSectionFields"
              @deleteItem="deleteKey(keyItemIndex, keyItem.id)"
              @highlightAnchors="onHightlightAnchors(keyItem.id, $event)"
              @highlightAnchorsCompoundItem="onHightlightAnchors($event.keyItemId, $event.data)"
              @dropdownOpen="scrollToIndex(keyItemIndex)"
              @dropdownOpenCompoundItem="scrollToCompoundItem"
            />
          </draggable>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { ValidationObserver } from 'vee-validate'

import { isEqual, cloneDeep } from 'lodash'
import bus from '@/bus'
import KeyItem from './KeyItem.vue'

import { getDefaultKeys, normalizeKeyItemValues } from './key-helper'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    KeyItem,
    draggable,
    ValidationObserver,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      keys: [],
      autoExpandInputSectionFields: false,
    }
  },
  computed: {
    out() {
      return cloneDeep(this.keys)
    },
    dragOptions() {
      return {
        animation: 0,
        ghostClass: 'draggable-ghost',
      }
    },
    isExcelBatch() {
      return this.$store.getters['batch/batch'].isExcel
    },
    optionsKeyItems() {
      return this.$store.getters['definitionSettings/options']['options-keys'].items
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
    bus.$on('dataView/addKeys', this.addKeys)
    bus.$on('validateKeyItems', this.validateKeyItems)

    this.$nextTick(() => {
      this.autoExpandInputSectionFields = true
    })
  },
  destroyed() {
    bus.$off('dataView/addKeys', this.addKeys)
    bus.$off('validateKeyItems', this.validateKeyItems)
  },
  methods: {
    setInternalState() {
      this.keys = normalizeKeyItemValues(this.value, this.optionsKeyItems)
    },
    addKeys(count) {
      const lastRowIndex = this.keys.length - 1
      this.keys = this.keys.concat(getDefaultKeys(count, this.isExcelBatch))
      this.$nextTick(() => {
        this.scrollToIndex(lastRowIndex + 1)
      })
    },
    scrollToIndex(index) {
      const { keyItemsWrapper } = this.$refs
      const keyModelItem = keyItemsWrapper.querySelectorAll('.key-item-main')[index]
      keyItemsWrapper.scrollTop = keyModelItem.offsetTop - 8
    },
    scrollToCompoundItem(id) {
      const { keyItemsWrapper } = this.$refs
      const keyModelItem = keyItemsWrapper.querySelector(`#key-item-${id}`)
      keyItemsWrapper.scrollTop = keyModelItem.offsetTop - 8
    },
    deleteKey(index, keyItemId) {
      if (keyItemId === this.$store.getters['batch/highlightKeyAnchorsData'].keyItemId) {
        this.$store.dispatch('batch/clearAnchorHighlights')
      }
      this.keys.splice(index, 1)
    },
    onHightlightAnchors(keyItemId, selectedAnchor) {
      this.$store.commit('batch/SET_HIGHLIGHT_KEY_ANCHORS_DATA', { keyItemId, selectedAnchor, source: this.source })
    },
    validateKeyItems(callback) {
      this.$refs.keyItemsForm.validate().then(success => {
        callback(success)
      })
    },
  },
}
</script>

<style scoped>
.key-items-container {
  overflow: hidden;
}

.heading-row {
  padding: 0.4rem;
  background-color: #f3f2f7;
  border-bottom: 2px solid #ebe9f1;
  column-gap: 10px;
}

.heading-row span {
  font-weight: bold;
  color: #6e6b7b;
  font-size: .857rem;
}

.key-items-wrapper {
  overflow: auto;
  position:relative;
}

.key-items-wrapper ::v-deep.key-item-main:last-child .key-item-separator {
  display: none;
}

.regular-column {
  flex-basis:300px;
}

.action-column {
  flex-basis:90px;
}
</style>
