<template>
  <b-modal
    v-model="showModal"
    title="Copy Rules"
    @ok="onSubmit"
    @hidden="$emit('modal-closed')"
  >
    <b-card-text>
      <b-form-group
        label="From rule item:"
      >
        <v-select
          v-model="fromRuleItemIndex"
          transition=""
          :clearable="false"
          :options="ruleItemOptions"
          :reduce="option => option.value"
        />
      </b-form-group>
    </b-card-text>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="!enableSubmit"
        @click="ok()"
      >
        Submit
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BModal, BCardText, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import bus from '@/bus'

export default {
  components: {
    BButton,
    BModal,
    BCardText,
    vSelect,
    BFormGroup,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      fromRuleItemIndex: null,
    }
  },
  computed: {
    definition() {
      return this.$store.getters['dataView/selectedDefinition']
    },
    keyRuleItems() {
      return this.definition.key.ruleItems
    },
    tableRuleItems() {
      return this.definition.table.flatMap(table => table?.table_definition_data.ruleItems)
    },
    ruleItemOptions() {
      let options = []
      let editIndex
      if (this.mode === 'key') {
        editIndex = this.$store.getters['dataView/keyRuleItemEditIndex']

        options = this.keyRuleItems.map(((ruleItem, index) => ({
          value: index,
          label: ruleItem.keyId ? `${ruleItem.id} - ${ruleItem.keyId}` : ruleItem.id,
        })))
      } else {
        editIndex = this.$store.getters['dataView/tableRuleItemEditIndex']
        options = this.tableRuleItems.map(((ruleItem, index) => ({
          value: index,
          label: ruleItem.label,
        })))
      }

      if (options.length > 0 && editIndex !== -1) {
        options.splice(editIndex, 1)
      }
      return options
    },
    enableSubmit() {
      return this.fromRuleItemIndex !== null
    },
    keyRuleItemRules: {
      get() {
        return this.$store.getters['dataView/keyRuleItemRules']
      },
      set(value) {
        this.$store.commit('dataView/SET_KEY_RULE_ITEM_RULES', value)
      },
    },
    tableRuleItemRules: {
      get() {
        return this.$store.getters['dataView/tableRuleItemRules']
      },
      set(value) {
        this.$store.commit('dataView/SET_TABLE_RULE_ITEM_RULES', value)
      },
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()

      if (this.mode === 'key') {
        const lastRowIndex = this.keyRuleItemRules.length - 1
        const newRules = this.keyRuleItems[this.fromRuleItemIndex].rules
        if (newRules.length > 0) {
          this.keyRuleItemRules = this.keyRuleItemRules.concat(newRules)

          this.$nextTick(() => {
            bus.$emit('dataView/keyRules/scrollToIndex', lastRowIndex + 1)
          })
        }
      } else {
        const lastRowIndex = this.tableRuleItemRules.length - 1
        const newRules = this.tableRuleItems[this.fromRuleItemIndex].rules
        if (newRules.length > 0) {
          this.tableRuleItemRules = this.tableRuleItemRules.concat(newRules)

          this.$nextTick(() => {
            bus.$emit('dataView/tableRules/scrollToIndex', lastRowIndex + 1)
          })
        }
      }

      this.showModal = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
