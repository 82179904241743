<template>
  <div class="d-flex align-items-center wrapper">
    <b-button
      variant="outline-primary"
      :disabled="verifying"
      @click="verify"
    >
      Verify
      <b-spinner
        v-if="verifying"
        small
        label="Small Spinner"
      />
    </b-button>

    <b-button
      :variant="submitting || !verified ? 'secondary' : 'primary'"
      :disabled="submitting || !verified"
      @click="showModal = true"
    >
      Submit
      <b-spinner
        v-if="submitting"
        small
        label="Small Spinner"
      />
    </b-button>

    <!-- Warning Modal -->
    <b-modal
      v-model="showModal"
      centered
      title="Submit Transaction Data"
      @ok="submit"
    >
      <b-card-text>
        Are you sure you want to submit transaction data to CW1 ?
      </b-card-text>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="submitting"
          @click="ok()"
        >
          Submit
          <b-spinner
            v-if="submitting"
            small
            label="Small Spinner"
          />
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BSpinner, BModal, BCardText,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BSpinner,
    BModal,
    BCardText,
  },
  data() {
    return {
      verifying: false,
      verified: false,
      showModal: false,
      submitting: false,
    }
  },
  computed: {
    verificationDetails() {
      return this.$store.getters['batch/verificationDetails']
    },
  },
  methods: {
    async verify() {
      this.verifying = true
      let error = null
      let message = 'Data Json saved successfully'

      try {
        const data = {
          data: this.verificationDetails,
        }

        await axios.post(`/pipeline/save_verification_details/${this.$route.params.id}/`, data)

        this.verified = true
      } catch (err) {
        message = err?.message || 'Error verifying Data Json'
        error = err
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: error ? 'AlertTriangleIcon' : 'CheckIcon',
          variant: error ? 'danger' : 'success',
        },
      })

      this.verifying = false
    },
    async submit() {
      this.submitting = true

      try {
        await axios.post(`/pipeline/release_transaction/${this.$route.params.id}/`)

        this.submitting = false

        this.$router.push({ name: 'home' })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.message || 'Error submitting Data Json',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        this.submitting = false
      }
    },
  },
}
</script>

<style scoped>
.wrapper {
  column-gap: 1rem;
}
</style>
