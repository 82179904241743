<template>
  <div
    :class="{'h-100': loading}"
  >
    <!-- Tab Buttons -->
    <b-button-group
      style="margin: 0.5rem"
    >
      <b-button
        v-for="(key, index) in Object.keys(atmWizardTabs)"
        :key="index + key"
        :variant="atmWizardTabs[key].active ? 'primary' : 'outline-primary'"
        size="sm"
        @click="onChangeTab(key)"
      >
        {{ `${index + 1}. ${atmWizardTabs[key].label}` }}
      </b-button>
    </b-button-group>

    <div
      class="separator"
      style="margin: 0.5rem 0"
    />

    <!-- Table Row Selection Tab -->
    <table-row-selection-tab />

    <!-- Results Tab -->
    <slot
      v-if="atmWizardTabs.results.active"
      name="results"
    />

    <!-- Test Tab -->
    <table-test v-if="!loading && atmWizardTabs.test.active" />

    <!-- Loader -->
    <div
      v-if="loading"
      class="mx-1 h-80 d-flex flex-column justify-content-center align-items-center"
      style="height: 80%"
    >
      <spinner-progress-loader
        :max="multipleLineRecord ? totalPages * 1.5 : totalPages * 0.1"
        :status="status ? status.status : ''"
        loading
        class="mx-2"
      />
    </div>

    <!-- scrollToTop Button -->
    <scroll-to-top-button
      v-if="atmWizardTabs.results.active || atmWizardTabs.test.active"
    />
  </div>
</template>

<script>
import bus from '@/bus'
import {
  BButtonGroup, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpinnerProgressLoader from '@/components/UI/SpinnerProgressLoader.vue'
import TableTest from '../../TableTest/TableTest.vue'

import TableRowSelectionTab from './AtmWizardTabs/TableRowSelectionTab.vue'
import ScrollToTopButton from '../ScrollToTopButton.vue'

export default {
  components: {
    BButtonGroup,
    BButton,
    SpinnerProgressLoader,
    TableTest,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    TableRowSelectionTab,
    ScrollToTopButton,
  },
  computed: {
    selectedTableUniqueId() {
      const definitionTables = this.$store.getters['dataView/table']
      const { table_unique_id } = definitionTables[this.$store.getters['dataView/selectedTableId']]
      // eslint-disable-next-line camelcase
      return table_unique_id
    },
    atmWizardTabs() {
      return this.$store.getters['atm/atmWizardTabs']
    },
    loading: {
      get() {
        return this.$store.getters['dataView/loading']
      },
      set(value) {
        this.$store.commit('dataView/SET_LOADING', value)
      },
    },
    status() {
      return this.$store.getters['batch/status']
    },
    totalPages() {
      return this.$store.getters['batch/totalPages']
    },
    selectedDefintionVersion() {
      return this.$store.getters['dataView/selectedDefinitionVersion']
    },
    userSelectedPatterns() {
      return this.$store.getters['atm/userSelectedPatterns']
    },
    multipleLineRecord() {
      return this.$store.getters['dataView/modelMultipleLineRecord']
    },
  },
  watch: {
    selectedTableUniqueId() {
      this.onResetAtmData()
    },
    atmWizardTabs() {
      if (this.atmWizardTabs.tableRowSelection.active) {
        if (this.multipleLineRecord) {
          this.$store.dispatch('atm/refreshExtendUserSelectedPatterns')
        } else {
          this.$store.dispatch('atm/refreshUserSelectedPatterns')
        }
      }
    },
  },
  created() {
    bus.$on('atm/onChangeTab', this.onChangeTab)
  },
  destroyed() {
    bus.$off('atm/onChangeTab', this.onChangeTab)
  },
  methods: {
    onResetAtmData() {
      this.$store.commit('atm/SET_ATM_PATTERNS', [])
      this.$store.commit('atm/SET_ATM_PATTERN_RECORDS', [])
      this.$store.commit('atm/SET_SELECTED_ATM_PATTERNS', [])

      this.$store.dispatch('atm/resetChunkLineRecords')

      if (this.multipleLineRecord) {
        this.$store.dispatch('atm/refreshExtendUserSelectedPatterns')
      } else {
        this.$store.dispatch('atm/refreshUserSelectedPatterns')
      }

      this.$store.dispatch('atm/refreshUserSelectedOb')
    },
    onChangeTab(key) {
      const atmWizardTabs = { ...this.atmWizardTabs }

      if (atmWizardTabs[key].active) {
        return
      }

      Object.keys(atmWizardTabs).forEach(keyItem => {
        if (key === keyItem) {
          atmWizardTabs[keyItem].active = true
        } else {
          atmWizardTabs[keyItem].active = false
        }
      })

      this.$store.commit('atm/SET_ATM_WIZARD_TABS', atmWizardTabs)
    },
  },
}
</script>
