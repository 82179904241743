<template>
  <div class="d-flex flex-column definitions-container">
    <b-alert
      variant="danger"
      :show="!loading && loadingError !== null ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div
      v-if="loading && mode !== 'automated-table-model'"
      class="text-center"
    >
      <b-spinner variant="primary" />
    </div>

    <div
      class="flex-grow-1 definitions-content-wrapper"
      @scroll="onScroll"
    >
      <div class="definitions-content-box">
        <div class="definitions-content">
          <template v-if="!loading && !loadingError">
            <template v-if="mainMode === 'tableSettings' && mode !== 'test'">
              <table-models
                v-show="mode === 'table-models'"
                v-model="tableModels"
              />
              <table-columns
                v-show="mode === 'table-columns'"
                v-model="tableColumns"
              />
              <key-items
                v-show="mode === 'table-keys'"
                v-model="tableKeyItems"
                source="tableKeyItems"
              />
              <table-rule-items
                v-show="mode === 'table-rule-items'"
              />
              <table-rules
                v-if="mode === 'table-rules'"
                v-model="tableRuleItemRules"
              />
              <table-normalizer-items
                v-show="mode === 'table-normalizer'"
                v-model="tableNormalizerItems"
              />
            </template>

            <table-test v-if="['test', 'verification'].includes(mode)" />

            <template v-if="mainMode === 'keySettings'">
              <key-models
                v-show="mode === 'key-models'"
                v-model="keyModels"
              />
              <key-items
                v-show="mode === 'key-items'"
                v-model="keyItems"
                source="keyItems"
              />
              <key-rule-items
                v-show="mode === 'key-rule-items'"
              />
              <key-rules
                v-if="mode === 'key-rules'"
                v-model="keyRuleItemRules"
              />
              <key-not-in-use-items
                v-show="mode === 'key-not-in-use-items'"
              />
              <key-lookup-items
                v-show="mode === 'key-lookup-items'"
              />
              <key-lookup
                v-if="mode === 'key-lookup'"
                v-model="keyLookupItemQueries"
              />
            </template>

            <chunk-data v-if="mode === 'chunk-data'" />

            <key-lookup
              v-if="batchView === 'explore-lookup'"
              :value="[]"
            />
          </template>

          <defined-keys v-if="mode === 'defined-keys'" />

          <atm v-if="mode === 'automated-table-model'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner, BAlert } from 'bootstrap-vue'
import bus from '@/bus'
import TableModels from './TableModels/TableModels.vue'
import TableColumns from './TableColumns/TableColumns.vue'
import TableTest from './TableTest/TableTest.vue'
import TableRules from './TableRules/TableRules.vue'
import TableRuleItems from './TableRuleItems/TableRuleItems.vue'
import TableNormalizerItems from './TableNormalizerItems/TableNormalizerItems.vue'

import KeyModels from './KeyModels/KeyModels.vue'
import KeyItems from './KeyItems/KeyItems.vue'
import KeyRuleItems from './KeyRuleItems/KeyRuleItems.vue'
import KeyRules from './KeyRules/KeyRules.vue'
import KeyNotInUseItems from './KeyNotInUseItems/KeyNotInUseItems.vue'
import KeyLookupItems from './KeyLookupItems/KeyLookupItems.vue'
import KeyLookup from './KeyLookup/KeyLookup.vue'

import ChunkData from './ChunkData/ChunkData.vue'
import DefinedKeys from './DefinedKeys/DefinedKeys.vue'
import Atm from './Atm/Atm.vue'

export default {
  components: {
    TableModels,
    TableColumns,
    TableTest,
    KeyModels,
    KeyItems,
    BSpinner,
    BAlert,
    KeyRuleItems,
    KeyRules,
    ChunkData,
    DefinedKeys,
    Atm,
    KeyNotInUseItems,
    TableRules,
    TableRuleItems,
    TableNormalizerItems,
    KeyLookup,
    KeyLookupItems,
  },
  data() {
    return {
      initialized: false,
    }
  },
  computed: {
    mainMode() {
      return this.$store.getters['dataView/mainMode']
    },
    mode() {
      return this.$store.getters['dataView/mode']
    },
    selectedDefinitionVersion() {
      return this.$store.getters['dataView/selectedDefinitionVersion']
    },
    batchView() {
      return this.$store.getters['batch/view']
    },
    dataRefreshKey() {
      return `${this.batchView}+${this.mainMode}+${this.selectedDefinitionVersion}`
    },
    selectedDefinition() {
      return this.$store.getters['dataView/selectedDefinition']
    },
    currentRouteName() {
      return this.$route.name
    },
    tableModels: {
      get() {
        return this.$store.getters['dataView/tableModels']
      },
      set(value) {
        this.$store.commit('dataView/SET_TABLE_MODELS', value)
      },
    },
    tableColumns: {
      get() {
        return this.$store.getters['dataView/tableColumns']
      },
      set(value) {
        this.$store.commit('dataView/SET_TABLE_COLUMNS', value)
      },
    },
    tableKeyItems: {
      get() {
        return this.$store.getters['dataView/tableKeyItems']
      },
      set(value) {
        this.$store.commit('dataView/SET_TABLE_KEY_ITEMS', value)
      },
    },
    keyItems: {
      get() {
        return this.$store.getters['dataView/keyItems']
      },
      set(value) {
        this.$store.commit('dataView/SET_KEY_ITEMS', value)
      },
    },
    keyModels: {
      get() {
        return this.$store.getters['dataView/keyModels']
      },
      set(value) {
        this.$store.commit('dataView/SET_KEY_MODELS', value)
      },
    },
    keyRuleItemRules: {
      get() {
        return this.$store.getters['dataView/keyRuleItemRules']
      },
      set(value) {
        this.$store.commit('dataView/SET_KEY_RULE_ITEM_RULES', value)
      },
    },
    tableRuleItemRules: {
      get() {
        return this.$store.getters['dataView/tableRuleItemRules']
      },
      set(value) {
        this.$store.commit('dataView/SET_TABLE_RULE_ITEM_RULES', value)
      },
    },
    tableNormalizerItems: {
      get() {
        return this.$store.getters['dataView/tableNormalizerItems']
      },
      set(value) {
        this.$store.commit('dataView/SET_TABLE_NORMALIZER_ITEMS', value)
      },
    },
    keyLookupItemQueries: {
      get() {
        return this.$store.getters['dataView/keyLookupItemQueries']
      },
      set(value) {
        this.$store.commit('dataView/SET_KEY_LOOKUP_ITEM_QUERIES', value)
      },
    },
    loading: {
      get() {
        return this.$store.getters['dataView/loading']
      },
      set(value) {
        this.$store.commit('dataView/SET_LOADING', value)
      },
    },
    loadingError: {
      get() {
        return this.$store.getters['dataView/loadingError']
      },
      set(value) {
        this.$store.commit('dataView/SET_LOADING_ERROR', value)
      },
    },
    selectedTableUniqueId() {
      const definitionTables = this.$store.getters['dataView/table']

      if (definitionTables.length) {
        const { table_unique_id } = definitionTables[this.$store.getters['dataView/selectedTableId']]
        // eslint-disable-next-line camelcase
        return table_unique_id
      }

      return null
    },
  },
  watch: {
    dataRefreshKey() {
      if (this.initialized) {
        this.fetchData()
      }
    },
    selectedTableUniqueId() {
      if (this.mode !== 'automated-table-model') {
        this.$store.dispatch('atm/resetChunkLineRecords')
      }
    },
  },
  created() {
    bus.$on('dataView/refreshData', this.fetchData)
    this.fetchData()
  },
  destroyed() {
    bus.$off('dataView/refreshData', this.fetchData)
  },
  methods: {
    onScroll(e) {
      bus.$emit('dataView/onScroll', e)
    },
    async fetchData() {
      this.loading = true

      if (['keySettings', 'tableSettings', 'automatedTableModel'].includes(this.mainMode)) {
        // Close rule screen / lookup screen
        if (this.$store.getters['dataView/mode'] === 'key-rules') {
          this.$store.dispatch('dataView/setMode', 'key-rule-items')
        } else if (this.$store.getters['dataView/mode'] === 'table-rules') {
          this.$store.dispatch('dataView/setMode', 'table-rule-items')
        } else if (this.$store.getters['dataView/mode'] === 'key-lookup') {
          this.$store.dispatch('dataView/setMode', 'key-lookup-items')
        }

        // Fetch Definition
        try {
          await this.$store.dispatch('dataView/fetchDefinition', this.currentRouteName)
        } catch (error) {
          this.loadingError = error
          this.loading = false

          if (!this.initialized) {
            this.initialized = true
          }

          return
        }
      } else if (this.mainMode === 'chunkData') {
        // Fetch Chunk Data
        try {
          await this.$store.dispatch('batch/loadChunkData')
        } catch (error) {
          this.loadingError = error.message || 'Error fetching chunk data'
          this.loading = false
          if (!this.initialized) {
            this.initialized = true
          }
          return
        }
      }

      this.loadingError = null
      this.loading = false
      if (!this.initialized) {
        this.initialized = true
      }
    },
  },
}
</script>

<style scoped>
.definitions-container {
  height: 100%;
  row-gap: 0.5rem;
}
.definitions-content-wrapper {
  overflow: auto;
}
.definitions-content-box {
  position: relative;
  height: 100%;
}
.definitions-content {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
</style>
