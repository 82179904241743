<template>
  <div
    ref="scrollContainer"
    class="scroll-container"
    @scroll="onScroll"
  >
    <div
      class="large-container"
      :style="{
        width: canvasWidth + 'px',
        height: canvasHeight + 'px',
        'margin-left': leftMargin + 'px'
      }"
    >
      <div
        v-if="highlightedNodeConfig"
        ref="highlightedNode"
        class="highlighted-node"
        :style="{
          top: highlightedNodeConfig.y * zoom + 'px',
          left: highlightedNodeConfig.x * zoom + 'px',
          width: highlightedNodeConfig.width * zoom + 'px',
          height: highlightedNodeConfig.height * zoom + 'px',
        }"
      />

      <div
        v-if="scrollNodeConfig.visible"
        ref="scrollNode"
        class="scroll-node"
        :style="{
          top: scrollNodeConfig.y * zoom + 'px',
          left: scrollNodeConfig.x * zoom + 'px',
          width: scrollNodeConfig.width * zoom + 'px',
          height: scrollNodeConfig.height * zoom + 'px',
        }"
      />

      <div
        :style="{
          transform: `translate(${scrollX}px, ${scrollY}px)`
        }"
      >
        <v-stage
          ref="stage"
          :config="stageConfig"
          @mousedown="handleStageMouseDown"
          @touchstart="handleStageMouseDown"
          @mousemove="handleStageMouseMove"
          @touchmove="handleStageMouseMove"
          @mouseup="handleStageMouseUp"
          @touchend="handleStageMouseUp"
        >
          <v-layer
            ref="layer"
            :config="imageLayerConfig"
          >
            <v-image
              v-for="imageConfig of pageImages"
              ref="image"
              :key="imageConfig.id"
              :config="imageConfig"
            />
            <v-rect
              v-if="highlightedNodeConfig"
              ref="rect"
              :config="highlightedNodeConfig"
            />

            <template v-if="selectableWordNodes">
              <v-rect
                v-for="wordNodeConfig of wordNodesConfig"
                :key="wordNodeConfig.id"
                :config="wordNodeConfig"
                @mouseenter="nodeMouseEnterHandler"
                @mouseleave="nodeMouseLeaveHandler"
                @mousedown="nodeMouseDownHandler"
              />
            </template>

            <v-rect
              v-for="(chunkNodeConfig, index) of chunkNodesConfig"
              :key="index"
              :config="chunkNodeConfig"
              @mousedown="chunkNodeMouseDownHandler"
            />

            <v-rect
              v-for="(keyBlockConfig, index) of keyBlocksConfig"
              :key="`keyBlock${index}`"
              :config="keyBlockConfig"
            />

            <template v-if="mode === 'automated-table-model'">
              <template v-if="atmWizardTabs.results.active">
                <v-rect
                  v-for="(atmPatternRecordConfig, index) of atmPatternRecordsConfig"
                  :key="atmPatternRecordConfig.pos + index"
                  :config="atmPatternRecordConfig"
                  @mouseenter="handleMouseEnter"
                  @mouseleave="handleMouseLeave"
                  @mousedown="atmPatternMouseDownHandler"
                />
              </template>

              <template v-if="atmWizardTabs.tableRowSelection.active">
                <template
                  v-if="multipleLineRecord"
                >
                  <v-rect
                    v-for="(multiLineRecord, index) of multiLineRecordsConfig"
                    :key="multiLineRecord.pos + multiLineRecord.pageId + index"
                    :config="multiLineRecord"
                    @mouseenter="handleMouseEnter"
                    @mouseleave="handleMouseLeave"
                  />
                </template>
                <v-rect
                  v-for="(chunkLineRecordConfig, index) of chunkLineRecordsConfig"
                  :key="chunkLineRecordConfig.posRef + index"
                  :config="chunkLineRecordConfig"
                  @mouseenter="handleMouseEnter"
                  @mouseleave="handleMouseLeave"
                  @mousedown="atmPatternMouseDownHandler"
                />
              </template>
            </template>

            <v-rect
              :config="selectionRectConfig"
            />

            <v-rect
              :config="scrollNodeConfig"
            />

            <v-rect
              ref="selectorRect"
              :config="selectorRectConfig"
              @transformend="handleTransformEnd"
              @dragend="handleDragEnd"
            />

            <v-line
              :config="measureLineConfig"
            />

            <v-transformer
              ref="transformer"
              :config="transformerConfig"
            />

            <v-line
              v-for="(keyAnchorLineConfig, index) of keyAnchorLineConfigs"
              :key="`anchor-line-${index}`"
              :config="keyAnchorLineConfig"
            />
          </v-layer>
        </v-stage>
      </div>
    </div>
  </div>

</template>

<script>
import { min, max } from 'lodash'
import bus from '@/bus'
import Vue from 'vue'

// padding will increase the size of stage
// so scrolling will look smoother
const PADDING = 500
const localBus = new Vue()
const resizeOb = new ResizeObserver(() => {
  localBus.$emit('containerSizeChanged')
})

export default {
  props: {
    pages: {
      type: Array,
      required: true,
    },
    highlightedNode: {
      type: Object,
      required: false,
      default: null,
    },
    selectableWordNodes: {
      type: Boolean,
      required: false,
      default() {
        return false
      },
    },
    chunkData: {
      type: Object,
      required: false,
      default() {
        return null
      },
    },
    keyBlocks: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    chunkNodeListening: {
      type: Boolean,
      required: true,
    },
    atmPatternRecords: {
      type: Array,
      required: false,
      default() {
        return null
      },
    },
    chunkLineRecords: {
      type: Array,
      required: false,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      selectionRectConfig: {
        rotation: 0,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        scaleX: 1,
        scaleY: 1,
        fill: 'transparent',
        stroke: '#7367f0',
        strokeWidth: 2,
        visible: false,
        strokeScaleEnabled: false,
      },
      measureLineConfig: {
        points: [0, 0, 0, 0],
        stroke: '#7367f0',
        strokeWidth: 3,
        visible: false,
        drawing: false,
      },
      selectorRectConfig: {
        rotation: 0,
        x: 10,
        y: 10,
        width: 100,
        height: 100,
        scaleX: 1,
        scaleY: 1,
        fill: 'transparent',
        stroke: '#7367f0',
        strokeWidth: 2,
        draggable: true,
        visible: false,
        strokeScaleEnabled: false,
        name: 'selectorRect',
      },
      transformerConfig: {
        rotateEnabled: false,
        borderEnabled: false,
        ignoreStroke: true,
        flipEnabled: false,
        anchorStroke: '#7367f0',
        name: 'transformer',
      },
      scrollX: 0,
      scrollY: 0,
      containerSize: null,
      selectedNodeIds: [],
      dragStartX: 0,
      dragStartY: 0,
      dragEndX: 0,
      dragEndY: 0,
      scrollNodeConfig: {
        rotation: 0,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        scaleX: 1,
        scaleY: 1,
        fill: 'transparent',
        stroke: 'red',
        strokeWidth: 2,
        visible: false,
        strokeScaleEnabled: false,
        listening: false,
      },
      scrollToPosTimer: null,
    }
  },
  computed: {
    mode() {
      return this.$store.getters['dataView/mode']
    },
    batchId() {
      return this.$store.getters['batch/batch'].id
    },
    zoom() {
      return this.$store.getters['batch/zoom']
    },
    canvasWidth() {
      const maxWidth = max(this.pages.map(page => page.width))
      return maxWidth * this.zoom
    },
    canvasHeight() {
      const totalHeight = this.pages.map(page => page.height).reduce((a, b) => a + b, 0)
      return totalHeight * this.zoom
    },
    stageConfig() {
      return {
        width: window.innerWidth + PADDING * 2,
        height: window.innerHeight + PADDING * 2,
        x: -this.scrollX,
        y: -this.scrollY,
      }
    },
    leftMargin() {
      let margin = 0
      if (this.canvasWidth < this.containerSize?.width) {
        margin = (this.containerSize.width - this.canvasWidth) / 2
      }
      return margin
    },
    imageLayerConfig() {
      return {
        scaleX: this.zoom,
        scaleY: this.zoom,
      }
    },
    pageImages() {
      let pageY = 0
      return this.pages.map(page => {
        const pageConfig = {
          x: 0,
          y: pageY,
          image: page.image,
          width: page.width,
          height: page.height,
          id: page.id,
        }
        pageY += page.height
        return pageConfig
      })
    },
    pageYOffsets() {
      const offsets = {}
      this.pageImages.forEach(pageImge => {
        offsets[pageImge.id] = pageImge.y
      })
      return offsets
    },
    wordNodesConfig() {
      const wordNodes = []
      let pageY = 0
      this.pages.forEach(page => {
        page.wordNodes.forEach(wordNode => {
          const positionInfo = wordNode.pos.split(',').map(num => +num)

          const x = positionInfo[0]
          const y = positionInfo[1] + pageY
          const width = positionInfo[2] - positionInfo[0]
          const height = positionInfo[3] - positionInfo[1]

          let fill = 'transparent'
          let opacity = 1
          if (this.selectedNodeIds.includes(wordNode.id)) {
            fill = 'yellow'
            opacity = 0.5
          }

          wordNodes.push({
            id: wordNode.id,
            x,
            y,
            width,
            height,
            fill,
            wordText: wordNode.v,
            opacity,
          })
        })
        pageY += page.height
      })
      return wordNodes
    },
    chunkNodesConfig() {
      const chunkNodes = []

      if (!this.chunkData) {
        return chunkNodes
      }

      this.chunkData.chunkLines.forEach(chunkLine => {
        chunkLine.chunks.forEach(chunkItem => {
          const pageY = this.pageYOffsets[chunkItem.pageId]
          if (pageY != null) {
            const text = chunkItem.value
            const positionInfo = chunkItem.pos.split(',').map(num => +num)
            const x = positionInfo[0]
            const y = positionInfo[1] + pageY
            const width = positionInfo[2] - positionInfo[0]
            const height = positionInfo[3] - positionInfo[1]

            chunkNodes.push({
              text,
              x,
              y,
              width,
              height,
              fill: 'yellow',
              opacity: 0.4,
              listening: this.chunkNodeListening,
              pos: chunkItem.pos,
              pageId: chunkItem.pageId,
            })
          }
        })
      })

      return chunkNodes
    },
    keyBlocksConfig() {
      const keyBlockNodes = []

      if (!this.highlightKeyBlocks) {
        return keyBlockNodes
      }

      this.keyBlocks.forEach(keyBlock => {
        const pageY = this.pageYOffsets[keyBlock.pageId]
        if (pageY != null) {
          const positionInfo = keyBlock.pos.split(',').map(num => +num)
          const x = positionInfo[0]
          const y = positionInfo[1] + pageY
          const width = positionInfo[2] - positionInfo[0]
          const height = positionInfo[3] - positionInfo[1]

          keyBlockNodes.push({
            x,
            y,
            width,
            height,
            fill: 'transparent',
            stroke: 'red',
            strokeWidth: 2,
            listening: false,
          })
        }
      })

      return keyBlockNodes
    },
    atmWizardTabs() {
      return this.$store.getters['atm/atmWizardTabs']
    },
    extendedUserSelectedPatterns() {
      return this.$store.getters['atm/extendedUserSelectedPatterns']
    },
    multipleLineRecord() {
      return this.$store.getters['dataView/modelMultipleLineRecord']
    },
    chunkLineRecordsConfig() {
      const chunkLineRecords = []

      if (!this.chunkLineRecords) {
        return chunkLineRecords
      }

      this.chunkLineRecords.forEach(pos => {
        // eslint-disable-next-line no-unused-vars
        const [left, top, right, bottom, pageId, _, __, ___, status, pattern] = pos.split(',')
        const pageY = this.pageYOffsets[pageId]

        if (pageY != null) {
          const x = parseInt(left, 10)
          const y = parseInt(top, 10) + pageY
          const width = parseInt(right, 10) - parseInt(left, 10)
          const height = parseInt(bottom, 10) - parseInt(top, 10)

          chunkLineRecords.push({
            x,
            y,
            width,
            height,
            fill: status === 'blank' ? 'transparent' : status,
            opacity: 0.4,
            pos: [left, top, right, bottom].join(','),
            pageId,
            posRef: pos,
            pattern,
          })
        }
      })

      return chunkLineRecords
    },
    atmPatternRecordsConfig() {
      const atmPatternRecords = []

      if (!this.atmPatternRecords) {
        return atmPatternRecords
      }

      this.atmPatternRecords.forEach(pos => {
        // eslint-disable-next-line no-unused-vars
        const [left, top, right, bottom, pageId, _, batchId, posRef, status, refStatus] = pos.split(',')
        const pageY = this.pageYOffsets[pageId]

        if (pageY != null && this.batchId === batchId) {
          const x = parseInt(left, 10)
          const y = parseInt(top, 10) + pageY
          const width = parseInt(right, 10) - parseInt(left, 10)
          const height = parseInt(bottom, 10) - parseInt(top, 10)

          atmPatternRecords.push({
            x,
            y,
            width,
            height,
            fill: status === 'blank' ? 'transparent' : status,
            refStatus,
            opacity: 0.4,
            pos: [left, top, right, bottom].join(','),
            pageId,
            posRef,
          })
        }
      })

      return atmPatternRecords
    },
    multiLineRecordsConfig() {
      const multiLineRecords = []

      if (!this.extendedUserSelectedPatterns) {
        return multiLineRecords
      }

      this.extendedUserSelectedPatterns.forEach(item => {
        let pageY = null
        let pageId = null
        let batchId = null
        let left
        let top
        let right
        let bottom

        item.forEach(e => {
          // eslint-disable-next-line no-unused-vars
          const [itemLeft, itemTop, itemRight, itemBottom, itemPageId, _, itemBatchId] = e.pos.split(',')

          if (pageY == null || pageId == null || batchId == null) {
            pageY = this.pageYOffsets[itemPageId]
            pageId = itemPageId
            batchId = itemBatchId
          }

          if (!left || left > parseInt(itemLeft, 10)) {
            left = parseInt(itemLeft, 10)
          }

          if (!top || top > parseInt(itemTop, 10)) {
            top = parseInt(itemTop, 10)
          }

          if (!right || right < parseInt(itemRight, 10)) {
            right = parseInt(itemRight, 10)
          }

          if (!bottom || bottom < parseInt(itemBottom, 10)) {
            bottom = parseInt(itemBottom, 10)
          }
        })

        if (pageY != null && batchId === this.batchId) {
          const x = parseInt(left, 10)
          const y = parseInt(top, 10) + pageY
          const width = parseInt(right, 10) - parseInt(left, 10)
          const height = parseInt(bottom, 10) - parseInt(top, 10)

          multiLineRecords.push({
            x,
            y,
            width,
            height,
            fill: 'transparent',
            opacity: 0.6,
            pos: [left, top, right, bottom].join(','),
            pageId,
            // posRef: e.pos,
            // pattern,
            stroke: 'red',
            strokeWidth: 4,
          })
        }
      })

      return multiLineRecords
    },
    highlightedNodeConfig() {
      if (!this.highlightedNode) {
        return null
      }

      const pageConfig = this.pageImages.find(pageImage => pageImage.id === this.highlightedNode.pageId)
      if (!pageConfig) {
        return null
      }
      const positionInfo = this.highlightedNode.pos.split(',').map(num => +num)

      const x = positionInfo[0]
      const y = positionInfo[1] + pageConfig.y
      const width = positionInfo[2] - positionInfo[0]
      const height = positionInfo[3] - positionInfo[1]

      return {
        x,
        y,
        width,
        height,
        fill: 'transparent',
        stroke: 'red',
        strokeWidth: 2,
        listening: false,
      }
    },
    enableSelector() {
      return this.$store.getters['batch/enableSelector']
    },
    enableMeasure() {
      return this.$store.getters['batch/enableMeasure']
    },
    highlightKeyBlocks() {
      return this.$store.getters['batch/highlightKeyBlocks']
    },
    selectorPosition() {
      if (!this.selectorRectConfig.visible) {
        return null
      }

      let topPos = this.selectorRectConfig.y
      let pageId = ''
      let selectedPageIndex = -1

      // Calulate height respective to the page
      for (let pageIndex = 0; pageIndex < this.pages.length; pageIndex += 1) {
        const page = this.pages[pageIndex]
        if (topPos > page.height) {
          topPos -= page.height
        } else {
          pageId = page.id
          selectedPageIndex = pageIndex
          break
        }
      }

      return {
        startPos: this.selectorRectConfig.x.toString(),
        endPos: (this.selectorRectConfig.x + (this.selectorRectConfig.width * this.selectorRectConfig.scaleX)).toString(),
        topPos: topPos.toString(),
        bottomPos: (topPos + (this.selectorRectConfig.height * this.selectorRectConfig.scaleY)).toString(),
        pageId,
        pageIndex: selectedPageIndex,
      }
    },
    measuredDistance() {
      if (!this.measureLineConfig.visible || this.measureLineConfig.drawing) {
        return null
      }
      const xDistance = Math.abs(this.measureLineConfig.points[2] - this.measureLineConfig.points[0]).toFixed(2)
      const yDistance = Math.abs(this.measureLineConfig.points[3] - this.measureLineConfig.points[1]).toFixed(2)
      return { xDistance, yDistance }
    },
    displayKeyAnchors() {
      return this.$store.getters['batch/displayKeyAnchors']
    },
    keyAnchorsData() {
      return this.$store.getters['batch/keyAnchorsData']
    },
    keyAnchorLineConfigs() {
      const lines = []
      if (!this.displayKeyAnchors) {
        return lines
      }

      const topData = this.keyAnchorsData.top || {}
      const bottomData = this.keyAnchorsData.bottom || {}
      const leftData = this.keyAnchorsData.left || {}
      const rightData = this.keyAnchorsData.right || {}
      const { selectedAnchor } = this.keyAnchorsData

      const topThreshold = topData.threshold ? parseFloat(topData.threshold) : 0
      const bottomThreshold = bottomData.threshold ? parseFloat(bottomData.threshold) : 0
      const leftThreshold = leftData.threshold ? parseFloat(leftData.threshold) : 0
      const rightThreshold = rightData.threshold ? parseFloat(rightData.threshold) : 0

      let page
      let pageYOffset
      let x1
      let y1
      let x2
      let y2

      if (topData.pos) {
        page = this.pages[topData.pageIndex]
        if (page) {
          pageYOffset = this.pageYOffsets[page.id]
          x1 = leftData.pos ? +leftData.pos.split(',')[2] : 0
          y1 = +topData.pos.split(',')[3] + pageYOffset
          x2 = rightData.pos ? +rightData.pos.split(',')[0] : page.width
          y2 = +topData.pos.split(',')[3] + pageYOffset

          if (selectedAnchor === 'top') {
            lines.push({
              points: [x1 + leftThreshold, y1, x2 + rightThreshold, y2],
              stroke: '#7367f0',
              strokeWidth: 2,
              strokeScaleEnabled: false,
              dash: [10, 10],
            })
          }

          lines.push({
            points: [x1 + leftThreshold, y1 + topThreshold, x2 + rightThreshold, y2 + topThreshold],
            stroke: 'red',
            strokeWidth: 2,
            strokeScaleEnabled: false,
            dash: selectedAnchor === 'top' ? [10, 10] : [],
          })
        }
      }

      if (bottomData.pos) {
        page = this.pages[bottomData.pageIndex]
        if (page) {
          pageYOffset = this.pageYOffsets[page.id]
          x1 = leftData.pos ? +leftData.pos.split(',')[2] : 0
          y1 = +bottomData.pos.split(',')[1] + pageYOffset
          x2 = rightData.pos ? +rightData.pos.split(',')[0] : page.width
          y2 = +bottomData.pos.split(',')[1] + pageYOffset

          if (selectedAnchor === 'bottom') {
            lines.push({
              points: [x1 + leftThreshold, y1, x2 + rightThreshold, y2],
              stroke: '#7367f0',
              strokeWidth: 2,
              strokeScaleEnabled: false,
              dash: [10, 10],
            })
          }

          lines.push({
            points: [x1 + leftThreshold, y1 + bottomThreshold, x2 + rightThreshold, y2 + bottomThreshold],
            stroke: 'red',
            strokeWidth: 2,
            strokeScaleEnabled: false,
            dash: selectedAnchor === 'bottom' ? [10, 10] : [],
          })
        }
      }

      if (leftData.pos) {
        page = this.pages[leftData.pageIndex]
        if (page) {
          pageYOffset = this.pageYOffsets[page.id]
          x1 = +leftData.pos.split(',')[2]
          y1 = (topData.pos ? +topData.pos.split(',')[3] : 0) + pageYOffset
          x2 = +leftData.pos.split(',')[2]
          y2 = (bottomData.pos ? +bottomData.pos.split(',')[1] : page.height) + pageYOffset

          if (selectedAnchor === 'left') {
            lines.push({
              points: [x1, y1 + topThreshold, x2, y2 + bottomThreshold],
              stroke: '#7367f0',
              strokeWidth: 2,
              strokeScaleEnabled: false,
              dash: [10, 10],
            })
          }

          lines.push({
            points: [x1 + leftThreshold, y1 + topThreshold, x2 + leftThreshold, y2 + bottomThreshold],
            stroke: 'red',
            strokeWidth: 2,
            strokeScaleEnabled: false,
            dash: selectedAnchor === 'left' ? [10, 10] : [],
          })
        }
      }

      if (rightData.pos) {
        page = this.pages[rightData.pageIndex]
        if (page) {
          pageYOffset = this.pageYOffsets[page.id]
          x1 = +rightData.pos.split(',')[0]
          y1 = (topData.pos ? +topData.pos.split(',')[3] : 0) + pageYOffset
          x2 = +rightData.pos.split(',')[0]
          y2 = (bottomData.pos ? +bottomData.pos.split(',')[1] : page.height) + pageYOffset

          if (selectedAnchor === 'right') {
            lines.push({
              points: [x1, y1 + topThreshold, x2, y2 + bottomThreshold],
              stroke: '#7367f0',
              strokeWidth: 2,
              strokeScaleEnabled: false,
              dash: [10, 10],
            })
          }

          lines.push({
            points: [x1 + rightThreshold, y1 + topThreshold, x2 + rightThreshold, y2 + bottomThreshold],
            stroke: 'red',
            strokeWidth: 2,
            strokeScaleEnabled: false,
            dash: selectedAnchor === 'right' ? [10, 10] : [],
          })
        }
      }

      return lines
    },
  },
  watch: {
    selectorPosition: {
      handler() {
        this.$store.commit('batch/SET_SELECTOR_POSITION', this.selectorPosition)
      },
      deep: true,
    },
    measuredDistance: {
      handler() {
        this.$store.commit('batch/SET_MEASURED_DISTANCE', this.measuredDistance)
      },
      deep: true,
    },
    enableSelector() {
      if (this.enableSelector === false) {
        this.deleteSelector()
      }
    },
    enableMeasure() {
      if (this.enableMeasure === false) {
        this.deleteMeasure()
      }
    },
  },
  created() {
    bus.$on('fitToWidth', this.fitToWidth)
    bus.$on('scrollToHighlightedNode', this.scrollToHighlightedNode)
    bus.$on('scrollToPos', this.scrollToPos)
    localBus.$on('containerSizeChanged', this.onContainerSizeChange)
  },
  mounted() {
    this.setContainerSize()
    this.fitToWidth()
    resizeOb.observe(this.$refs.scrollContainer)
    this.scrollToHighlightedNode()
  },
  beforeDestroy() {
    resizeOb.unobserve(this.$refs.scrollContainer)
  },
  destroyed() {
    bus.$off('fitToWidth', this.fitToWidth)
    bus.$off('scrollToHighlightedNode', this.scrollToHighlightedNode)
    bus.$off('scrollToPos', this.scrollToPos)
    localBus.$off('containerSizeChanged', this.onContainerSizeChange)
  },
  methods: {
    handleTransformEnd(e) {
      this.selectorRectConfig.x = e.target.x()
      this.selectorRectConfig.y = e.target.y()
      this.selectorRectConfig.rotation = e.target.rotation()
      this.selectorRectConfig.scaleX = e.target.scaleX()
      this.selectorRectConfig.scaleY = e.target.scaleY()
    },
    handleDragEnd(e) {
      this.selectorRectConfig.x = e.target.x()
      this.selectorRectConfig.y = e.target.y()
    },
    handleMouseEnter(e) {
      const container = e.target.getStage().container()
      container.style.cursor = 'pointer'
    },
    handleMouseLeave(e) {
      const container = e.target.getStage().container()
      container.style.cursor = 'default'
    },
    handleStageMouseDown(e) {
      e.evt.preventDefault()

      // Do nothing if clicked on selector rectangle
      const clickedOnSelectorRect = e.target.hasName('selectorRect')
      const clickedOnTransformer = e.target.getParent().className === 'Transformer'
      if (clickedOnSelectorRect || clickedOnTransformer) {
        return
      }

      if (this.enableSelector) {
        // Start selection otherwise
        const layer = this.$refs.layer.getNode()
        const pointerPosition = layer.getRelativePointerPosition()
        this.dragStartX = pointerPosition.x
        this.dragStartY = pointerPosition.y
        this.dragEndX = pointerPosition.x
        this.dragEndY = pointerPosition.y

        this.selectionRectConfig.width = 0
        this.selectionRectConfig.height = 0
        this.selectionRectConfig.visible = true
      }

      if (this.enableMeasure) {
        // Start measure otherwise
        const layer = this.$refs.layer.getNode()
        const pointerPosition = layer.getRelativePointerPosition()
        this.dragStartX = pointerPosition.x
        this.dragStartY = pointerPosition.y
        this.dragEndX = pointerPosition.x
        this.dragEndY = pointerPosition.y

        this.measureLineConfig.points = [0, 0, 0, 0]
        this.measureLineConfig.visible = true
        this.measureLineConfig.drawing = true
      }
    },
    handleStageMouseMove(e) {
      e.evt.preventDefault()
      if (this.enableSelector) {
        if (!this.selectionRectConfig.visible) {
          return
        }

        // Update dimenitions of selection rectangle
        const layer = this.$refs.layer.getNode()
        const pointerPosition = layer.getRelativePointerPosition()

        this.dragEndX = pointerPosition.x
        this.dragEndY = pointerPosition.y

        this.selectionRectConfig.x = Math.min(this.dragStartX, this.dragEndX)
        this.selectionRectConfig.y = Math.min(this.dragStartY, this.dragEndY)
        this.selectionRectConfig.width = Math.abs(this.dragEndX - this.dragStartX)
        this.selectionRectConfig.height = Math.abs(this.dragEndY - this.dragStartY)
      }

      if (this.enableMeasure) {
        // if user is not drawing line, do nothing
        if (!this.measureLineConfig.drawing) {
          return
        }

        // Update line points
        const layer = this.$refs.layer.getNode()
        const pointerPosition = layer.getRelativePointerPosition()

        this.dragEndX = pointerPosition.x
        this.dragEndY = pointerPosition.y

        const points = [
          this.dragStartX,
          this.dragStartY,
          this.dragEndX,
          this.dragEndY,
        ]
        this.measureLineConfig.points = points
      }
    },
    handleStageMouseUp(e) {
      e.evt.preventDefault()
      if (this.enableSelector) {
        if (!this.selectionRectConfig.visible) {
          return
        }

        if (this.selectionRectConfig.width > 0) {
          this.createSelector(this.selectionRectConfig.x, this.selectionRectConfig.y, this.selectionRectConfig.width, this.selectionRectConfig.height)
        }

        // Hide selection rectangle
        this.selectionRectConfig.visible = false
      }
      if (this.enableMeasure) {
        // if user is drawing a line, complete the line.
        if (this.measureLineConfig.drawing) {
          this.measureLineConfig.drawing = false
        }
      }
    },
    onScroll() {
      this.scrollX = this.$refs.scrollContainer.scrollLeft - PADDING
      this.scrollY = this.$refs.scrollContainer.scrollTop - PADDING
    },
    onContainerSizeChange() {
      this.setContainerSize()
    },
    setContainerSize() {
      this.containerSize = {
        width: this.$refs.scrollContainer.clientWidth,
        height: this.$refs.scrollContainer.clientHeight,
      }
    },
    fitToWidth() {
      if (!this.containerSize || this.pages.length === 0) {
        return
      }

      const pageWidth = max(this.pages.map(page => page.width))

      const widthZoom = this.containerSize.width / pageWidth < 0.23 ? 0.23 : this.containerSize.width / pageWidth
      this.$store.commit('batch/SET_ZOOM', widthZoom - 0.1)
    },
    scrollToHighlightedNode() {
      if (!this.$refs.highlightedNode) {
        return
      }
      this.$refs.scrollContainer.scrollTop = this.$refs.highlightedNode.offsetTop - 20
      this.$refs.scrollContainer.scrollLeft = this.$refs.highlightedNode.offsetLeft - 20
    },
    scrollToPos(data) {
      const { pos, pageId } = data

      const pageConfig = this.pageImages.find(pageImage => pageImage.id === pageId)
      if (!pageConfig) {
        return
      }
      const positionInfo = pos.split(',').map(num => +num)
      const x = positionInfo[0]
      const y = positionInfo[1] + pageConfig.y
      const width = positionInfo[2] - positionInfo[0]
      const height = positionInfo[3] - positionInfo[1]

      clearTimeout(this.scrollToPosTimer)

      this.scrollNodeConfig.x = x
      this.scrollNodeConfig.y = y
      this.scrollNodeConfig.width = width
      this.scrollNodeConfig.height = height
      this.scrollNodeConfig.visible = true

      this.$nextTick(() => {
        this.$refs.scrollContainer.scrollTop = this.$refs.scrollNode.offsetTop - 20
        this.$refs.scrollContainer.scrollLeft = this.$refs.scrollNode.offsetLeft - 20

        this.scrollToPosTimer = setTimeout(() => {
          this.scrollNodeConfig.visible = false
        }, 1500)
      })
    },
    nodeMouseEnterHandler(event) {
      const nodeId = event.target.attrs.id
      if (event.evt.shiftKey) {
        if (!this.selectedNodeIds.includes(nodeId)) {
          this.selectedNodeIds.push(nodeId)
        }
      } else {
        this.selectedNodeIds = [nodeId]
      }
    },
    nodeMouseLeaveHandler(event) {
      if (!event.evt.shiftKey) {
        this.selectedNodeIds = []
      }
    },
    nodeMouseDownHandler(event) {
      event.evt.preventDefault()

      if (this.selectedNodeIds.length === 0) {
        return
      }

      const wordNodes = []
      this.pages.forEach(page => {
        page.wordNodes.forEach(wordNode => {
          wordNodes.push({
            pageId: page.id,
            ...wordNode,
          })
        })
      })

      const selectedNodes = wordNodes.filter(node => this.selectedNodeIds.includes(node.id))
      const text = selectedNodes.map(node => node.v).join(' ')
      const startPos = min(selectedNodes.map(node => +node.pos.split(',')[0])).toString()
      const topPos = min(selectedNodes.map(node => +node.pos.split(',')[1])).toString()
      const endPos = max(selectedNodes.map(node => +node.pos.split(',')[2])).toString()
      const bottomPos = min(selectedNodes.map(node => +node.pos.split(',')[3])).toString()

      const { pageId, styleId } = selectedNodes[0]
      const pageIndex = this.pages.findIndex(page => page.id === pageId)
      const page = this.pages[pageIndex]
      const style = styleId && page.styles[styleId] ? page.styles[styleId] : null

      const value = {
        text,
        startPos,
        topPos,
        endPos,
        bottomPos,
        pageIndex,
        pageId,
        style,
        pageHeight: page.height,
        pageWidth: page.width,
      }
      bus.$emit('imageViewerValueSelected', value)
    },
    createSelector(x, y, width, height) {
      this.selectorRectConfig.x = x
      this.selectorRectConfig.y = y
      this.selectorRectConfig.width = width
      this.selectorRectConfig.height = height
      this.selectorRectConfig.scaleX = 1
      this.selectorRectConfig.scaleY = 1
      this.selectorRectConfig.visible = true
      // Attach transformer
      const transformerNode = this.$refs.transformer.getNode()
      transformerNode.nodes([this.$refs.selectorRect.getNode()])
    },
    deleteSelector() {
      this.selectorRectConfig.visible = false
      // Detach transformer
      const transformerNode = this.$refs.transformer.getNode()
      transformerNode.nodes([])
    },
    deleteMeasure() {
      this.measureLineConfig.points = [0, 0, 0, 0]
      this.measureLineConfig.visible = false
      this.measureLineConfig.drawing = false
    },
    chunkNodeMouseDownHandler(event) {
      const { text, pos, pageId } = event.target.attrs
      if (this.mode === 'chunk-data') {
        bus.$emit('scrollToChunkCell', { pos, pageId })
      }

      const startPos = pos.split(',')[0]
      const topPos = pos.split(',')[1]
      const endPos = pos.split(',')[2]
      const bottomPos = pos.split(',')[3]

      const pageIndex = this.pages.findIndex(page => page.id === pageId)
      const page = this.pages[pageIndex]

      const value = {
        text,
        startPos,
        topPos,
        endPos,
        bottomPos,
        pageIndex,
        style: null,
        pageHeight: page.height,
        pageWidth: page.width,
      }
      bus.$emit('imageViewerValueSelected', value)
    },
    atmPatternMouseDownHandler(event) {
      const { posRef, refStatus } = event.target.attrs

      if (this.atmWizardTabs.tableRowSelection.active) {
        bus.$emit('atm/selectTableRow', posRef)

        return
      }

      if (event.evt.shiftKey) {
        bus.$emit('atm/onShiftClick', posRef)
      }

      bus.$emit('atm/scrollToAtmPatternRecord', { posRef, refStatus })
    },
  },
}
</script>

<style scoped>
.scroll-container {
    overflow: auto;
}
.large-container {
    overflow: hidden;
    position: relative;
}
.highlighted-node, .scroll-node {
    position: absolute;
}
</style>
