<template>
  <div class="h-100">
    <b-table-simple
      v-if="!loading && !loadingError"
      ref="table"
      class="custom-table h-100"
      sticky-header="100%"
    >
      <colgroup>
        <col style="width: 20%">
        <col style="width: 7%">
        <col style="width: 7%">
        <col style="width: 7%">
        <col style="width: 8%">
        <col style="width: 7%">
        <col style="width: 40%">
        <col style="width: 4%">
      </colgroup>

      <b-thead>
        <b-tr>
          <b-th>Label</b-th>
          <b-th>Page Index</b-th>
          <b-th>Pos</b-th>
          <b-th>X Percentage</b-th>
          <b-th>Y Percentage</b-th>
          <b-th>Language</b-th>
          <b-th>Style</b-th>
          <b-th />
        </b-tr>
      </b-thead>
      <b-tbody>
        <defined-key
          v-for="(item, itemIndex) of items"
          :key="itemIndex"
          v-model="items[itemIndex]"
          @deleteItem="deleteItem(itemIndex, item)"
        />
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import {
  VBTooltip, BTableSimple, BThead, BTbody, BTh, BTr,
} from 'bootstrap-vue'
import bus from '@/bus'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DefinedKey from './DefinedKey.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BTableSimple,
    DefinedKey,
    BTbody,
    BThead,
    BTh,
    BTr,
  },
  data() {
    return {
      items: [],
      deletedIds: [],
    }
  },
  computed: {
    definitionId() {
      return this.$store.getters['dataView/selectedDefinition']?.id
    },
    loading: {
      get() {
        return this.$store.getters['dataView/loading']
      },
      set(value) {
        this.$store.commit('dataView/SET_LOADING', value)
      },
    },
    loadingError: {
      get() {
        return this.$store.getters['dataView/loadingError']
      },
      set(value) {
        this.$store.commit('dataView/SET_LOADING_ERROR', value)
      },
    },
  },
  created() {
    bus.$on('dataView/addDefinedKeys', this.addItems)
    bus.$on('dataView/saveDefinedKeys', this.save)
    this.fetchDefinedKeys(true)
  },
  destroyed() {
    bus.$off('dataView/addDefinedKeys', this.addItems)
    bus.$off('dataView/saveDefinedKeys', this.save)
  },
  methods: {
    fetchDefinedKeys(showSpinner) {
      return new Promise(resolve => {
        if (showSpinner) {
          this.loading = true
        }
        axios.get('/defined_keys/', {
          params: {
            definition_id: this.definitionId,
          },
        }).then(res => {
          this.items = res.data.items
          this.deletedIds = []
          this.loadingError = null
          this.loading = false
          resolve()
        }).catch(error => {
          this.loadingError = error?.response?.data?.detail || 'Error fetching defined keys'
          this.loading = false
          resolve()
        })
      })
    },
    addItems(count) {
      const lastRowIndex = this.items.length - 1
      const newItems = []
      for (let i = 0; i < count; i += 1) {
        newItems.push({
          id: '',
          label: '',
          data: {
            pos: null,
            xPercentage: null,
            yPercentage: null,
            pageIndex: null,
            style: null,
          },
        })
      }

      this.items = this.items.concat(newItems)
      this.$nextTick(() => {
        this.scrollToIndex(lastRowIndex + 1)
      })
    },
    scrollToIndex(index) {
      const table = this.$refs.table.$el
      const tbody = table.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[index]
      const thead = table.querySelector('thead')
      table.scrollTop = row.offsetTop - (thead.offsetHeight + 10)
    },
    deleteItem(index, item) {
      if (item.id) {
        this.deletedIds.push(item.id)
      }
      this.items.splice(index, 1)
    },
    save(callback) {
      axios.post('/defined_keys/', {
        definition_id: this.definitionId,
        items: this.items,
        deleted_items: this.deletedIds,
      }).then(res => {
        this.fetchDefinedKeys(false)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.detail,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            callback()
          })
      }).catch(error => {
        const message = error?.response?.data?.detail || 'Error saving defined keys'
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        callback()
      })
    },
  },
}
</script>
