<template>
  <b-button
    variant="outline-primary"
    :disabled="executingLookup || !enableSubmit"
    @click="runLookup"
  >
    Run Lookup
    <b-spinner
      v-if="executingLookup"
      small
      label="Small Spinner"
    />
  </b-button>
</template>

<script>
import bus from '@/bus'
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BSpinner,
  },
  computed: {
    executingLookup() {
      return this.$store.getters['lookup/executing']
    },
    enableSubmit() {
      return this.$store.getters['lookup/enableSubmit']
    },
  },
  methods: {
    runLookup() {
      bus.$emit('dataView/runLookup')
    },
  },
}
</script>
